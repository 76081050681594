<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div v-if="initialized&&cfdocument.identifier" @click="framework_viewer_clicked">
	<vue-draggable-resizable :drag-handle="'.k-case-tree-title'" :resizable="!maximized" :draggable="!maximized" :class-name="class_name" @resizing="tree_resized" :z="maximized?10:100" :w="viewer_width" :h="viewer_height" :active="!maximized" :preventDeactivation="true" :handles="['br']" class-name-handle="k-resizable-handle" :enable-native-drag="true">
		<div class="k-case-tree-top" :data-case-tree-top-identifier="lsdoc_identifier">
			<div class="k-case-tree-top-inner">
				<div class="k-case-tree-title">
					<v-btn v-if="!embedded_mode&&!($vuetify.breakpoint.xsOnly&&show_search_bar)" icon color="#fff" @click="hide_tree"><v-icon>fas {{maximized?'fa-arrow-circle-left':'fa-times-circle'}}</v-icon></v-btn>
					<FrameworkSwitcher v-if="!($vuetify.breakpoint.xsOnly&&show_search_bar)" btn_size="small" bstyle="margin-right:4px;" color="#fff" />
					<div v-show="show_framework_title" class="k-case-tree-title-inner" :class="doc_title_css" v-html="case_tree_title"></div>
					<v-menu right><template v-slot:activator="{on}"><v-btn v-if="is_sandbox" v-show="show_framework_title" v-on="on" fab x-small color="#fff" class="ml-2"><v-icon small>fas fa-umbrella-beach</v-icon></v-btn></template>
						<v-list dense>
							<v-list-item><v-list-item-icon><v-icon class="mr-2">fas fa-umbrella-beach</v-icon></v-list-item-icon><v-list-item-title>This is a “sandbox” copy of the original framework<br>created at {{sandbox_creation_date}}</v-list-item-title></v-list-item>
							<v-list-item @click="open_original_of_sandbox"><v-list-item-title class="text-right">Switch to the original framework <v-icon class="ml-2">fas fa-circle-arrow-right</v-icon></v-list-item-title></v-list-item>
							<v-list-item v-if="editing_enabled" @click="show_apply_sandbox=true"><v-list-item-title class="text-right">Apply sandbox changes to the original framework <v-icon class="ml-2">fas fa-bolt</v-icon></v-list-item-title></v-list-item>
							<v-list-item v-if="!editing_enabled"><v-list-item-title class="text-right mt-3" style="font-size:14px">(To apply sandbox changes to the original framework,<br>you must first turn on editing mode)</v-list-item-title></v-list-item>
						</v-list>
					</v-menu>
					<v-menu right><template v-slot:activator="{on}"><v-btn v-if="is_derivative&&user_can_edit" v-show="show_framework_title" v-on="on" fab x-small color="#fff" class="ml-2"><v-icon small>fas fa-arrow-up-from-bracket</v-icon></v-btn></template>
						<v-list dense>
							<v-list-item><v-list-item-icon><v-icon class="mr-2">fas fa-arrow-up-from-bracket</v-icon></v-list-item-icon><v-list-item-title>This is a “derivative” copy of another framework</v-list-item-title></v-list-item>
							<v-list-item @click="open_original_of_derivative"><v-list-item-title class="text-right">Switch to the original framework <v-icon class="ml-2">fas fa-circle-arrow-right</v-icon></v-list-item-title></v-list-item>
						</v-list>
					</v-menu>
				</div>
				<v-spacer/>
				<v-btn v-if="(crosswalk_right_identifier && editing_enabled) || showing_crosswalk" @click="showing_crosswalk = !showing_crosswalk" color="white" class="mx-2" style="margin-top: 9px;" x-small>{{ showing_crosswalk ? "Return to Framework" : "Return to Crosswalk Editor" }}</v-btn>
				<div v-show="show_search_bar" :style="!embedded_mode?'margin-left:8px':''" class="k-case-tree-search-outer" @click.stop="">	<!-- click.stop is needed here to prevent "ghosting" the search interface when you click near the search bar -->
					<div class="k-case-tree-search-results elevation-2" v-if="active_search_tree_keys.length>0&&!showing_crosswalk&&viewer_mode!='table'">
						<v-btn small icon color="#fff" @click.stop="jump_to_search(-1)"><v-icon small color="#fff">fas fa-angle-left</v-icon></v-btn><!--
						--><v-btn small icon color="#fff" @click.stop="jump_to_search(1)"><v-icon small color="#fff">fas fa-angle-right</v-icon></v-btn>
					</div>
					<v-hover v-slot:default="{hover}"><v-text-field ref="framework_search_bar" light background-color="#fff" class="mx-1" solo hide-details dense
						placeholder="Search"
						v-model="search_terms"
						prepend-inner-icon="fas fa-search" @click:prepend-inner="execute_search_start(true)"
						@focus="search_bar_focused"
						@blur="search_bar_blurred"
						@keydown="search_field_keydown"
						autocomplete="new-password"
						@click.stop=""
					>
						<template v-slot:append>
							<v-icon v-show="search_terms||$vuetify.breakpoint.xs||$vuetify.breakpoint.sm" class="ml-2" :color="hover?'primary':'#777'" style="font-size:20px" @click.stop="clear_search_terms">fas fa-times-circle</v-icon>
							<v-tooltip bottom><template v-slot:activator="{on}"><v-icon v-on="on" v-show="(hover||search_terms)&&!showing_crosswalk" class="ml-2" :color="hover?'primary':'#777'" style="font-size:20px; margin-top:-3px" @click.stop="advanced_search_clicked">fas fa-robot</v-icon></template>Advanced Search</v-tooltip>
							<v-icon v-show="(hover||search_terms)&&!showing_crosswalk&&viewer_mode!='table'&&!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm" class="ml-2" color="light-blue" style="font-size:20px" @click.stop="U.show_help('search')">fas fa-info-circle</v-icon>
						</template>
					</v-text-field></v-hover>
					<div v-if="search_panel_showing&&!showing_crosswalk&&viewer_mode!='table'" class="k-case-tree-search-panel" :class="search_panel_css_class" @click.stop="search_panel_to_front">
						<div class="k-case-tree-search-panel-screen"></div>
						<div class="k-case-tree-search-options" v-show="!search_results_panel_showing||$vuetify.breakpoint.smAndUp">
							<div class="my-3 d-flex align-center" style="pointer-events: auto;" @click="search_filter_menu_area_clicked">
								<v-menu right nudge-top="-32"><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="secondary" class="k-tight-btn ml-1" ref="search_filter_btn" @click.stop="search_filter_menu_clicked">Filters<v-icon small class="ml-1">fas fa-caret-down</v-icon></v-btn></template>
									<v-list width="400">
										<v-list-item><v-list-item-title @click.stop="">
											<div class="d-flex mt-1">
												<div class="grey--text text--darken-2 mt-2 ml-2" style="font-size:16px"><b>Search filters:</b></div>
												<v-spacer/>
												<div style="flex:0 0 110px"><v-select background-color="#fff" v-model="search_grade_low" :items="grades" item-value="index" label="Grade Low" outlined dense hide-details :menu-props="{top:true,dense:true}"></v-select></div>
												<div class="ml-1" style="flex:0 0 110px"><v-select background-color="#fff" v-model="search_grade_high" :items="grades" item-value="index" label="Grade High" outlined dense hide-details :menu-props="{top:true,dense:true}"></v-select></div>
											</div>
											<div style="flex:1 1 auto" class="mt-3 mb-1"><v-select multiple background-color="#fff" v-model="search_field_types" :items="search_field_type_options" label="Fields" outlined small-chips deletable-chips hide-details :menu-props="{offsetY:true,dense:true,closeOnClick:true}"></v-select></div>
											<div style="flex:1 1 auto" class="mt-3"><v-select multiple background-color="#fff" v-model="search_item_types" :items="search_item_type_options" label="Item Types" outlined small-chips :deletable-chips="search_item_types[0]!=all_item_types_option" hide-details :menu-props="{offsetY:true,dense:true,closeOnClick:true}"></v-select></div>
										</v-list-item-title></v-list-item>
									</v-list>
								</v-menu>
								<div class="ml-3" style="font-size:14px; line-height:18px;" v-html="filter_description"></div>
								<v-spacer/>
							</div>
							<div class="mt-1 mb-2 mx-auto" v-if="search_item_for_limit_descriptor">
								<div class="d-flex"><v-spacer/><v-checkbox class="mt-0 pt-0" v-model="search_limit_to_item" :disabled="search_item_for_limit_descriptor==''" hide-details><template v-slot:label><div style="font-size:14px" :class="search_item_for_limit_descriptor?'':'grey--text'">Limit search to children of<span v-if="!search_limit_to_item&&search_item_for_limit_descriptor"> last</span> selected item<span v-if="search_item_for_limit_descriptor">:</span></div></template></v-checkbox><v-spacer/></div>
								<div v-if="search_item_for_limit_descriptor" class="text-center"><b class="grey--text text--darken-1">{{search_item_for_limit_descriptor}}</b></div>
							</div>
						</div>
						<div v-if="search_results_panel_showing" class="k-case-tree-search-results-panel" :class="$vuetify.breakpoint.xsOnly?'elevation-4':''">
							<v-list dense>
								<v-list-item v-if="search_result_entries.length==0"><v-spacer/><div style="white-space:normal; text-align:center">
									<i v-if="tree_keys_for_advanced_search.length>0">A basic search of these terms returned no matches.<br>Adjust your <a @click.stop="show_search_filters"><b>search filters</b></a> or <nobr>try an <a @click.stop="advanced_search_clicked"><b>advanced search</b><v-icon color="primary" small class="ml-1" style="margin-top:-3px">fas fa-robot</v-icon></a></nobr></i>
									<i v-else>No items matched your search filters. <a @click.stop="show_search_filters"><b>Adjust the filters</b></a> and try again.</i>
								</div><v-spacer/></v-list-item>
								<v-list-item v-else class="pb-1">
									<div><v-icon v-visible="search_results_page_index > 0" @click.stop="show_search_results_page('prev')">fas fa-arrow-circle-left</v-icon></div>
									<v-spacer/>
									<div class="grey--text text--darken-2">
										<v-icon v-if="advanced_search_showing" small style="margin-top:-5px" class="mr-1">fas fa-robot</v-icon>
										<b style="font-size:14px">Search Results<span v-if="max_search_results_page_index>0"> (page {{search_results_page_index+1}})</span></b></div>
										<!-- <v-btn small icon class="ml-4" @click.stop="search_panel_to_back"><v-icon style="margin-top:-3px" small>fas fa-compress-alt</v-icon></v-btn> -->
									<v-spacer/>
									<div><v-icon v-visible="search_results_page_index < max_search_results_page_index" @click.stop="show_search_results_page('next')">fas fa-arrow-circle-right</v-icon></div>
								</v-list-item>

								<div v-for="(o, i) in active_search_tree_keys" :key="i" v-show="show_item_in_search_panel(o)"><v-tooltip :left="!$vuetify.breakpoint.xsOnly" :nudge-right="$vuetify.breakpoint.xsOnly?'':'8'" :bottom="$vuetify.breakpoint.xsOnly" :nudge-top="$vuetify.breakpoint.xsOnly?'12':''" content-class="k-case-tree-search-item-tooltip elevation-1" transition="none"><template v-slot:activator="{on}"><v-hover v-slot:default="{hover}"><v-list-item v-on="on" @click.stop="search_result_clicked(o)" :class="(o.tree_key == search_result_highlighted_tree_key)?'k-case-tree-advanced-search-last-clicked-result':''">
									<div v-show="hover"><CopyBtn size="small" :val="search_result_panel_copy_val(o)" color="primary"/></div>
									<div class="mr-2" v-if="advanced_search_showing"><div style="width:30px; margin-top:-1px"><v-progress-linear :value="o.entry.sim_score/10" color="green" height="10"></v-progress-linear></div>
									</div>
									<div class="d-flex" v-html="search_result_panel_html(o)"></div>
								</v-list-item></v-hover></template><div v-html="search_result_panel_tooltip_html(o)"></div></v-tooltip></div>
								<v-list-item v-if="!advanced_search_showing&&tree_keys_for_advanced_search.length>0&&search_result_entries.length>0"><v-spacer/><div class="pt-2 mt-2 px-1" style="white-space:normal; text-align:center; border-top:1px solid #999"><i>Try an <a @click.stop="advanced_search_clicked"><b>advanced search</b><v-icon color="primary" small class="ml-1 mr-1" style="margin-top:-3px">fas fa-robot</v-icon></a> or <a @click.stop="show_search_filters"><b>adjust search filters</b></a></i></div><v-spacer/></v-list-item>
							</v-list>
						</div>
					</div>
				</div>
				<v-btn v-show="!show_search_bar&&!showing_crosswalk&&viewer_mode!='table'" x-small fab color="transparent" class="ml-2" @click.stop="reveal_search_bar"><v-icon color="white">fas fa-search</v-icon></v-btn>
				
				<v-menu :open-on-hover="false" :transition="false" bottom left v-model="kebab_menu_showing"><template v-slot:activator="{on}"><v-btn v-if="!embedded_mode" v-on="on" icon color="#fff" class="ml-0"><v-icon>fas fa-ellipsis-v</v-icon></v-btn></template>
					<v-list min-width="250" dense>
						<v-list-item v-show="$vuetify.breakpoint.xs||$vuetify.breakpoint.sm" @click="show_help"><v-list-item-icon><v-icon small color="light-blue accent-4">fas fa-circle-info</v-icon></v-list-item-icon><v-list-item-title class="light-blue--text text--accent-4">{{$store.state.site_config.app_name}} Help</v-list-item-title></v-list-item>
						<v-list-item><v-list-item-title class="text-center">FRAMEWORK OPTIONS</v-list-item-title></v-list-item>
						<v-divider/>

						<!-- <v-list-item @click="toggle_maximize_tree"><v-list-item-icon><v-icon small>fas {{maximized?'fa-compress':'fa-expand'}}</v-icon></v-list-item-icon><v-list-item-title>{{maximized?'Minimize':'Maximize'}} framework viewer</v-list-item-title></v-list-item> -->

						<v-list-item class="mt-1"><v-list-item-title><span class="mr-2">Viewer Mode:</span>
							<v-btn-toggle dense active-class="k-toggle-btn-active-class" class="k-toggle-btn" v-model="viewer_mode" mandatory>
								<v-btn small light value="tree">Tree</v-btn>
								<v-btn small light value="tiles">Tiles</v-btn>
								<v-btn v-if="!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm" small light value="table">Table</v-btn>
							</v-btn-toggle>
						</v-list-item-title></v-list-item>

						<!-- <v-list-item v-if="viewer_mode!='table'&&open_node_count>0" @click="collapse_all"><v-list-item-icon><v-icon small style="transform:rotate(-45deg)">fas fa-compress-alt</v-icon></v-list-item-icon><v-list-item-title>Collapse all</v-list-item-title></v-list-item> -->

						<v-list-item><v-list-item-icon><v-icon small>fas fa-font</v-icon></v-list-item-icon><v-list-item-title>
							Font Size:
							<v-btn icon x-small color="#777" class="ml-1" @click.stop="adjust_font_size(-1)" :disabled="font_size==-2"><v-icon>fas fa-minus-circle</v-icon></v-btn>
							<v-btn icon x-small color="#777" class="ml-1" @click.stop="adjust_font_size(1)" :disabled="font_size==2"><v-icon>fas fa-plus-circle</v-icon></v-btn>
						</v-list-item-title></v-list-item>

						<v-list-item v-if="editing_enabled" @click="show_update_report('archives')" class="orange--text text--darken-2"><v-list-item-icon><v-icon small color="orange darken-2">fas fa-archive</v-icon></v-list-item-icon><v-list-item-title>Manage framework archives</v-list-item-title></v-list-item>

						<v-divider v-if="!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm" />

						<v-menu :transition="false" offset-x left nudge-top="8" :open-on-hover="false" style="display: block;">
							<template v-slot:activator="{on}"><v-list-item v-on="on" style="cursor:pointer">
								<v-list-item-icon><v-icon small>fas fa-file-export</v-icon></v-list-item-icon><v-list-item-title>Export Framework / Copy Framework Link</v-list-item-title>
								<v-list-item-action class="justify-end"><v-icon small>fas fa-chevron-right</v-icon></v-list-item-action>
							</v-list-item></template>

							<v-list dense>
								<v-list-item v-if="!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm" @click="export_framework_json"><v-list-item-icon><v-icon small>fas fa-file-export</v-icon></v-list-item-icon><v-list-item-title>Export CASE JSON…</v-list-item-title></v-list-item>
								<v-list-item v-if="$store.state.enable_ap_export&&signed_in&&!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm" @click="export_ap_spreadsheet"><v-list-item-icon><v-icon small>fas fa-file-export</v-icon></v-list-item-icon><v-list-item-title>Export to AP spreadsheet format…</v-list-item-title></v-list-item>
								<v-list-item v-if="$store.state.enable_ap_export&&signed_in&&!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm" @click="export_ap_word"><v-list-item-icon><v-icon small>fas fa-file-export</v-icon></v-list-item-icon><v-list-item-title>Export to AP Word format…</v-list-item-title></v-list-item>
								<v-list-item v-if="!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm" @click="viewer_mode='table';kebab_menu_showing=false"><v-list-item-icon><v-icon small>fas fa-file-export</v-icon></v-list-item-icon><v-list-item-title><i>Export framework data in spreadsheet form from the <b>Table View</b></i></v-list-item-title></v-list-item>
								<v-list-item @click="copy_shortcut_url"><v-list-item-icon><v-icon small>fas fa-link</v-icon></v-list-item-icon><v-list-item-title>Copy {{$store.state.site_config.browsing_interface_title}} link</v-list-item-title></v-list-item>
								<v-list-item @click="copy_api_url"><v-list-item-icon><v-icon small>fas fa-link</v-icon></v-list-item-icon><v-list-item-title>Copy CASE package API link</v-list-item-title></v-list-item>
							</v-list>
						</v-menu>

						<v-list-item v-if="user_can_align" @click="toggle_resource_alignments"><v-list-item-icon><v-icon small style="transform:rotate(45deg)">fas fa-compress-alt</v-icon></v-list-item-icon><v-list-item-title>Align resources (BETA)…</v-list-item-title></v-list-item>

						<v-list-item v-if="user_can_admin" @click="show_access_detail_dialog=true"><v-list-item-icon><v-icon small class="red--text text--darken-3">fas fa-chart-line</v-icon></v-list-item-icon><v-list-item-title class="red--text text--darken-3">Framework access report</v-list-item-title></v-list-item>
						<v-list-item v-if="user_can_admin" @click="show_manage_framework_user_rights=true"><v-list-item-icon><v-icon small class="red--text text--darken-3">fas fa-users</v-icon></v-list-item-icon><v-list-item-title class="red--text text--darken-3">Manage user rights for framework…</v-list-item-title></v-list-item>

						<v-list-item class="mt-1" v-if="user_can_admin"><v-list-item-title><span class="mr-2">CASE Displayed:</span>
							<v-btn-toggle dense active-class="k-toggle-btn-active-class" class="k-toggle-btn" v-model="case_version_displayed" mandatory>
								<v-btn class="k-nocaps-btn k-tight-btn" small light value="vext">vext</v-btn>
								<v-btn class="k-nocaps-btn k-tight-btn" small light value="v1p1">v1p1</v-btn>
								<v-btn class="k-nocaps-btn k-tight-btn" small light value="v1p0">v1p0</v-btn>
							</v-btn-toggle>
						</v-list-item-title></v-list-item>

						<v-divider v-if="!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm&&user_can_edit" />
						<v-list-item v-if="user_can_edit&&!editing_enabled&&!viewing_archive" @click="check_out_for_editing"><v-list-item-icon><v-icon small>fas fa-edit</v-icon></v-list-item-icon><v-list-item-title><b>Enable framework editing</b></v-list-item-title></v-list-item>
						<v-list-item v-if="user_can_edit&&editing_enabled" @click="check_in_for_editing"><v-list-item-icon><v-icon small>fas fa-edit</v-icon></v-list-item-icon><v-list-item-title><b>Stop editing framework</b></v-list-item-title></v-list-item>
					</v-list>
				</v-menu>
				<!-- <v-btn icon color="#fff" style="margin-right:-2px" @click="toggle_maximize_tree"><v-icon>fas {{maximized?'fa-compress':'fa-expand'}}</v-icon></v-btn> -->
			</div>
			<div v-if="image_src&&!is_sandbox" class="k-case-tree-framework-image-wrapper"><img :src="image_src" /></div>
			<div v-if="is_sandbox" class="k-case-tree-framework-image-wrapper"><v-icon style="font-size:180px; line-height:220px;" color="#333">fas fa-umbrella-beach</v-icon></div>
		</div>

		<div class="k-case-tree-main" v-if="cftree.children" v-show="!showing_crosswalk">
			<div class="k-case-tree-inner-wrapper">
				<!-- <div v-if="cftree.children && !has_items" class="mt-4 text-center"><i>This CASE document does not include any items</i></div> -->
				<CASEFVToolbar v-show="viewer_mode=='tree'&&!show_move_fn" :viewer="this" />

				<div v-if="viewer_mode=='tree'||viewer_mode=='tiles'" class="k-case-tree-inner-wrapper-2">
					<CASETree ref="main_viewer_tree"
						:home_framework_record="home_framework_record"
						:viewer="this"
						:show_checkbox_fn="show_checkbox_fn"
						:show_move_fn="show_move_fn"
						:show_chooser_fn="show_chooser_fn"
						@tree_scrolled="tree_scrolled"
					/>
				</div>

				<ItemsTable v-if="item_table_shown" v-show="viewer_mode=='table'&&viewer_table_mode=='items'" :viewer="this"
					:framework_record="framework_record"
					@search_result_clicked="search_result_clicked"
					@switch_table="viewer_table_mode='associations'"
					@switch_to_tree="viewer_mode='tree'" />
				<AssociationsTable v-if="assoc_table_shown" v-show="viewer_mode=='table'&&viewer_table_mode=='associations'" :viewer="this"
					:framework_record="framework_record" :hide_associations="hide_associations"
					@search_result_clicked="search_result_clicked"
					@switch_table="viewer_table_mode='items'"
					@switch_to_tree="viewer_mode='tree'" />

			</div>
		</div>

		<!--CROSSWALK-->
		<CrosswalkEditor v-if="crosswalk_right_identifier" v-show="showing_crosswalk" :crosswalk_lsdoc_identifiers="[lsdoc_identifier, crosswalk_right_identifier]" :viewer="this"/>

		<div v-if="viewing_archive||tracking_changes" class="k-case-archive-view-ctl" :class="'k-case-archive-view-ctl-' + view_archive_fn">
			<v-icon v-if="tracking_changes" small color="#fff" class="mr-2" @click="copy_archive_link">fas fa-link</v-icon>
			<div style="overflow:hidden; text-overflow:ellipsis" class="mr-2"><nobr v-html="view_archive_ctl_text"></nobr></div>
			<v-spacer/>
			<v-menu v-if="tracking_changes" left z-index="100"><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="#fff" class="mr-3">Show changes to…</v-btn></template>
				<v-list dense>
					<v-list-item v-for="(mitem, key) in track_changes_field_descriptions" :key="key" @click.stop="set_track_changes_fields(key)"><v-list-item-title><v-icon small color="#555" class="mr-2" style="margin-top:-2px" v-visible="track_changes_fields[key]">fas fa-check-circle</v-icon><span v-html="mitem"></span></v-list-item-title></v-list-item>
				</v-list>
			</v-menu>

			<div class="text-center"><nobr>
				<v-btn small text color="white" class="k-tight-btn" @click="show_update_report('')"><v-icon small class="mr-2">fas fa-arrow-circle-left</v-icon>Update Report</v-btn>
				<v-btn small text color="white" class="k-tight-btn" @click="show_archive_table_view"><v-icon small class="mr-2">fas fa-table</v-icon>Show Changes in Table</v-btn>
				<v-btn v-if="viewing_archive" small text color="white" class="k-tight-btn" @click="return_to_current_version"><v-icon small class="mr-2">fas fa-times-circle</v-icon>Done viewing updates</v-btn>
				<v-btn v-if="tracking_changes" small text color="white" class="k-tight-btn" @click="cancel_track_changes"><v-icon small class="mr-2">fas fa-times-circle</v-icon>Done tracking changes</v-btn>
				<v-btn v-visible="!track_changes_legend_showing" icon small color="#fff" @click="toggle_track_changes_legend"><v-icon small>fas fa-key</v-icon></v-btn>
			</nobr></div>
		</div>

		<!-- button to switch to tree mode from tile mode -->
		<div v-if="viewer_mode=='tiles'" class="k-tile-mode-to-tree-mode-btn"><v-btn fab x-small color="primary" @click="cancel_tile_mode"><v-icon>fas fa-tree</v-icon></v-btn></div>

	</vue-draggable-resizable>
	<CASEFVMoreInfo v-if="more_info_type" :framework_record="framework_record" :object_type="more_info_type" :object="more_info_item" :show_delta_at_start="more_info_show_delta" @dialog_cancel="more_info_type=''" @show_framework_json="show_framework_json" />
	<PrintItems v-if="show_print_dialog" :framework_record="home_framework_record" :print_node="print_node" @dialog_cancel="show_print_dialog=false" />
	<CASEAssociatedItemTree ref="associated_item_tree" v-if="association_framework_tree_identifier" 
		:left_node="association_framework_tree_left_node" 
		:associationType="association_framework_tree_associationType" 
		:framework_identifier="association_framework_tree_identifier" 
		:archive_framework_record="association_framework_tree_associationType=='archive'?track_changes_framework_record:null"
		:item_identifier="association_framework_tree_item_identifier" 
		:item_tree_key="association_framework_tree_item_tree_key" 
		:line_to_identifier="association_framework_tree_item_identifier" 
		:line_to_tree_key="association_framework_tree_item_tree_key" 
		:line_from_tree_key="association_line_from_tree_key" 
		:viewer="this" @dialog_cancel="hide_associated_item" 
	/>
	<CASEItemTileExpanded ref="expanded_tile" :viewer="this" :framework_record="home_framework_record" />

	<DocumentEditor v-if="editing_document&&!viewing_archive" :framework_record="home_framework_record" :viewer="this"
		@dialog_cancel="cancel_edit_document"
	/>
	<ItemEditor v-if="edited_node&&!viewing_archive" :framework_record="home_framework_record" :original_node="edited_node" :framework_maximized="maximized" :viewer="this"
		@dialog_cancel="cancel_item_edit"
		@item_edit_suggestion_saved="item_edit_suggestion_saved"
		@item_edit_suggestion_applied="item_edit_suggestion_applied"
		@edit_new_child="edit_new_child"
		@make_node_open="make_node_open"
		@make_node_active="make_node_active"
		@make_node_parents_open="make_node_parents_open"
	/>
	<BatchEditor ref="batch_editor" v-if="show_checkbox_fn&&!viewing_archive" :framework_record="home_framework_record" :viewer="this" @dialog_cancel="toggle_batch_edit_mode" />
	<MoveEditor ref="move_editor" v-if="show_move_fn&&!viewing_archive" :framework_record="home_framework_record" :viewer="this" @dialog_cancel="toggle_move_mode" />
	<MakeAssociationsInterface ref="make_associations_editor" v-if="show_make_associations_interface&&!viewing_archive" :framework_record="home_framework_record" :viewer="this" @dialog_cancel="toggle_make_associations" />
	<AlignResourcesInterface ref="resource_alignments_editor" v-if="show_resource_alignments_interface&&!viewing_archive" :framework_record="home_framework_record" :viewer="this" @dialog_cancel="toggle_resource_alignments" />
	<ReduceFileSize v-if="show_reduce_file_size_dialog" :framework_record="home_framework_record" @dialog_cancel="show_reduce_file_size_dialog=false" />
	<ApplySandbox v-if="show_apply_sandbox" :framework_record="framework_record" @dialog_cancel="show_apply_sandbox=false" @hide_tree="hide_tree"/>
	<CommentsTable v-if="show_comments_table" :viewer="this"
		:framework_record="framework_record"
		:starting_comment_id="comments_table_starting_comment_id"
		@search_result_clicked="search_result_clicked"
		@dialog_cancel="show_comments_table=false" />
	<FrameworkAdminUsers v-if="show_manage_framework_user_rights"
		:lsdoc_identifier="lsdoc_identifier"
		@dialog_cancel="show_manage_framework_user_rights=false"
	/>
	<FrameworkAccessReportList v-if="show_access_detail_dialog"
		:framework_record="framework_record"
		@dialog_cancel="show_access_detail_dialog=false" />
	<ImportConfirm v-if="show_import_confirm" :viewer="this" @dialog_cancel="show_import_confirm=false" />
	<ProgressionTable v-if="progression_table_data" :framework_record="progression_table_record" :progression_table_data="progression_table_data" @dialog_cancel="progression_table_data=null" />
	<CustomScriptRunner v-if="custom_script_name" :custom_script_name="custom_script_name" @dialog_cancel="custom_script_name=''" />
	<SideBySideEditor v-if="side_by_side_editor_head_identifier" @dialog_cancel="side_by_side_editor_head_identifier=null" :viewer="this" :head_identifier="side_by_side_editor_head_identifier" />
	<ManageCommentGroups v-if="show_comment_group_manager" @dialog_cancel="show_comment_group_manager=false" :framework_record="framework_record" />
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import FrameworkSwitcher from '@/components/frameworks/FrameworkSwitcher'
import CopyBtn from '../utilities/CopyBtn'
import CASEFVMoreInfo from './CASEFVMoreInfo'
import PrintItems from './PrintItems'
import CASETree from './CASETree'
import CASEItemTileExpanded from './CASEItemTileExpanded'
import CASEAssociatedItemTree from './CASEAssociatedItemTree'
import AssociationsTable from './AssociationsTable'
import ItemsTable from './ItemsTable'
import ProgressionTable from './ProgressionTable'
import FrameworkAccessReportList from '../admin/FrameworkAccessReportList'
import CASEFVSearchMixin from './CASEFVSearchMixin'
import CASEFVAssociationsMixin from './CASEFVAssociationsMixin'
import '../../js/search_fns.js'

import DocumentEditor from '../edit/DocumentEditor'
import ItemEditor from '../edit/ItemEditor'
import BatchEditor from '../edit/BatchEditor'
import MoveEditor from '../edit/MoveEditor'
import MakeAssociationsInterface from '../edit/MakeAssociationsInterface'
import AlignResourcesInterface from '../edit/AlignResourcesInterface'
import ReduceFileSize from '../edit/ReduceFileSize'
import ImportConfirm from '../edit/ImportConfirm'
import ImportItemsSaveMixin from '../edit/ImportItemsSaveMixin'
import ApplySandbox from '../edit/ApplySandbox'
import FrameworkAdminUsers from '../admin/FrameworkAdminUsers'
import CommentsMixin from '../comments/CommentsMixin'
import CommentsTable from '../comments/CommentsTable'
import MirrorMixin from '../../js/MirrorMixin'
import CASEFVToolbar from './CASEFVToolbar'
import ManageCommentGroups from '../comments/ManageCommentGroups'
import APMetadataMixin from '../../js/APMetadataMixin'
import CustomScriptRunner from '../edit/CustomScriptRunner'

import CrosswalkEditor from '../crosswalks/CrosswalkEditor.vue'
import SideBySideEditor from './SideBySideEditor'

export default {
	components: { FrameworkSwitcher, CASEFVMoreInfo, PrintItems, CopyBtn, CASETree, CASEItemTileExpanded, CASEAssociatedItemTree, AssociationsTable, ItemsTable, ProgressionTable, FrameworkAccessReportList, CASEFVToolbar, CustomScriptRunner,
		// take editor componts out if not using editing capabilities
		DocumentEditor, ItemEditor, BatchEditor, MoveEditor, MakeAssociationsInterface, ReduceFileSize, AlignResourcesInterface, FrameworkAdminUsers, CommentsTable, ImportConfirm,
		// Archive actions are a mix of viewing (view, compare) and editing (restore, delete)
		ApplySandbox, CrosswalkEditor, SideBySideEditor, ManageCommentGroups
	},
	mixins: [CASEFVSearchMixin, CommentsMixin, ImportItemsSaveMixin, MirrorMixin, APMetadataMixin, CASEFVAssociationsMixin],
	props: {
		lsdoc_identifier: { type: String, required: true },
		starting_lsitem_identifier: { type: String, required: false, default() { '' }},
		starting_tree_key: { type: String, required: false, default() { '' }},
		show_doc_info_on_open: { type: Boolean, required: false, default() { return false }},
		minimized_param: { type: Boolean, required: false, default() { return false }},
		imported_search_terms: { type: String, required: false, default() { return '' }},
	},
	data() { return {
		initialized: false,
		kebab_menu_showing: false,
		edited_node: null,				// note that AppShortcutsMixin checks edited_node and editing_document to determine if shortcuts should be used
		editing_document: false,
		current_editor: null,
		show_doc_info: false,
		show_fw_changes: false,
		more_info_type: '',
		more_info_item: {},
		more_info_show_delta: false,
		stats_mode: false,
		show_chooser_fn: false,
		show_reduce_file_size_dialog: false,
		show_checkbox_fn: false,
		show_move_fn: false,
		move_node_shift_right: null,
		show_move_node_conversion: false,
		show_make_associations_interface: false,
		show_resource_alignments_interface: false,
		show_manage_framework_user_rights: false,
		show_apply_sandbox: false,
		show_access_detail_dialog: false,
		show_comments_table: false,
		comment_editor: null,
		show_print_dialog:false,
		print_node: null,
		// side_by_side_editor_head_identifier: null,

		expanded_tile_item: null,
		expanded_tile_framework_record: null,

		show_import_confirm: false,
		new_imported_item_identifiers: [],

		view_archive_ctl_text: '',
		view_archive_fn: '',
		editing_prior_to_viewing_archive: null,
		show_compare_to_archive: false,
		archive_date: '',
		archive_note: '',
		archive_item_identifier_to_show: '',
		track_changes_framework_record: null,
		track_changes_field_descriptions: {
			fullStatement: 'Statement/title',
			humanCodingScheme: 'Human-readable code',
			notes: 'Notes',
			supplementalNotes: 'Supplemental information',
			highlight_text_diffs: '<b>HIGHLIGHT TEXT CHANGES</b>',
			loose_comparisons: '<b>IGNORE PUNCTUATION/CAPITALIZATION CHANGES</b>',
			// CFItemType: 'Item type',
			// educationLevel: 'Education level',
		},

		arc_item: {},
		item_move_drag_options: {
			group: 'case_items',
			animation: 200,
			handle: ".k-case-item-move-handle",
		},
		remove_association_confirmed: false,
		association_framework_tree_left_node: null,
		association_framework_tree_associationType: '',
		association_framework_tree_identifier: null,
		association_framework_tree_item_identifier: null,
		association_framework_tree_item_tree_key: null,
		last_clicked_association_identifier: null,
		last_clicked_association_item_tree_key: null,
		association_line_from_tree_key: '',

		previous_starting_tree_key: '',

		progression_table_data: null,
		progression_table_record: null,

		// responsivity
		title_or_search: 'title',

		start_string_message_showing: false,

		custom_script_name: '',

		// manage comment groups
		show_comment_group_manager:false,

		// use these to avoid the overhead of rendering the table modes until requested
		item_table_shown: false,
		assoc_table_shown: false,

		// if set, the table will show only children of the specified node
		table_mode_start_node: null,
	}},
	computed: {
		...mapState(['user_info', 'framework_records', 'grades', 'start_string', 'start_identifier', 'embedded_mode', 'embedded_mode_chooser']),
		...mapGetters(['signed_in']),
		track_changes_fn: {
			// store the track_changes_fn for each framework in localstorage; save/store archive_date and archive_note along with the fn
			get() {
				let s = this.$store.state.lst.track_changes_fn
				if (s) {
					let o = JSON.parse(s)
					if (o[this.lsdoc_identifier]) {
						this.archive_date = o[this.lsdoc_identifier].archive_date
						this.archive_note = o[this.lsdoc_identifier].archive_note
						return o[this.lsdoc_identifier].fn
					} else {
						this.archive_date = ''
						this.archive_note = ''
						return ''
					}
				}
			},
			set(val) {
				let o = {}
				let s = this.$store.state.lst.track_changes_fn
				if (s) o = JSON.parse(s)
				o[this.lsdoc_identifier] = {
					fn: val,
					archive_date: this.archive_date,
					archive_note: this.archive_note
				}
				this.$store.commit('lst_set', ['track_changes_fn', JSON.stringify(o)])
			},
		},
		track_changes_fields() { return this.$store.state.lst.track_changes_fields },
		track_changes_legend_showing() { return vapp.framework_list_component.$refs.track_changes_legend.showing },
		side_by_side_editor_head_identifier: {
			get() { return (this.$store.state.lst.side_by_side_editor_head_identifier[this.lsdoc_identifier] ?? '') },
			set(val) { this.$store.commit('lst_set_hash', ['side_by_side_editor_head_identifier', this.lsdoc_identifier, val]) }
		},
		user_can_view() {
			return vapp.is_granted('view_framework', this.lsdoc_identifier)
		},
		user_can_edit() {
			// no editing or admining or aligning when on a phone!
			if (!this.signed_in || this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) return false
			return vapp.is_granted('edit_framework', this.lsdoc_identifier)
		},
		user_can_admin() {
			if (!this.signed_in || this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) return false
			return vapp.is_granted('admin_framework', this.lsdoc_identifier)
		},
		user_can_align() {
			if (!this.signed_in || this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) return false
			// currently we only let pepper and sunil access the alignment mechanism
			if (this.user_info.email == 'pepper@commongoodlt.com' || this.user_info.email == 'sunil.williams.4@gmail.com') return true
			return false
		},
		case_version_displayed: {
			get() { return this.$store.state.case_version_displayed },
			set(val) { this.$store.state.case_version_displayed = val }
		},
		viewer_mode: {
			get() { 
				let mode = this.$store.state.lst.viewer_mode
				if (mode == 'table') {
					if (this.viewer_table_mode == 'items') this.item_table_shown = true
					else this.assoc_table_shown = true
				}
				return mode 
			},
			set(val) {
				this.$store.commit('lst_set', ['viewer_mode', val])

				if (val == 'tiles') {
					vapp.$refs.help.set_current_app_doc('tileview')
				} else if (val == 'table') {
					if (this.viewer_table_mode == 'items') {
						this.item_table_shown = true
						vapp.$refs.help.set_current_app_doc('tableitemsview')
					} else {
						this.assoc_table_shown = true
						vapp.$refs.help.set_current_app_doc('tableassociationsview')
					}
				} else {
					vapp.$refs.help.set_current_app_doc('treeview')
				}
			}
		},
		viewer_table_mode: {
			get() { return this.$store.state.lst.viewer_table_mode },
			set(val) {
				this.$store.commit('lst_set', ['viewer_table_mode', val])

				if (val == 'items') {
					this.item_table_shown = true
					vapp.$refs.help.set_current_app_doc('tableitemsview')
				} else {
					this.assoc_table_shown = true
					vapp.$refs.help.set_current_app_doc('tableassociationsview')
				}
			}
		},
		maximized: {
			// view is maximized by default -- unless 'minimized' is in the query string
			get() { return this.minimized_param !== true },
			set(val) {
				this.$emit('set_mode', 'minimized', !val)
			},
		},
		editing_enabled: {
			get() { return this.$store.state.editing_enabled},
			set(val) { this.$store.commit('set', ['editing_enabled', val])}
		},
		show_identifiers_in_tiles: {
			get() { return this.$store.state.lst.show_identifiers_in_tiles},
			set(val) { this.$store.commit('lst_set', ['show_identifiers_in_tiles', val])}
		},
		show_color_coded_item_types: {
			get() { return this.$store.state.lst.show_color_coded_item_types },
			set(val) { this.$store.commit('lst_set', ['show_color_coded_item_types', val]) }
		},
		hide_associations() {
			// there is a satchel-specific document setting for hiding associations for all users except framework reviewers/editors/admins; respect that setting here
			// if you're allowed to review the framework, associations are *not* hidden for you
			if (vapp.is_granted('review_framework', this.lsdoc_identifier)) return false
			return this.framework_record.ss_framework_data.hide_associations
		},
		show_associations: {
			get() {
				// if we don't *have* any associations for the framework, don't show associations, regardless of whether or not the show_associations flag is on
				if (!this.has_associations) return false

				// also don't show if hide_associations evaluates to true
				if (this.hide_associations) return false

				return this.$store.state.lst.show_associations
			},
			set(val) { this.$store.commit('lst_set', ['show_associations', val]) }
		},
		wrap_item_text: {
			get() { return this.$store.state.lst.wrap_item_text },
			set(val) { this.$store.commit('lst_set', ['wrap_item_text', val]) }
		},
		show_comments: {
			get() { 
				if (this.embedded_mode) return false
				return this.$store.state.lst.show_comments 
			},
			set(val) { this.$store.commit('lst_set', ['show_comments', val]) }
		},
		viewer_width: {
			get() {
				let width = this.$store.state.case_tree_viewer_width
				if (empty(width)) {
					width = Math.round($(window).width() / 2)
					if (width > 900) width = 900	// max 900
					if (width < 600) width = 600	// min 600
				}
				if (width > $(window).width()) width = $(window).width()
				return width
			},
			set(val) { this.$store.commit('set', ['case_tree_viewer_width', val]) }
		},
		viewer_height: {
			get() {
				// on small screen, full height
				if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) return $(window).height()

				let height = this.$store.state.case_tree_viewer_height
				if (empty(height)) {
					height = $(window).height() - 110
				}
				return height
			},
			set(val) { this.$store.commit('set', ['case_tree_viewer_height', val]) }
		},
		class_name() {
			let s = 'k-case-tree-outer-wrapper '

			// color should always reflect the home framework
			s += U.framework_color(this.home_framework_record.json.CFDocument.identifier)

			if (this.embedded_mode) s += ' k-case-tree-outer-wrapper-embedded'

			if (this.viewer_mode == 'table') {
				s += ' k-case-tree-outer-wrapper--table-mode'
			}

			if (this.viewing_archive || this.tracking_changes) s += ' k-case-tree-outer-wrapper-archive-showing'

			if (this.maximized) {
				if (this.framework_record.pinned_items.length > 0 && !this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm) {
					s += ' k-case-tree-outer-wrapper--pinned-items-showing'
				}
			} else {
				s += ' k-case-tree-outer-wrapper--minimized elevation-8'
			}
			return s
		},
		doc_title_css() {
			// reduce size of title when necessary to fit it in
			let title = this.home_framework_record.json.CFDocument.title
			if (title.length > 85) return 'k-case-tree-title-inner-xsmall'
			else if (title.length > 70) return 'k-case-tree-title-inner-small'
			return ''
		},
		// cfdocument_subject() {
		// 	// this is supposed to always come in as an array, but sometimes it's a string
		// 	if (empty(this.cfdocument.subject)) return ''
		// 	if ($.isArray(this.cfdocument.subject)) return this.cfdocument.subject.join(', ')
		// 	return this.cfdocument.subject
		// },

		// framework A may have associations with objects in framework B. if so, the user can choose to show framework B's tree if we're in the viewer context
		// so CASEFrameworkViewer receives the home_framework_record (framework A), then determines which tree to show based on document_identifier_showing_in_tree, which is chosen by the user in CASETree.vue
		home_framework_record() {
			let o = this.framework_records.find(x=>x.lsdoc_identifier==this.lsdoc_identifier)
			if (empty(o)) return {}
			else return o
		},
		framework_record() {
			let o = this.framework_records.find(x=>x.lsdoc_identifier == this.home_framework_record.document_identifier_showing_in_tree)
			if (empty(o)) return {}
			else return o
		},

		cfo() {
			if (empty(this.framework_record.cfo)) return {}
			else return this.framework_record.cfo
		},
		case_tree_title() {
			// top title should always reflect the home framework
			if (empty(this.home_framework_record.json.CFDocument)) return ''
			return this.home_framework_record.json.CFDocument.title
			// note that this could be overridden to reflect a different title, e.g. "Henry Teaching & Learning Standards: Science"
		},
		cftree() { return empty(this.cfo.cftree) ? {} : this.cfo.cftree },
		cfdocument() { return (empty(this.cftree) || empty(this.cftree.cfitem)) ? {} : this.cftree.cfitem },
		cfitems() { return empty(this.cfo.cfitems) ? {} : this.cfo.cfitems },
		CFDocument() { return empty(this.framework_record.json) ? {} : this.framework_record.json.CFDocument },
		has_items() { return !empty(this.cftree.children) && this.cftree.children.length > 0 },
		is_mirror() { return this.framework_record.ss_framework_data.is_mirror === 'yes' },
		is_derivative() { return !empty(this.cfdocument.extensions?.sourceFrameworkIdentifier) },
		derivative_original_framework_record() {
			if (!this.is_derivative) return null
			return this.framework_records.find(x=>x.lsdoc_identifier == this.cfdocument.extensions.sourceFrameworkIdentifier)
		},
		derivative_original_json() {
			if (!this.derivative_original_framework_record?.framework_json_loaded) return null
			return this.derivative_original_framework_record.json
		},
		is_sandbox() { return !empty(this.framework_record.ss_framework_data.sandboxOfIdentifier) },
		sandbox_creation_date() {
			if (!this.is_sandbox) return ''
			return this.framework_record.ss_framework_data.sandboxSyncDateTime
		},
		user_is_admin() {
			return this.user_info.system_role == 'admin'
		},
		fw_changes() {
			return this.framework_record.fw_changes
		},
		active_node() { return this.framework_record.active_node },
		open_nodes() { return this.framework_record.open_nodes },
		open_node_count() { return Object.keys(this.open_nodes).length },
		image_src() {
			return U.framework_image_src(this.home_framework_record)
		},
		viewing_archive() {
			// we're currently viewing an archive if this.view_archive_fn is set to something
			return !empty(this.view_archive_fn)
		},
		tracking_changes() {
			return !empty(this.track_changes_fn) && !empty(this.track_changes_framework_record) && !empty(this.track_changes_framework_record.cfo)
		},
		public_review_on() { return this.framework_record.ss_framework_data.public_review_on },

		font_size: {
			get() {
				if (this.$vuetify.breakpoint.xs) return this.$store.state.lst.font_size_xs
				else if (this.$vuetify.breakpoint.sm) return this.$store.state.lst.font_size_sm
				else return this.$store.state.lst.font_size
			},
			set(val) {
				if (this.$vuetify.breakpoint.xs) this.$store.commit('lst_set', ['font_size_xs', val])
				else if (this.$vuetify.breakpoint.sm) this.$store.commit('lst_set', ['font_size_sm', val])
				else this.$store.commit('lst_set', ['font_size', val])
			}
			// note that font_size gets expressed in CASETree.top_css_class
		},

		// responsivity
		show_framework_title() {
			// if not on a small screen, always show the title
			if (!this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm) return true
			// else we toggle between showing the search bar and the title
			return this.title_or_search == 'title'
		},

		show_search_bar() {
			// don't show the search bar when we're in crosswalking mode
			// if (this.showing_crosswalk) return false

			// if not on a small screen, always show the search bar
			if (!this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm) return true
			// else we toggle between showing the search bar and the title
			return this.title_or_search == 'search'
		},

		has_associations() {
			// note that we used to calculate this right here using a recursive fn, but that proved to slow things down a lot for big frameworks
			return this.home_framework_record.cfo.displayed_association_counts_hash[this.lsdoc_identifier] > 0
		},

		selected_items() {
			return this.framework_record.selected_items
		},
		selected_nodes() {
			console.log('computing selected_nodes')
			if (!this.selected_items) return null
			let arr = []

			// selected_items are identifiers, and could be aliased in multiple places.
			// if a selected_items_ancestor is specified, only select nodes that are descendents of it (if there are multiple nodes for an item)
			for (let identifier of this.selected_items) {
				let cfitem = this.cfitems[identifier]
				// note that selected_items may include items from other frameworks; skip them
				if (!cfitem) continue

				// for each selected item, if there is a single node for the item (which will usually be the case), we always show it
				if (cfitem.tree_nodes.length == 1) {
					arr.push(cfitem.tree_nodes[0])
					continue
				}

				// if we don't have a selected_items_ancestor, always show all nodes
				if (!this.framework_record.selected_items_ancestor) {
					for (let node of cfitem.tree_nodes) arr.push(node)
					continue
				}

				// else go through all nodes for this item
				let node_pushed = false
				for (let node of cfitem.tree_nodes) {
					// traverse up from the node; if we find selected_items_ancestor, push the node
					this.temp_node = node
					while (temp_node) {
						if (node.cfitem.identifier == this.framework_record.selected_items_ancestor) {
							arr.push(node)
							node_pushed = true
							break
						}
						temp_node = node.parent_node
					}
				}
				// if we didn't push at least one node above, push the first node for the item
				if (!node_pushed) {
					arr.push(cfitem.tree_nodes[0])
				}
			}

			// if open_selected_nodes_when_calculated was set to true...
			// console.log('open_selected_nodes_when_calculated: ' + this.framework_record.open_selected_nodes_when_calculated)
			if (this.framework_record.open_selected_nodes_when_calculated) {
				// open all the selected nodes' ancestors, so that the nodes are showing (and the nodes' children are showing)
				// but we don't here reset all open_nodes -- that may be done in PostMessageMixin, if appropriate
				for (let node of arr) {
					while (node.tree_key > 1) {
						this.$store.commit('set', [this.framework_record.open_nodes, node.tree_key+'', true])
						node = node.parent_node
					}
				}

				if (this.limited_to_selected_items == 'false_after_open') {
					this.$store.commit('set', [this.framework_record, 'limit_to_selected_items', false])
				}

				// always reset open_selected_nodes_when_calculated to false after we've shown the selected nodes
				this.$store.commit('set', [this.framework_record, 'open_selected_nodes_when_calculated', false])
			}

			return arr
		},
		limit_to_selected_items() { return this.framework_record.limit_to_selected_items },
		extension_fields() {
			// used by ItemsTable; kept here so we don't re-compute it if we go back and forth from the table. return object with extension fields that are used in this framework
			// TODO: we could in theory base this on whether the framework has any items whose item types match each field; but that might not actually work
			let o = {}
			for (let field in window.CASE_Custom_Extension_Fields.CFItem) {
				if (this.framework_record.json.CFItems.find(x=>x.extensions && !empty(x.extensions[field]))) {
					o[field] = window.CASE_Custom_Extension_Fields.CFItem[field]
				}
			}
			return o
			// return window.CASE_Custom_Extension_Fields.CFItem
		},
	},
	created() {
		// vapp.case_tree_component will always point to the currently-open viewer, so we can shortcut to current viewer properties with this
		// e.g. vapp.case_tree_component.user_can_edit
		vapp.case_tree_component = this

		this.show_doc_info = this.show_doc_info_on_open

		// whenever we mount this component, call set_embedded_mode_chooser_fn to set up the embedded mode chooser_fn if necessary
		vapp.set_embedded_mode_chooser_fn()
	},
	mounted() {
		// if the framework json has already been loaded, just call initialize_tree; otherwise call refresh_lsdoc to load the json
		if (this.framework_record.framework_json_loaded) {
			this.initialize_tree()
		} else {
			if (!this.framework_record.json) {
				this.$alert(sr('The framework specified in this URL ($1) is not available on this instance of $2.', this.lsdoc_identifier, this.$store.state.site_config.app_name))
				vapp.go_to_route('')
				return
			}

			// before loading, make sure the user has access to the framework. This prevents, e.g., viewing of a private framework by someone who doesn't have access to the framework
			if (!this.user_can_view) {
				this.$confirm({
				    text: sr('The framework specified in this URL ($1) is not publicly available at this time. Would you like to sign in?', this.case_tree_title),
				    acceptText: 'Sign In',
				    cancelText: 'View Public Frameworks',
					dialogMaxWidth: 600
				}).then(y => {
					vapp.sign_in()
				}).catch(n=>{
					vapp.go_to_route('')
				}).finally(f=>{})
				return
			}

			this.refresh_lsdoc()
		}

		// this.build_assoc_type_menu_options()
	},
	umounted() {
	},
	watch: {
		starting_lsitem_identifier: { immediate: true, handler() {
			// whenever starting_lsitem_identifier changes, if search results aren't showing and search_limit_to_item is false,...
			if (!this.search_results_panel_showing && !this.search_limit_to_item) {
				// ...clear search_item_for_limit
				this.search_item_for_limit = ''
			}

			if (!this.$store.state.case_tree_suppress_watcher) {
				// if we have a previous_starting_tree_key, close it
				if (this.previous_starting_tree_key) {
					this.$store.commit('set', [this.open_nodes, this.previous_starting_tree_key+'', '*DELETE_FROM_STORE*'])
				}

				if (!empty(this.starting_lsitem_identifier)) {
					this.$nextTick(x=>{
						// clear last_clicked_node -- if we don't do this here, when the user clicks "back" the previously-last-clicked item is still highlighted
						this.$store.commit('set', [this.framework_record, 'last_clicked_node', ''])

						this.make_item_parents_open(this.starting_lsitem_identifier, this.starting_tree_key)
						this.make_item_open(this.starting_lsitem_identifier, this.starting_tree_key)
						this.make_item_active(this.starting_lsitem_identifier, this.starting_tree_key)
					})
				}
			}

			this.previous_starting_tree_key = this.starting_tree_key
		}},
		show_comments() {
			// when user clicks to show comments, retrieve comments (and comment groups) from the DB
			if (this.show_comments) {
				this.get_comments_for_framework()
			}
		},
		'framework_record.last_clicked_node'() {
			if (this.$refs.associated_item_tree && this.$refs.associated_item_tree.remove_connecting_line) {
				this.$refs.associated_item_tree.remove_connecting_line()
			}
			this.last_clicked_association_identifier = null
			this.last_clicked_association_item_tree_key = null
			// this.hide_associated_item()
			// this.association_framework_tree_associationType = ''
			// this.association_line_from_tree_key = ''
			// this.association_framework_tree_identifier = null
			// this.association_framework_tree_item_identifier = null
		},

		showing_crosswalk() {
			this.update_associations_to_show()
		},
	},
	methods: {
		refresh_lsdoc(archive_filename, callback_fn) {
			// cancel stats mode when we refresh
			this.stats_mode = false

			// caller can optionally specify an archive_filename to load
			let payload = {
				lsdoc_identifier: this.lsdoc_identifier,
				archive_filename: archive_filename,
			}

			// if we're loading the framework for the first time (initialized is false), record the access of the framework
			if (!this.initialized) {
				payload.record_access = true
			}

			// console.log('CASEFrameworkViewer: refresh_lsdoc')
			U.loading_start('Loading framework…', 'refresh_lsdoc')
			this.$store.dispatch('get_lsdoc', payload).then(()=>{
				U.loading_stop('refresh_lsdoc')
				// set this.framework_record.framework_json_loaded to ensure we re-initialize the tree
				this.$store.commit('set', [this.framework_record, 'framework_json_loaded', false])

				// if we're embedded, the parent app may have sent in item identifiers for selected_items and/or the starting item that refer to items from another framework that have been duplicated in the current framework. if so, the code below will translate to the copied items’ identifiers
				U.process_framework_record_sourceItemIdentifiers(this.framework_record)
				if (this.starting_lsitem_identifier) {
					let original_identifier = U.get_originals_from_sourceItemIdentifiers(this.starting_lsitem_identifier, this.framework_record)
					if (original_identifier != this.starting_lsitem_identifier) {
						setTimeout(x=>this.set_starting_lsitem_identifier(original_identifier))
					}
				}

				this.initialize_tree()

				// if we just loaded an archive, leave framework_json_loaded at false so that if we leave and come back to this framework, we'll reload the current framework;
				// otherwise set framework_json_loaded to true now
				if (empty(archive_filename)) this.$store.commit('set', [this.framework_record, 'framework_json_loaded', true])

				// if callback_fn specified, call it
				if (typeof(callback_fn) == 'function') callback_fn()

			}).catch((e)=>{
				console.log(e)
				U.loading_stop('refresh_lsdoc')
				this.$alert('An error occurred when loading the competency framework.').then(x=>this.hide_tree())
			})
		},

		build_cfo() {
			// console.log('load_framework/build_cfo original: ' + this.framework_record.json.CFDocument.identifier)
			U.build_cfo(this.$worker, this.framework_record.json).then((cfo)=>{
//				console.log(cfo)
				// if show_fw_changes is on, add
				if (this.show_fw_changes && !empty(this.fw_changes)) {
					this.add_changes_to_cfo(cfo.cftree)
				}

				this.$store.commit('set', [this.framework_record, 'cfo', cfo])

				// restore pinned items from localstorage
				let s = this.$store.state.lst.pinned_items
				if (s) {
					let o = JSON.parse(s)
					if (o[this.framework_record.lsdoc_identifier]) {
						this.$store.commit('set', [this.framework_record, 'pinned_items', o[this.framework_record.lsdoc_identifier]])
					}
				}

				// if we received a start_string in the URL query string (this is set in App.vue/initialize)...
				if (!empty(this.start_string)) {
					// if we received an item identifier in start_string_identifiers (from initialize service), use it
					if (this.$store.state.start_string_identifiers && this.$store.state.start_string_identifiers.identifier) {
						console.log('here: ' + this.$store.state.start_string_identifiers.identifier)
						this.$store.commit('set', ['start_identifier', this.$store.state.start_string_identifiers.identifier])

					// else if start_string is a GUID, it *is* the identifier
					} else if (U.is_uuid(this.start_string)) {
						this.$store.commit('set', ['start_identifier', this.start_string])

					} else {
						// else start_string can be a humanCodingScheme value
						let hcs = this.start_string

						// allow for regexp(s) on the start_string value
						for (let a of this.$store.state.start_string_regexps) {
							hcs = hcs.replace(new RegExp(a[0]), a[1])
						}
						console.log(sr('start_string: $1 - $2', this.start_string, hcs))

						for (let identifier in this.framework_record.cfo.cfitems) {
							let item = this.framework_record.cfo.cfitems[identifier]
							// use indexOf instead of == so that "27.014" matches "27.01400"
							if (item.humanCodingScheme && item.humanCodingScheme.indexOf(hcs) == 0) {
								console.log('found start_string: ' + identifier)
								this.$store.commit('set', ['start_identifier', identifier])
								break
							}
						}
					}

					// if we got a start_identifier, go to tiles mode and set_starting_lsitem_identifier, so that we'll show the item below
					if (!empty(this.start_identifier)) {
						this.viewer_mode = 'tiles'
						this.$emit('set_starting_lsitem_identifier', this.start_identifier)
						let start_item_text = U.generate_cfassociation_node_uri_title(this.framework_record.cfo.cfitems[this.start_identifier], true)
						this.$alert({
							title: sr('Welcome to $1!', this.$store.state.site_config.app_name),
							text: sr('<p>You are currently viewing the standards for <b>$1</b> in “Tile Mode”.</p><p>Click the <i class="fas fa-tree"></i> icon in the lower-right corner of the window to switch to “Tree Mode” and reveal all standards in the <b>$2</b> framework.</p>', start_item_text, this.framework_record.json.CFDocument.title),
							dialogMaxWidth: 700,
							focusBtn: true,
						})
					} else {
						console.log('couldn’t find identifier for start_string ' + this.start_string)
					}
				}

				// if we received a starting_lsitem_identifier, show it here
				if (!empty(this.starting_lsitem_identifier)) {
					this.$store.commit('set', ['case_tree_suppress_watcher', true])
					setTimeout(x=>this.$store.commit('set', ['case_tree_suppress_watcher', false]), 10)

					this.make_item_parents_open(this.starting_lsitem_identifier, this.starting_tree_key)
					this.make_item_open(this.starting_lsitem_identifier, this.starting_tree_key)
					this.make_item_active(this.starting_lsitem_identifier, this.starting_tree_key)
				}

				// now do some more initialization things
				this.initialize_tree_post_build_cfo()
			})
			.catch((e)=>{
				U.loading_stop()
				console.log(e)
			})
		},

		show_help() { vapp.show_help() },
		show_framework_switcher() { vapp.show_framework_switcher() },

		set_starting_lsitem_identifier(item_identifier, tree_key) {
			this.$emit('set_starting_lsitem_identifier', item_identifier, tree_key)
			// use this hackish method to stop the watcher in CASEFrameworkViewer from firing right now; if it fired it would just try to re-activate what we've already activated above
			// TODO: clean this up??
			this.$store.commit('set', ['case_tree_suppress_watcher', true])
			setTimeout(x=>this.$store.commit('set', ['case_tree_suppress_watcher', false]), 10)
		},

		// this fn can be called by includers to first hide any open items, then show a single item, then scroll to the item
		show_item(identifier, flag) {
			if (flag != 'leave_open_nodes') this.$store.commit('set', [this.framework_record, 'open_nodes', {}])
			this.$store.commit('set', [this.framework_record, 'active_node', ''])
			this.$store.commit('set', [this.framework_record, 'last_clicked_node', ''])

			this.make_item_parents_open(identifier)
			this.make_item_open(identifier)
			this.make_item_active(identifier)

			// not sure what this is doing here...
			// setTimeout(x=>{
			// 	let target = $(sr('[data-tree-key=$1]', this.active_node))
			// 	if (target.length == 0) return
			// }, 0)
		},

		// add change data to the cfo tree, to efficiently show changed items in the tree. Note that we only do this when directed to show change data
		add_changes_to_cfo(node) {
			let change = this.fw_changes.find(o=>o.identifier == node.cfitem.identifier)
			// see UpdatesDocTable for fw_changes calculateion and format
			if (change && change.change_type != 'Date change only' && change.change_type != 'Unknown') {
				node.cfitem.stats.change = change
			}
			for (let i = 0; i < node.children.length; ++i) {
				this.add_changes_to_cfo(node.children[i])
			}
		},

		initialize_tree() {
			// console.log('initialize_tree')

			// this is stuff that has to be done *after* the document json has loaded
			if (empty(this.framework_record.cfo) || !this.framework_record.framework_json_loaded) {
				// build the cfo if it doesn't already exist, or if framework_json_loaded is set to false
				this.build_cfo()
			} else {
				// else do the things we have to do after build_cfo is complete
				this.initialize_tree_post_build_cfo()
			}

			// if editing is enabled and the user is allowed to edit this document, call check_out_for_editing
			if (this.editing_enabled && this.user_can_edit) {
				this.check_out_for_editing()
			}

			// if show_comments is on, call get_comments_for_framework
			if (this.show_comments) {
				this.get_comments_for_framework()
			}

			// if we have a stored track_changes_fn, start tracking
			if (this.track_changes_fn) {
				this.track_changes(this.track_changes_fn, this.archive_date, this.archive_note)
			}

			this.initialized = true
		},

		initialize_tree_post_build_cfo() {
			// wait one more tick to make sure everything is set up, then...
			this.$nextTick(()=>{
				// if this is a derivative and we don't have the original json, load it (before running update_frameworks_with_associations)
				if (this.is_derivative) {
					if (!this.derivative_original_json) {
						let payload = {
							lsdoc_identifier: this.cfdocument.extensions.sourceFrameworkIdentifier,
						}

						console.log('load_framework derivative: ' + payload.lsdoc_identifier)
						U.loading_start('Loading original framework…', 'refresh_lsdoc')
						this.$store.dispatch('get_lsdoc', payload).then(()=>{
							U.loading_stop('refresh_lsdoc')
							let fr = this.framework_records.find(x=>x.lsdoc_identifier==this.cfdocument.extensions.sourceFrameworkIdentifier)
							// if we didn't get an fr here, probably the original JSON could not be found
							if (!fr) {
								// we probably shouldn't be here though, because get_lsdoc should actually reject()
								console.log(sr('Derivative framework’s ($1) original framewwork ($2) could not be found [1]', this.cfdocument.identifier, this.cfdocument.extensions.sourceFrameworkIdentifier))
							} else {
								// then build the cfo for the original framework; we will need it to show associations
								U.build_cfo(this.$worker, fr.json).then((cfo)=>{
									this.$store.commit('set', [fr, 'cfo', cfo])
									this.$store.commit('set', [fr, 'framework_json_loading', false])

									// when we're done loading the original, pull associations from the original, then update_frameworks_with_associations
									U.pull_associations_from_original_of_derivative(this.framework_record, fr)
									this.update_frameworks_with_associations()
								})
								.catch((e)=>{
									this.$store.commit('set', [fr, 'framework_json_load_failed', true])
									console.log(e)
								})
							}

						}).catch((e)=>{
							// if we get to here, the original doc has disappeared
							U.loading_stop('refresh_lsdoc')
							console.log(e)
							console.log(sr('Derivative framework’s ($1) original framewwork ($2) could not be found [2]', this.cfdocument.identifier, this.cfdocument.extensions.sourceFrameworkIdentifier))
						})
					} else {
						// if we already have the original, pull its associations now, then update_frameworks_with_associations
						U.pull_associations_from_original_of_derivative(this.framework_record, this.derivative_original_framework_record)
						this.update_frameworks_with_associations()
					}
				} else {
					// not a derivative, so run update_frameworks_with_associations now
					this.update_frameworks_with_associations()
				}

				// $(this.$el).find('.k-case-tree-outer-wrapper').css({
				// 	'z-index': 10,
				// 	// this has to be set to match k-case-tree-banner-height below
				// 	'height': 'calc(100vh - 60px)'
				// })
				// this.toggle_maximize_tree(this.maximized)

				// // after time for things to open...
				// let delay = 1000
				// setTimeout(x=>{
					// start automatically calling the tree_scrolled fn
					clearTimeout(window.auto_tree_scrolled_timeout)	// this clears out previous timeout when we're in dev mode and we update the app
					this.auto_tree_scrolled()
				// }, delay)

				if (this.$store.state.viewer_post_load_execute_fn) {
					this.$store.state.viewer_post_load_execute_fn()
					this.$store.commit('set', ['viewer_post_load_execute_fn', null])
				}

				// send a message to the chooser host (if we're in embedded mode) that a framework has been loaded
				vapp.pm_send('framework_loaded', {framework_identifier: this.lsdoc_identifier, item_identifier: this.starting_lsitem_identifier, source: 1})
			})
		},

		active_item_position() {
			let tile_jq = $('.k-case-tree-active-item-tile-wrapper')

			// get position of node in tree, relative to the window
			let offset = $(sr('[data-tree-key=$1]', this.active_node)).offset()
			let item_top = offset.top

			let tile_top = item_top
			// if not inline, the tile will be positioned relative to tile_wrapper_top, which should be ~112px
			let tile_wrapper_jq = $('.k-case-tree-active-item-wrapper')
			if (tile_wrapper_jq.length > 0) {
				// so start with tile_top at item_top - tile_wrapper_top
				tile_top -= $('.k-case-tree-active-item-wrapper').offset().top
			}

			// if the tile would be too high, start by positioning at the top of the tile wrapper
			if (tile_top < 0) {
				tile_top = 0
			}

			// get tile_height and window height
			let tile_height = tile_jq.height()
			let window_height = $(window).height()
			// console.log('tile_height: ' + tile_height)

			// calculate where the bottom of the tile would be, relative to the window
			let tile_bottom = item_top + tile_height

			// if that's too low, move it up so it'll be at the bottom of the screen
			let tile_bottom_max = this.tracking_changes ? (window_height - 42) : (window_height - 6)
			if (tile_bottom > tile_bottom_max) {
				tile_top = tile_top - (tile_bottom - tile_bottom_max)
			}

			return [tile_jq, item_top, tile_top]
		},

		auto_tree_scrolled() {
			// run the fn that keeps the tile appearing in the right place every 100 ms, as long as the tree is showing
			// note that this fn is launched as soon as the tree is initialized; tree_scrolled is also attached to the scroll event of the tree
			if ($('[data-case-tree-top-identifier=' + this.lsdoc_identifier + ']').length == 0) {
				// console.log("CANCELLING")
			} else {
				this.tree_scrolled()
				window.auto_tree_scrolled_timeout = setTimeout(x=>this.auto_tree_scrolled(), 100)
			}
		},

		tree_scrolled() {
			// position toolbar
			let $scroll_wrapper = $(this.$el).find('.k-case-tree-scroll-wrapper')
			// top: at the top of the tree area; yoke to scroll to make it look nice
			let st = $scroll_wrapper.scrollTop()
			let $toolbar = $(this.$el).find('.k-cfv-toolbar')
			let tbt
			if (this.$vuetify.breakpoint.xs) {
				tbt = $(this.$el).find('.k-case-tree-top').outerHeight()
			} else {
				tbt = 8 - st
				if (tbt < 0) tbt = 0
			}
			// width: the same width as the scroll wrapper
			let tbw = $scroll_wrapper.width()
			// left: yoked to the position of the tree area
			let tbl = $(this.$el).find('.k-case-tree').offset()?.left
			$toolbar.css({'top': tbt + 'px', 'width': tbw+'px', 'left': tbl+'px'})
			
			if (!this.maximized || this.$vuetify.breakpoint.xs || !this.active_node || $(sr('[data-tree-key=$1]', this.active_node)).length == 0) return
			// when we scroll the tree, position the active tile to try to make it even with the node in the tree
			// see companion watcher function in CASETree.vue

			let [tile_jq, item_top, tile_top] = this.active_item_position()

			// console.log('tile_top: ' + tile_top + ' / current top: ' + tile_jq.css('top'))
			tile_jq.css('top', tile_top + 'px')
		},

		scroll_to_item(node_tree_key) {
			if (empty(node_tree_key)) node_tree_key = this.active_node
			if (empty(node_tree_key)) return
			let tree_jq = $(this.$refs.main_viewer_tree.$el)
			let node_jq = tree_jq.find(sr('[data-tree-key=$1]', node_tree_key))
			if (node_jq.length == 0) return

			let node_top = node_jq.position().top
			let node_bottom = node_top + node_jq.height()
			let tree_height = tree_jq.height()
			let current_scroll_top = tree_jq.scrollTop()

			let scroll_to
			if (node_top < 64) {
				// move up (so subtract from scollTop) 64 - node_top
				scroll_to = current_scroll_top - (64 - node_top)
			} else if (node_bottom > tree_height - 64) {
				// move down (so add to scrollTop)
				scroll_to = current_scroll_top + (node_bottom - tree_height + 64)
			}

			// console.log(node_top, tree_height, current_scroll_top, scroll_to)

			if (!empty(scroll_to)) $(this.$refs.main_viewer_tree.$el).animate({scrollTop: scroll_to+'px'}, 50)
		},

		adjust_font_size(delta) {
			this.font_size += delta
			if (this.font_size < -2) this.font_size = -2
			if (this.font_size > 2) this.font_size = 2
		},

		set_show_chooser_fn(val) {
			// this can be used to set the show_chooser_fn to a function, then set it back to false when it's done
			this.show_chooser_fn = val
		},

		find_cfitem_from_key(node, key) {
			if (empty(node)) return null
			if (node.tree_key == key) return node.cfitem
			if (empty(node.children) || node.children.length == 0) return null
			for (let child of node.children) {
				let r = this.find_cfitem_from_key(child, key)
				if (!empty(r)) return r
			}
			return null
		},

		clear_last_clicked_node() {
			// console.log('clear_last_clicked_node')
			this.search_panel_to_back()
			this.$store.commit('set', [this.framework_record, 'last_clicked_node', ''])
		},

		show_expanded_item(item, item_framework_record) {
			// note that "item" here must be a tree node (not a cfitem)
			if (!item_framework_record) item_framework_record = this.home_framework_record
			this.$refs.expanded_tile.show_item(item, item_framework_record)
		},

		hide_expanded_item() {
			this.$refs.expanded_tile.hide()
		},

		// the 'make_item_xxx' fns handle cases where work on the basis of an identifier; the 'make_node_xxx' fns handle cases where we want to do something with a single node
		make_item_open(identifier, tree_key) {
			let cfitem = this.cfitems[identifier]
			if (empty(cfitem)) return

			// the tree_key param can be 'show_all' to show all nodes with the given identifier;
			// or it could specify a specific tree_key;
			// or it could be empty, in which case we show the first node for the identifier
			let show_all = false
			if (tree_key == 'show_all') {
				show_all = true
				tree_key = ''
			}

			// open this item
			for (let tree_node of cfitem.tree_nodes) {
				// if we're looking for a specific tree_key and this isn't it, continue
				if (!empty(tree_key) && tree_node.tree_key+'' != tree_key+'') continue

				// don't open nodes that have no children
				if (tree_node.children.length == 0) continue

				if (!this.open_nodes[tree_node.tree_key]) {
					this.$store.commit('set', [this.open_nodes, tree_node.tree_key+'', true])
				}

				// if we're not showing all nodes, break after we show one
				if (!show_all) break
			}
		},

		make_item_active(identifier, tree_key) {
			let cfitem = (identifier == this.cfdocument.identifier) ? this.cfdocument : this.cfitems[identifier]
			if (empty(cfitem)) return

			// activate this item the first place it appears in the case_tree, or at tree_key of provided
			for (let node of cfitem.tree_nodes) {
				// if a tree_key was provided, continue until we find the node with that tree_key
				if (!empty(tree_key) && node.tree_key+'' != tree_key+'') continue
				this.$store.commit('set', [this.framework_record, 'active_node', node.tree_key])
				break
			}
		},

		make_item_parents_open(identifier, tree_key) {
			let cfitem = this.cfitems[identifier]
			if (empty(cfitem)) return
			// see logic around tree_key for make_item_open above

			let show_all = false
			if (tree_key == 'show_all') {
				show_all = true
				tree_key = ''
			}

			// open the item's ancestors
			for (let node of cfitem.tree_nodes) {
				if (!empty(tree_key) && node.tree_key+'' != tree_key+'') continue

				let temp_node = node
				while (!empty(temp_node.parent_node)) {
					this.$store.commit('set', [this.open_nodes, temp_node.parent_node.tree_key+'', true])
					temp_node = temp_node.parent_node
				}

				if (!show_all) break
			}
		},

		// the 'make_item_xxx' fns handle cases where work on the basis of an identifier; the 'make_node_xxx' fns handle cases where we want to do something with a single node
		make_node_open(tree_key) {
			let tree_node = this.cfo.tree_nodes_hash[tree_key]
			if (empty(tree_node)) return

			// don't open nodes that have no children
			if (tree_node.children.length == 0) return

			if (!this.open_nodes[tree_node.tree_key]) {
				this.$store.commit('set', [this.open_nodes, tree_node.tree_key+'', true])
			}
		},

		make_node_active(tree_key, and_set_url) {
			// if tree_key is empty string, or this tree_key doesn't exist, clear the active_node
			let tree_node = this.cfo.tree_nodes_hash[tree_key]
			let identifier

			if (empty(tree_node)) {
				tree_key = ''
				identifier = ''
			} else {
				identifier = tree_node.cfitem.identifier
			}

			// activate this node (or set to empty string)
			this.$store.commit('set', [this.framework_record, 'active_node', tree_key])

			// if and_set_url is true, also set the url
			if (and_set_url === true) {
				this.set_starting_lsitem_identifier(identifier)
			}
		},

		make_node_parents_open(tree_key) {
			let tree_node = this.cfo.tree_nodes_hash[tree_key]
			if (empty(tree_node)) return

			// open the node's ancestors
			while (!empty(tree_node.parent_node)) {
				this.$store.commit('set', [this.open_nodes, tree_node.parent_node.tree_key+'', true])
				tree_node = tree_node.parent_node
			}

			// console.log('make_node_parents_open', JSON.stringify(this.open_nodes))
		},

		toggle_doc_info(show) {
			if (typeof(show) != 'boolean') show = !(this.show_doc_info)
			this.show_doc_info = show
		},

		collapse_all(callback) {
			// putting this and the expand_all code in the worker shell makes it so that the loading indicator shows while vue is showing things
			U.loading_start('Collapsing all items…')
			this.$worker.run(() => {
				return
			}, [])	// this is where we pass the original data into the fn
			.then(()=>{
				U.loading_stop()
				this.$store.commit('set', [this.framework_record, 'open_nodes', {}])
				this.$store.commit('set', [this.framework_record, 'active_node', ''])
				this.$store.commit('set', [this.framework_record, 'last_clicked_node', ''])

				// reset item identifier from url
				this.set_starting_lsitem_identifier('')

				if (typeof(callback) == 'function') callback()
			})
			.catch(()=>{
				U.loading_stop()
				console.error // logs any possible error
			})
		},

		expand_all() {
			// not currently accessible from the UI
			U.loading_start('Expanding all items…')
			this.$store.commit('set', [this.framework_record, 'last_clicked_node', ''])
			this.$worker.run((cfo) => {
				let obj = {}
				for (let identifier in cfo.cfitems) {
					for (let i = 0; i < cfo.cfitems[identifier].tree_nodes.length; ++i) {
						if (cfo.cfitems[identifier].tree_nodes[i].children.length > 0) {
							obj[cfo.cfitems[identifier].tree_nodes[i].tree_key] = true
						}
					}
				}
				return obj

			}, [this.cfo])	// this is where we pass the original data into the fn
			.then((obj)=>{
				U.loading_stop()
				this.$store.commit('set', [this.framework_record, 'open_nodes', obj])
				// reset item identifier from url
				this.set_starting_lsitem_identifier('')
			})
			.catch(()=>{
				U.loading_stop()
				console.error // logs any possible error
			})
		},

		hide_tree() {
			// when this framework's tree is hidden, clear start_string
			// console.log('hide_tree')
			this.$store.commit('set', ['start_string', ''])
			this.$store.commit('set', ['start_identifier', ''])
			this.$store.commit('set', ['start_string_identifiers', ''])
			this.$emit('hide_tree')
		},

		more_info(item, flag) {
			this.more_info_item = {}

			if (item == 'document' || empty(item.parent_node)) {
				this.more_info_item = this.framework_record.json.CFDocument
				// let cfdocument
				// if (item == 'document') cfdocument = this.cfo.cfdocument
				// else cfdocument = item.cfitem
				// for (let key in cfdocument) {
				// 	if (key == 'tree_nodes' || key == 'stats') continue
				// 	this.more_info_item[key] = cfdocument[key]
				// }
				this.more_info_type = 'document'

			} else {
				// CF item
				this.more_info_item = this.framework_record.json.CFItems.find(x=>x.identifier==item.cfitem.identifier)
				// for (let key in item.cfitem) {
				// 	if (key == 'tree_nodes' || key == 'stats') continue
				// 	this.more_info_item[key] = item.cfitem[key]
				// }
				this.more_info_type = 'item'

				// removed 3/4/2023
				// if (item.cfitem.stats.change) this.more_info_item.change = item.cfitem.stats.change
			}

			if (flag == 'show_history') this.more_info_show_delta = true
			else this.more_info_show_delta = false
		},

		print_view(item) {
			this.print_node = item
			this.show_print_dialog = true
		},

		framework_viewer_clicked() {
			this.clear_last_clicked_node()

			// if not maximized, when framework viewer is clicked, make sure it's in front of the editor
			if (this.maximized) return
			$('.k-case-tree-outer-wrapper').css('z-index', 101)
			$('.k-case-item-editor-outer').css('z-index', 100)
		},

		toggle_move_mode(flag, param) {
			if (this.show_move_fn) {
				this.show_move_fn = false
				this.move_node_shift_right = null

			} else {
				// set the show_move_fn; this will both toggle the move interface to show, and toggle the tree to show move handles
				this.show_move_fn = (evt) => {
					this.$refs.move_editor.item_move_drag_complete(evt)
				}
				// cancel document/item edit if open
				this.cancel_edit_document()
				this.cancel_item_edit()
			}

			// set move_mode_flag and param -- e.g. SideBySideEditor calls this with a flag so that when the user finishes moving we re-open the SideBySideEditor
			this.move_mode_flag = flag
			this.move_mode_param = param
		},

		start_move_node_conversion() {
			this.move_node_shift_right = null
			this.show_move_node_conversion = true
		},

		finish_move_node_conversion(tree_key) {
			if (tree_key) this.move_node_shift_right = tree_key
			this.show_move_node_conversion = false
		},

		sort_children(parent, confirmed) {
			if (!confirmed) {
				this.$confirm({
					text: 'Are you sure you want to sort the child items of this node?<br><b>This is not undoable.</b>',
					acceptIconAfter: 'fas fa-circle-arrow-right',
					acceptText: 'Sort',
				}).then(y => {
					this.sort_children(parent, true)
				}).catch(n=>{console.log(n)}).finally(f=>{})
				return
			}

			// sort by hcs/fs -- have to do this for all places where the parent exists in the tree
			for (let parent_node of parent.cfitem.tree_nodes) {
				parent_node.children.sort((a,b) => {
					// sort by hcs if either or both have one
					if (a.cfitem.humanCodingScheme && !b.cfitem.humanCodingScheme) return -1
					if (!a.cfitem.humanCodingScheme && b.cfitem.humanCodingScheme) return 1
					if (a.cfitem.humanCodingScheme && b.cfitem.humanCodingScheme) {
						return U.natural_sort(a.cfitem.humanCodingScheme, b.cfitem.humanCodingScheme)
					}

					// else sort by fs
					return U.natural_sort(a.cfitem.fullStatement, b.cfitem.fullStatement)
				})
			}

			// construct data for update
			let data = {
				lsdoc_identifier: this.lsdoc_identifier,
				CFAssociations: [],
			}

			let data_updated = false
			for (let parent_node of parent.cfitem.tree_nodes) {
				let arr = parent_node.children
				for (let i = 0; i < arr.length; ++i) {
					let child = arr[i]
					// console.log(sr('$1: $2', child.cfitem.humanCodingScheme, child.cfitem.fullStatement))

					// for forms' sake, update the sequence in the node, although we really only use these node.sequence values when the tree is originally created
					this.$store.commit('set', [child, 'sequence', i+1])

					// update the sequenceNumber in the framework_record, and add to data
					if (!data_updated) {
						// find the association for this item
						let index = U.find_cfassociation_index(this.framework_record.json.CFAssociations, child.cfitem.identifier, parent.cfitem.identifier)
						let target_association = this.framework_record.json.CFAssociations[index]

						// set lastChangeDateTime to *NOW* so server will update to the correct timestamp
						this.$store.commit('set', [target_association, 'lastChangeDateTime', '*NOW*'])
						this.$store.commit('set', [target_association, 'sequenceNumber', i+1])
						data.CFAssociations.push(new CFAssociation(target_association))
					}
				}

				// set data_updated to true here so that we only add CFAssociations to data when we process the first parent
				data_updated = true
			}

			// console.log(data)

			// save the updated cfassociations
			data.show_spinner = true
			this.$store.dispatch('save_framework_data', data).then(()=>{
				// when done, update the CFAssociations' lastChangeDateTime
				for (let cfa of data.CFAssociations) {
					if (cfa.lastChangeDateTime == '*NOW*') {
						let json_CFA = this.framework_record.json.CFAssociations.find(x=>x.identifier == cfa.identifier)
						this.$store.commit('set', [json_CFA, 'lastChangeDateTime', this.$store.state.framework_lastChangeDateTime])
					}
				}
			})
		},

		toggle_batch_edit_mode() {
			if (this.show_checkbox_fn) {
				this.show_checkbox_fn = false

			} else {
				// set the show_checkbox_fn; this will both toggle the batch editor interface to show, and toggle the tree to show checkboxes
				this.show_checkbox_fn = (identifier_clicked, tree_key_clicked, val, evt) => {
					this.$refs.batch_editor.checkbox_clicked(identifier_clicked, tree_key_clicked, val, evt)
				}
				// cancel document/item edit if open
				this.cancel_edit_document()
				this.cancel_item_edit()
			}
		},

		toggle_make_associations(node) {
			// if on, turn off
			if (this.show_make_associations_interface) {
				this.show_make_associations_interface = false

			} else {
				// else turn on; if node is provided, chose that node on open
				this.show_make_associations_interface = true
				if (node && node.tree_key) {
					setTimeout(x=>{
						this.current_editor.item_chosen_on_left(node)
					}, 100)
				}
				// make sure associations are showing when this goes on
				this.show_associations = true

				// cancel document/item edit if open
				this.cancel_edit_document()
				this.cancel_item_edit()
			}
		},

		toggle_resource_alignments() {
			if (this.show_resource_alignments_interface) {
				this.show_resource_alignments_interface = false

			} else {
				// else turn on
				this.show_resource_alignments_interface = true
				// make sure associations are showing when this goes on
				this.show_associations = true

				// cancel document/item edit if open
				this.cancel_edit_document()
				this.cancel_item_edit()
			}
		},

		toggle_show_comments() {
			if (!this.signed_in && !this.public_review_on) {
				this.$alert('Please sign in to create or view comments.')
			} else {
				this.show_comments = !this.show_comments
			}
		},

		archive_framework() {
			if (this.is_mirror) {
				this.mirror_update_by_user(this.framework_record)
				return
			}

			this.$prompt({
				title: 'Archive Framework',
				text: '<p>Enter a short description to identify the archive. (The archive’s record will always include the current date and time, so there’s no need to specify the date/time in your description.)</p>',
				promptType: 'textarea',
				initialValue: '',
				acceptText: 'Save Archive',
				dialogMaxWidth: 620,
			}).then(phrase => {
				if (empty(phrase)) {
					this.$alert('You must enter a description of the archive.')
					return
				}

				let payload = {
					lsdoc_identifier: this.CFDocument.identifier,
					phrase: $.trim(phrase),
					cf_item_count: this.framework_record.json.CFItems.length,
					cf_association_count: this.framework_record.json.CFAssociations.length,
				}
				this.$store.dispatch('archive_lsdoc', payload).then((archive_time)=>{
					archive_time = archive_time.replace(/(\d\d\d\d-\d\d-\d\d)--(\d\d)-(\d\d)-(\d\d)/, '$1 $2:$3:$4')
					archive_time = date.format(U.convert_to_local_date(archive_time, 0), 'M/D/YYYY h:mm A')	// Jan 1, 2019 3:12 PM
					this.$alert(sr('Framework archived at $1 (local time).', archive_time)).then(x=>{
						// trigger a reload of the framework's archives
						vapp.reset_framework_update_report_archives(this.CFDocument.identifier)
					})
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		delete_framework() {
			this.$confirm({
			    title: 'Delete Framework',
			    text: 'Are you sure you want to delete this framework? The framework will be moved to the archives, and will no longer appear in the framework list.',
			    acceptText: 'Delete',
				acceptColor: 'red',
			}).then(y => {
				this.$store.dispatch('delete_lsdoc', this.CFDocument.identifier).then(()=>{
					this.hide_tree()
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		check_out_for_editing() {
			// mirrored framworks do not need an edit lock and abbreviated options under edit menus
			if (this.is_mirror) {
				this.editing_enabled = true
				return
			}

			let payload = {
				'framework_identifier': this.CFDocument.identifier,
				'edit_action': 'framework_checkout',
				'cf_item_count': this.framework_record.json.CFItems.length,
				'cf_association_count': this.framework_record.json.CFAssociations.length,
			}

			this.$store.dispatch('manage_edit_lock', payload).then((result)=>{
				console.log('edit request: ' + result.status)

				this.editing_enabled = true
			}).catch((e)=>{
				// if this doesn't work, set editing_enabled to false
				this.editing_enabled = false
				console.log(e)
			})
		},

		check_in_for_editing() {
			let payload = {
				'framework_identifier': this.CFDocument.identifier,
				'edit_action': 'framework_checkin'
			}
			this.$store.dispatch('manage_edit_lock', payload)
			this.editing_enabled = false
		},

		toggle_editing() {
			if (this.editing_enabled) this.check_in_for_editing()
			else this.check_out_for_editing()
		},

		edit_document(action) {
			this.cancel_item_edit()
			this.editing_document = true
			if (action) {
				this.$nextTick(x=>this.current_editor[action]())
			}
		},

		cancel_edit_document() {
			this.editing_document = false
		},

		edit_item(item, action) {
			this.cancel_edit_document()
			this.edited_node = null
			this.$nextTick(x=>{
				this.edited_node = item
				// do action
				if (action) {
					// for actions 'suggestions_only' or 'apply_suggestions', on nextTick (after the editor is showing...)
					if (action == 'suggestions_only') {
						this.$nextTick(x=>{
							// call the 'enter_suggestions_only_mode' fn in the editor, passing in arguments[2], which can include an object with previous suggested edits
							this.current_editor.enter_suggestions_only_mode(arguments[2])
						})
					} else if (action == 'apply_suggestions') {
						this.$nextTick(x=>{
							// call the 'apply_suggestions' fn in the editor, passing in arguments[2], which will include an object with previous suggested edits
							this.current_editor.apply_suggestions(arguments[2])
						})
					} else {
						// else execute the fn designated by action
						this.$nextTick(x=>this.current_editor[action]())
					}
				}
			})
		},

		cancel_item_edit() {
			this.edited_node = null
		},

		item_edit_suggestion_saved(CFItem) {
			// cancel the item editor; then the tile will reappear with the comment editor still open
			this.cancel_item_edit()

			// then after a tick to let things settle down, pass the edited CFItem back to the comment editor
			setTimeout(x=>{
				if (this.comment_editor) {
					this.comment_editor.item_edits_suggested(CFItem)
				}
			}, 100)
		},

		item_edit_suggestion_applied(CFItem) {
			// cancel the item editor; then the tile will reappear with the comment editor still open
			this.cancel_item_edit()

			// after a tick to let the editor close and the comment viewer show, pass the edited CFItem back to this.comment_editor (which will actually be the comment viewer)
			setTimeout(x=>{
				if (this.comment_editor) {
					this.comment_editor.suggested_edits_applied(CFItem)
				}
			}, 100)
		},

		edit_new_child(new_child_node) {
			this.edited_node = null
			this.$nextTick(()=>{
				this.edited_node = new_child_node
				// this.make_node_active(new_child_node)
			})
		},

		// called from an item tile, AssociationsTable, or MakeAssociationsInterface
		remove_association(assoc) {
			return new Promise((resolve, reject)=>{
				if (this.remove_association_confirmed) {
					this.remove_association_finish(assoc)
					resolve()
				} else {
					this.$confirm({
					    title: 'Delete Association',
					    text: 'Are you sure you want to delete this association? (You can always re-create the association later if you wish.)',
					    acceptText: 'Delete Association',
						acceptColor: 'red',
					}).then(y => {
						this.remove_association_finish(assoc)
						this.remove_association_confirmed = true
						resolve()
					}).catch(n=>{console.log(n)}).finally(f=>{})
				}
			})
		},

		remove_association_finish(assoc) {
			// associations can be housed either in the "home" framework or a dedicated crosswalk framework
			if (this.framework_record.json.CFAssociations.find(x=>x.identifier == assoc.identifier)) {
				console.warn('delete from home framework')
				this.$store.dispatch('delete_associations', {framework_record: this.framework_record, associations_to_delete: [assoc]})
			} else {
				// look for the other framework's identifier in origin and destination nodes' titles
				let other_framework_identifier = U.get_framework_identifier_from_cfassociation_node_uri_title(assoc.originNodeURI.title)
				if (!other_framework_identifier || other_framework_identifier == this.framework_record.lsdoc_identifier) {
					other_framework_identifier = U.get_framework_identifier_from_cfassociation_node_uri_title(assoc.destinationNodeURI.title)
				}

				if (other_framework_identifier) {
					let crosswalk_framework_record = U.get_crosswalk_framework_record(this.framework_record.lsdoc_identifier, other_framework_identifier)
					if (crosswalk_framework_record) {
						console.warn('delete from other framework ' + other_framework_identifier)
						this.$store.dispatch('delete_associations', {framework_record: crosswalk_framework_record, associations_to_delete: [assoc], check_out_and_in: 'yes'})
						return
					}
				}
				// if we get to here the delete didn't work
				this.$alert('We couldn’t find the framework where the association “lives”, so the association couldn’t be deleted.')
			}
		},

		// note that we may or may not specify an item_tree_key
		toggle_associated_item(associationType, node1, framework_identifier2, item_identifier2, item_tree_key2) {
			if (this.last_clicked_association_identifier && this.association_framework_tree_identifier == framework_identifier2 && this.association_framework_tree_item_identifier == item_identifier2 && this.association_framework_tree_item_tree_key == item_tree_key2 && this.association_framework_tree_associationType == associationType) {
				this.hide_associated_item()
			} else {
				// do this nextTick thing so that this re-shows the association line when the user has clicked off of the originally-shown item, then clicks back in and wants to re-show the line
				this.hide_associated_item()
				this.$nextTick(x=>this.show_associated_item(associationType, node1, framework_identifier2, item_identifier2, item_tree_key2))
			}
		},

		show_associated_item(associationType, node1, framework_identifier2, item_identifier2, item_tree_key2) {
			this.association_framework_tree_left_node = node1
			this.association_framework_tree_associationType = associationType
			this.association_framework_tree_identifier = framework_identifier2
			this.association_framework_tree_item_identifier = item_identifier2
			this.association_framework_tree_item_tree_key = item_tree_key2

			// this causes this association to be highlighted (bolded) in CASEItemAssociations.vue
			this.last_clicked_association_identifier = item_identifier2
			this.last_clicked_association_item_tree_key = item_tree_key2

			this.$nextTick(x=>{
				this.association_line_from_tree_key = node1.tree_key
			})
		},

		// when a track-changes icon is clicked on the left, show the corresponding item on the right
		show_archived_item(node1) {
			this.hide_associated_item()
			this.$nextTick(x=>{
				this.association_framework_tree_left_node = node1
				this.association_framework_tree_associationType = 'archive'
				this.association_framework_tree_identifier = this.lsdoc_identifier
				this.association_framework_tree_item_identifier = node1.cfitem.identifier

				this.make_node_active(node1.tree_key)
				this.make_node_open(node1.tree_key)	// make the node open when we show its archived equivalent
				this.$store.commit('set', [this.framework_record, 'last_clicked_node', node1.tree_key])

				this.$nextTick(x=>{
					this.association_line_from_tree_key = node1.tree_key
				})
			})
		},

		// when an archived item's track-changes icon is clicked on the right, show the corresponding item on the left
		show_current_item_from_archive(archive_node) {
			let item_identifier = archive_node.cfitem.identifier
			let cfitem = this.framework_record.cfo.cfitems[item_identifier]
			// if item doesn't exist in the current tree, return
			if (!cfitem) return

			// clear the line that's currently showing; then select the item on the left and draw a new line
			this.association_line_from_tree_key = ''
			this.$nextTick(x=>{
				this.association_framework_tree_left_node = current_node
				this.association_framework_tree_associationType = 'archive'
				this.association_framework_tree_identifier = this.lsdoc_identifier
				this.association_framework_tree_item_identifier = item_identifier
				this.association_framework_tree_item_tree_key = archive_node.tree_key

				let current_node = cfitem.tree_nodes[0]
				this.make_node_parents_open(current_node.tree_key)
				this.make_node_active(current_node.tree_key)
				this.make_node_open(current_node.tree_key)	// make the node open when we show its archived equivalent
				this.$store.commit('set', [this.framework_record, 'last_clicked_node', current_node.tree_key])

				this.$nextTick(x=>{
					this.association_line_from_tree_key = current_node.tree_key
				})
			})
		},

		switch_associated_item(framework_identifier2, item_identifier2, item_tree_key2) {
			// this is for a pretty specific purpose: if you're viewing an item on the left, and that item has associated items,
			// and you click to view an associated item on the right, and you click to expand the associated item's tile,
			// and that associated item itself has associations, and you click on one of the associated item's associated items...
			// we want to switch to showing the associated item's associated item on the right,
			// then immediately expand the tile of the associated item's associated item
			this.hide_associated_item()

			// do this on next tick so that the open_item fn will be called in CASEAssociatedItemTree -- this opens the item's parents
			this.$nextTick(x=>{
				this.association_framework_tree_left_node = null		// ??
				this.association_framework_tree_associationType = ''	// ??
				this.association_framework_tree_identifier = framework_identifier2
				this.association_framework_tree_item_identifier = item_identifier2
				this.association_framework_tree_item_tree_key = item_tree_key2

				// this causes this association to be highlighted in CASEItemAssociations.vue
				this.last_clicked_association_identifier = item_identifier2
				this.last_clicked_association_item_tree_key = item_tree_key2

				// then after another brief delay, simulate clicking the "expand" button on the newly-revealed item
				setTimeout(x=>{
					if (vapp.last_clicked_item_component && vapp.last_clicked_item_component.item && vapp.last_clicked_item_component.item.cfitem.identifier == this.association_framework_tree_item_identifier) {
						vapp.last_clicked_item_component.show_expanded_tile()
					}
				}, 10)
			})
		},

		hide_associated_item() {
			this.association_framework_tree_left_node = null
			this.association_framework_tree_associationType = ''
			this.association_framework_tree_identifier = null
			this.association_framework_tree_item_identifier = null
			this.association_framework_tree_item_tree_key = null
			this.association_line_from_tree_key = ''
			this.last_clicked_association_identifier = null
			this.last_clicked_association_item_tree_key = null
		},

		// if window is resized, save width and height to store
		tree_resized(x, y, width, height) {
			this.$store.commit('set', ['case_tree_viewer_width', width])
			this.$store.commit('set', ['case_tree_viewer_height', width])
		},

		toggle_maximize_tree(maximized_val) {
			if (typeof(maximized_val) != 'boolean') maximized_val = !this.maximized
			this.maximized = maximized_val
		},

		// view the original item that a duplicated node was copied from
		// NOTE: view_original_item and view_item_copies aren't currently used, but might be useful in the future
		view_original_item(copied_node) {
			// return if the copied item isn't a duplicate (?)
			if (!(copied_node.cfitem.extensions.sourceItemIdentifier && copied_node.cfitem.extensions.sourceItemIdentifier != copied_node.cfitem.identifier)) return

			let sourceItemIdentifier = copied_node.cfitem.extensions.sourceItemIdentifier

			// get the original's lsdoc_identifier (which might be the same framework we're viewing)
			U.get_lsdoc_identifier_from_item_identifier(sourceItemIdentifier).then((lsdoc_identifier)=>{
				// found it -- so show the original in the association tree (the associated item tree component will load the framework's cfo if necessary)
				this.show_associated_item('', copied_node, lsdoc_identifier, sourceItemIdentifier)

			}).catch((e)=>{
				console.log(e)
				this.$alert('Couldn’t identify the source of the original item.')
			})
		},

		// view copies of an node, which could be an alias (look up other items with the same identifier) 
		// and/or a duplicate (look up the item whose identifier matches copied_node's sourceItemIdentifier)
		view_item_copies(copied_node) {
			// let identifier = this.item_is_copy ? this.item.cfitem.extensions.sourceItemIdentifier : this.item.cfitem.identifier
			// see if the copied item is a duplicate
			let copied_node_identifier = copied_node.cfitem.identifier
			let sourceItemIdentifier = copied_node.cfitem.extensions.sourceItemIdentifier
			let copied_node_is_duplicate = (sourceItemIdentifier && sourceItemIdentifier != copied_node_identifier)
			
			// collapse all items, then open copies; by definition we will re-open the current item
			this.collapse_all(x=>{

				// start by opening all aliases of copied_node, and setting count to the number of nodes we open here
				this.make_item_parents_open(copied_node_identifier, 'show_all')
				let count = copied_node.cfitem.tree_nodes.length

				// now find duplicates of the item or the item's sourceItemIdentifier
				let found_original = false
				for (let cfitem of this.framework_record.json.CFItems) {
					if (cfitem.identifier == copied_node_identifier) continue

					let open_it = false					
					// the original that copied_node was duplicated from
					if (cfitem.identifier == sourceItemIdentifier) {
						open_it = true
						found_original = true
					
					// a duplicate of copied_node
					} else if (cfitem.extensions.sourceItemIdentifier == copied_node_identifier) {
						open_it = true

					// another duplicate of copied_node's original
					} else if (sourceItemIdentifier && cfitem.extensions.sourceItemIdentifier == sourceItemIdentifier) {
						open_it = true
					}

					if (open_it) {
						this.make_item_parents_open(cfitem.identifier, 'show_all')
						++count
					}
				}
				// don't make items open -- if the items are folders with lots of children this makes things confusing
				// this.make_item_open(identifier, tree_key)

				// make the current item active, and clear last_clicked_node
				this.make_item_active(copied_node_identifier, copied_node.tree_key)
				this.$store.commit('set', [this.framework_record, 'last_clicked_node', ''])

				// do a snackbar; if the user clicks the btn on the snackbar, view the original item
				let options = {text: '', color: 'amber darken-4', snackbarTimeout:5000, callback_fn:() => {
					this.view_original_item(copied_node)
				}}

				if (copied_node_is_duplicate) options.closeText = 'View Original'

				// if we didn't find a copy in this framework
				if (count == 0) {
					if (copied_node_is_duplicate) options.text = 'This item was duplicated from an item in another framework.'
					else options.text = 'No copies of the item were found in this framework. The item may have been copied into a different framework.'

				// else we *did* find a copy in this framework
				} else {
					if (copied_node_is_duplicate) {
						if (found_original) options.text = 'This item was duplicated from another item.'
						else options.text = 'This item was duplicated from an item in another framework.'
					} else options.text = 'Copies are marked with amber triangles.'
				}
				
				this.$inform(options)
			})
		},

		//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		// "Statistics" mode

		toggle_stats_mode() {
			if (this.stats_mode) {
				this.stats_mode = false
			} else {
				U.process_cfo_stats(this.$worker, this.cfo).then((new_cfo)=>{
					U.loading_stop()
					this.$store.commit('set', [this.framework_record, 'cfo', new_cfo])
					this.stats_mode = true
				})
				.catch(()=>{
					U.loading_stop()
					console.error // logs any possible error
				})
			}
		},

		//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		// MISC FUNCTIONS

		// toggle whether "selected_items" are showing only -- used for embed mode
		toggle_show_selected_items(toggle_on) {
			if (typeof(toggle_on) != 'boolean') toggle_on = !this.framework_record.limit_to_selected_items
			this.$store.commit('set', [this.framework_record, 'limit_to_selected_items', toggle_on])

			// if we're toggling on, set open_selected_nodes_when_calculated to true so that we open the selected items' ancestors in the selected_nodes computed
			if (toggle_on) this.$store.commit('set', [this.framework_record, 'open_selected_nodes_when_calculated', true])
		},

		// split into multiple frameworks
		split_framework(evt) {
			let export_files = (evt && evt.metaKey === true)
			let export_files_text = ''
			if (export_files) export_files_text = '<div class="mt-2"><b>Since you were holding down the COMMAND/CTRL key when you clicked the command, the split files will be exported, rather than being saved to the server.</b></div>'

			let msg = sr('This operation will split the current framework into $1 separate frameworks, one for each top-level item:<ul class="mt-2">', this.cftree.children.length)
			for (let child of this.cftree.children) {
				msg += sr('<li>$1</li>', child.cfitem.fullStatement)
			}
			msg += sr('</ul><div class="mt-2">Each new document will take as its document identifier (GUID) the identifier of the top-level item in the current framework.</div><div class="mt-2">The current framework will remain unchanged.</div>$1<div class="mt-2">Do you want to proceed?</div>', export_files_text)
			this.$confirm({
			    title: 'Split Into Multiple Frameworks',
			    text: msg,
			    acceptText: 'Create Split Frameworks',
				dialogMaxWidth: 800
			}).then(y => {
				this.split_framework_process(export_files)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		split_framework_process(export_files) {
			let ojson = this.framework_record.json
			let parent_ss_framework_data = this.framework_record.ss_framework_data

			U.loading_start('Creating frameworks…')
			let files_to_process = this.cftree.children.length

			// for each top-level item...
			for (let tc_node of this.cftree.children) {
				// get top-child identifier, then get the item JSON from ojson for this identifier
				let tc_identifier = tc_node.cfitem.identifier
				let tc_item = ojson.CFItems.find(x=>x.identifier == tc_identifier)

				// copy document; add title of tc_node; use tc_node as identifier; server will update lastChangeDateTime
				let nf = {}
				nf.CFDocument = $.extend(true, {}, ojson.CFDocument)
				nf.CFDocument.title += ': ' + tc_item.fullStatement
				nf.CFDocument.identifier = tc_identifier

				// create regular expression for replacing old document identifier with new identifier; use it on the CFDocument uri
				let doc_id_re = new RegExp(ojson.CFDocument.identifier, 'gi')
				nf.CFDocument.uri = nf.CFDocument.uri.replace(doc_id_re, tc_identifier)

				// copy in definitions, then add shell for items and associations
				nf.CFDefinitions = $.extend(true, {}, ojson.CFDefinitions)
				nf.CFItems = []
				nf.CFAssociations = []

				// copy all items and associations that are children of this child
				this.$worker.run((ojson, nf, tc_node) => {
					function build_framework(node) {
						let identifier = node.cfitem.identifier

						// skip the document's item (but not associations to the document)
						if (identifier != nf.CFDocument.identifier) {
							// add the item, if it doesn't already exist
							if (nf.CFItems.findIndex(x=>x.identifier == identifier) == -1) {
								nf.CFItems.push(ojson.CFItems.find(x=>x.identifier == identifier))
							}
						}

						// all non-isChildOf associations where the originNodeURI or destinationNodeURI is this identifier
						let assocs = ojson.CFAssociations.filter(o => o.associationType != 'isChildOf' && (o.originNodeURI.identifier == identifier || o.destinationNodeURI.identifier == identifier))
						for (let j = 0; j < assocs.length; ++j) {
							// make sure we only add each assoc once
							if (nf.CFAssociations.findIndex(x=>x.identifier == assocs[j].identifier) == -1) {
								nf.CFAssociations.push(assocs[j])
							}
						}

						// all isChildOf associations where the destinationNodeURI is this identifier -- that is, children of this item
						// (we *don't* do all isChildOf associations where the originNodeURI is this identifier, because if the item appears in multiple locations, we might get extra children)
						assocs = ojson.CFAssociations.filter(o => o.associationType == 'isChildOf' && o.destinationNodeURI.identifier == identifier)
						for (let k = 0; k < assocs.length; ++k) {
							// make sure we only add each assoc once
							if (nf.CFAssociations.findIndex(x=>x.identifier == assocs[k].identifier) == -1) {
								nf.CFAssociations.push(assocs[k])
							}
						}

						// recursively process children
						for (let i = 0; i < node.children.length; ++i) {
							let cn = node.children[i]
							build_framework(cn)
						}
					}
					build_framework(tc_node)

					let s = JSON.stringify(nf)
					console.log('finished ' + nf.CFDocument.title + ' / found document identifier? (should be -1): ' + s.indexOf(ojson.CFDocument.identifier))

					return nf

				}, [ojson, nf, tc_node])	// this is where we pass the original data into the fn
				.then((json)=>{
					// clean the generated json using reduce_case_json
					U.reduce_case_json(this.$worker, json, ['remove_empty_properties', 'remove_CFDocumentURIs']).then((clean_json)=>{
						// reduce_case_json returns an object with json and fn_return_vals; we just need the json
						clean_json = clean_json.json

						// if user held down cmd key when they clicked the command, export files rather than saving them to the server
						if (export_files) {
							this.export_json(clean_json, clean_json.CFDocument.identifier + '.json')
							// update files_to_process, and if we're done stop the loading indicator
							files_to_process -= 1
							if (files_to_process == 0) {
								U.loading_stop()
							}

						} else {
							// set lsdoc_identifier in the json, then save using the same save_framework_data fn that we use for creating new frameworks and saving changes to frameworks
							clean_json.lsdoc_identifier = clean_json.CFDocument.identifier

							// copy some things from parent_ss_framework_data
							if (!clean_json.ss_framework_data) clean_json.ss_framework_data = {}
							clean_json.ss_framework_data.color = parent_ss_framework_data.color
							clean_json.ss_framework_data.image = parent_ss_framework_data.image
							clean_json.ss_framework_data.category = parent_ss_framework_data.category
							clean_json.ss_framework_data.render_latex = parent_ss_framework_data.render_latex
							clean_json.ss_framework_data.exemplar_label = parent_ss_framework_data.exemplar_label

							// NOTE: we are not dealing with updating CFItemTypeURIs

							this.$store.dispatch('save_framework_data', clean_json).then((result)=>{
								// update this.CFDocument's lastChangeDateTime from result
								clean_json.CFDocument.lastChangeDateTime = result.document_lastChangeDateTime

								let cfd_json = new CFDocument(clean_json.CFDocument).to_json()	// it's probably overkill to do these transformation, but it shouldn't hurt

								let index = this.framework_records.findIndex(x=>x.lsdoc_identifier == cfd_json.identifier)
								if (index == -1) {
									let framework_record = U.create_framework_record(cfd_json.identifier, {CFDocument:cfd_json})
									this.$store.commit('set', [this.framework_records, 'PUSH', framework_record])
								} else {
									// if we're "re-importing" an existing framework, create a new framework_record object, copying in old_framework's ss_framework_data; then when we open it we'll reload the new json from the server
									// note that if this is the case we won't have updated the ss_framework_data record in the DB, so we should get these values back when we reload
									let framework_record = U.create_framework_record(cfd_json.identifier, {CFDocument:cfd_json}, $.extend(true, {}, this.framework_records[index].ss_framework_data))
									this.$store.commit('set', [this.framework_records, 'SPLICE', index, framework_record])
								}

								// update files_to_process, and if we're done stop the loading indicator
								files_to_process -= 1
								if (files_to_process == 0) {
									U.loading_stop()
								}
							})
						}
					})
				})
				.catch(()=>{
					U.loading_stop()
					this.$alert('An error occurred when attempting to split the framework.')
					console.error // logs any possible error
				})
			}
		},

		export_framework_json() {
			if (vapp.signed_in_only('export CASE JSON')) return
			this.export_framework('Export Framework as JSON', 'json', 'export_json')
		},

		export_framework(prompt_title, ext, fn) {
			// ask the user what format they want to export
			this.$prompt({
				title: prompt_title,
				text: sr('Select a format for your export:<ul class="mb-2"><li><b>CASE 1.0</b> includes only items and item fields that are part of the official CASE 1.0 specification.</li><li><b>CASE 1.1</b> includes additional item fields that are part of the official CASE 1.1 specification.</li><li><b>Extended CASE</b> may include items and item fields that are editable/viewable in $1, but are not part of the official CASE spec, or are not conformant with the official CASE best practices.</li></ul>', this.$store.state.site_config.app_name),
				promptType: 'select',
				selectOptions: [{value:'v1p0', text: 'CASE 1.0'}, {value:'v1p1', text: 'CASE 1.1'}, {value:'vext', text: 'Extended CASE'}],
				initialValue: 'v1p0',
				acceptText: 'Select Format',
				dialogMaxWidth: 620,
			}).then(format => {
				// let the user choose the filename
				this.$prompt({
					title: prompt_title,
					text: 'Enter a title for the exported file:',
					initialValue: 'CASE-' + this.cfdocument.title + '.' + ext,
					acceptText: 'Export',
					hideCancel: false,	// set to true to hide the cancel button
					dialogMaxWidth: 620,
				}).then(filename => {
					let json = $.extend(true, {}, this.home_framework_record.json)

					// for anything other than vext, strip supplemental branches
					// KEEP THIS CODE IN SYNCH WITH update_framework_elements.php
					if (format != 'vext') {
						let remove_item_and_assocs = (i)=>{
							let identifier = json.CFItems[i]['identifier'];
							// remove *all* associations (not just isChildOf) with this item on either side
							for (let j = 0; j < json.CFAssociations.length; ++j) {
								let assoc = json.CFAssociations[j]
								if (assoc.originNodeURI.identifier == identifier || assoc.destinationNodeURI.identifier == identifier) {
									json.CFAssociations.splice(j, 1)
									--j;	// decrement j so the loop stays in synch
								}
							}
							
							// now splice the item from CFItems
							json.CFItems.splice(i, 1)
						}
						
						let removed_item_count = 0;
						// first remove supplemental items
						for (let i = 0; i < json.CFItems.length; ++i) {
							if (json['CFItems'][i]['extensions'] && json['CFItems'][i]['extensions']['isSupplementalItem'] == true) {
								console.log('removing supplemental item: ' + json['CFItems'][i].fullStatement)
								remove_item_and_assocs(i);
								--i;	// decrement i so the loop stays in synch
								++removed_item_count;
							}
						}

						let x = 0
						// then if we removed any supplementals, remove orphaned associations and items
						if (removed_item_count > 0) do {
							removed_item_count = 0;
							for (let i = 0; i < json.CFItems.length; ++i) {
								let identifier = json.CFItems[i]['identifier'];
								let found_parent = false;
								// go through all associations...
								for (let j = 0; j < json.CFAssociations.length; ++j) {
									// if we find an isChildOf where this item is the child (origin)
									if (json.CFAssociations[j]['associationType'] == 'isChildOf' && json.CFAssociations[j]['originNodeURI']['identifier'] == identifier) {
										// if the parent (still) exists in CFItems, break out of this inner loop and leave the child there
										if (json['CFDocument']['identifier'] == identifier) found_parent = true
										if (!found_parent) for (let k = 0; k < json.CFItems.length; ++k) {
											if (json['CFItems'][k]['identifier'] == identifier) { found_parent = true; break; }
										}
										if (found_parent) break
									}
								}

								// if we didn't find an existing parent for this item, delete it and all its associations
								if (!found_parent) {
									console.log('removing no-parent item: ' + json['CFItems'][i].fullStatement)
									remove_item_and_assocs(i);
									--i;	// decrement i so the loop stays in synch
									++removed_item_count;
								}
							}
							console.log(`removed_item_count (${x}): ${removed_item_count}`)
							++x
						} while (removed_item_count > 0 && x < 50);
					}
					
					// for v1p0, strip extended CASE values
					if (format == 'v1p0') {
						for (let field in json.CFDocument) {
							if (CFDocument.fields.case_1_1_fields[field] || field == 'extensions') {
								delete json.CFDocument[field]
							}
						}
						for (let item of json.CFItems) {
							for (let field in item) {
								if (CFItem.fields.case_1_1_fields[field] || field == 'extensions') {
									delete item[field]
								}
							}
						}
						for (let assoc of json.CFAssociations) {
							for (let field in assoc) {
								if (CFAssociation.fields.case_1_1_fields[field] || field == 'extensions') {
									delete assoc[field]
								}
							}
						}

					// and for other than vext or v1p0, delete extensions.supplementalNotes (v1p0 would already have stripped extensions)
					} else if (format != 'vext') {
						for (let item of json.CFItems) {
							if (item.extensions?.supplementalNotes) {
								delete item.extensions.supplementalNotes
								// if no other extensions there, delete extensions too
								if (!U.object_has_keys(item.extensions)) {
									delete item.extensions
								}
							}
						}
					}

					// call the passed-in fn
					this[fn](json, filename)

				}).catch(n=>{console.log(n)}).finally(f=>{})
			}).catch(n=>{console.log(n)}).finally(f=>{})
			this.kebab_menu_showing = false
		},

		export_json(json, filename) {
			// slightly hacky solution from here: https://stackoverflow.com/questions/19721439/download-json-object-as-a-file-from-browser
			var dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(json))
			var downloadAnchorNode = document.createElement('a')
			downloadAnchorNode.setAttribute('href', dataStr)
			downloadAnchorNode.setAttribute('download', filename)
			document.body.appendChild(downloadAnchorNode) // required for firefox
			downloadAnchorNode.click()
			downloadAnchorNode.remove()
		},

		export_ap_word() {
			let html = `<h1 style="margin-bottom:16px">${this.framework_record.cfo.cftree.cfitem.title}</h1>`

			let last_level = -1
			let process_children = (node, level) => {
				let cfitem = node.cfitem
				
				let style = `margin-left:${level*24}px; `
				if (last_level != level) style += `border-top:1px solid #ccc; padding-top:16px;`

				let line = `<div style="${style}">`

				let s = ''
				if (cfitem.humanCodingScheme) s += `**${cfitem.humanCodingScheme}**  `
				s += cfitem.fullStatement

				if (level == 0) line += `<h3 style="margin-bottom:16px">${s}</h3>`
				else line += marked(s)

				line += `</div>`

				html += line

				last_level = level

				for (let child_node of node.children) {
					process_children(child_node, level+1)
				}
			}

			// for each top-level item...
			for (let top_node of this.framework_record.cfo.cftree.children) {
				if (top_node.cfitem.fullStatement != 'Additional Metadata') {
					process_children(top_node, 0)
				}
			}

			html = `<!DOCTYPE html><html><head></head><body><div style="font-size:14px; line-height:18px;">\n${html}\n</div></body></html>`

			this.$confirm({
				// title: 'AP Export for Word',
				text: html,
				acceptText: 'Done',
				acceptIcon: 'fas fa-check',
				cancelText: 'Save HTML',
				cancelIcon: 'fas fa-save',
				cancelColor: 'green darken-4',
				// set rejectCloseOnClick to false so clicking the cancel ("Copy to Clipboard") button *doesn't* close the dialog
				rejectCloseOnClick: false,
				fullscreen: true,
				dialogMaxWidth: '95vw',
			}).then(y => {

			}).catch(n=>{
				var dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(html)
				var downloadAnchorNode = document.createElement('a')
				let filename = $.trim(this.framework_record.cfo.cftree.cfitem.title) + '.html'
				downloadAnchorNode.setAttribute('href', dataStr)
				downloadAnchorNode.setAttribute('download', filename)
				document.body.appendChild(downloadAnchorNode) // required for firefox
				downloadAnchorNode.click()
				downloadAnchorNode.remove()

			}).finally(f=>{})

		},

		export_ap_spreadsheet() {
			// retrieve all-course metadata framework if we don't already have it
			let acm_framework_record = this.framework_records.find(x=>x.lsdoc_identifier==this.$store.state.all_course_metadata_framework_identifier)
			if (acm_framework_record.framework_json_loaded) {
				this.export_ap_spreadsheet_finish(acm_framework_record)
			} else {
				U.loading_start('Loading metadata…')
				this.$store.dispatch('get_lsdoc', this.$store.state.all_course_metadata_framework_identifier).then(()=>{
					U.loading_stop()

					// then build the cfo for the framework
					U.build_cfo(this.$worker, acm_framework_record.json).then((cfo)=>{
						this.$store.commit('set', [acm_framework_record, 'cfo', cfo])
						this.$store.commit('set', [acm_framework_record, 'framework_json_loading', false])
						U.loading_stop()

						this.export_ap_spreadsheet_finish(acm_framework_record)
					})
					.catch((e)=>{
						this.$store.commit('set', [acm_framework_record, 'framework_json_load_failed', true])
						U.loading_stop()
						console.log(e)
						this.$alert('Could not load the all-course metadata')
					})

				}).catch((e)=>{
					console.log(e)
					U.loading_stop()
					this.$alert('Could not load the all-course metadata')
				})
			}
		},

		export_ap_spreadsheet_finish(acm_framework_record) {
			// currently it doesn't really matter if we've extracted the vext fields for this format
			let all_lines = ['Category']	// placeholder for 'Category...' line
			let lines = []
			let max_level
			let max_max_level = 0
			let flat_lines = []
			let flat_columns = []
			let content_item_types = []
			let header_row_indexes = [0]

			// get renested item types
			let renested_item_types = {}
			for (let identifier in this.framework_record.cfo.cfitems) {
				let nitem = this.framework_record.cfo.cfitems[identifier]
				if (U.item_type_string(nitem) == 'Metadata Category') {
					let nit = nitem.extensions?.nestForAPSpreadsheetExport
					if (nit) {
						renested_item_types[nitem.fullStatement] = nit
					}
				}
			}

			let item_line = function(cfitem) {
				let line = ''
				// if the line has a hcs, add it
				if (cfitem.humanCodingScheme) {
					// strip out initial words/abbreviations if there? not doing this for now
					// line += cfitem.humanCodingScheme.replace(/^.* /, '') + ': '
					line += cfitem.humanCodingScheme + ': '
				}

				// add the fullStatement
				line += cfitem.fullStatement

				// for all item types other than 'Learning Object' and 'Essential Knowledge' (this list might grow), strip all markdown and dollar sign delimiters for latex
				if (!['Learning Objective', 'Essential Knowledge'].includes(cfitem.CFItemType)) {
					line = `[${line}]`
					line = line.replace(/([\s({\[>])\$(\S([^$]*?\S)?)\$([\s)}\].,;:%!?<])/g, ($0, $1, $2, $3, $4) => {
						return `${$1}${$2}${$4}`
					})
					line = line.replace(/^\[([\s\S]*)\]$/, '$1')

					// bulletted lists -- convert to bullet character? (hopefully there won't be many of these)
					line = line.replace(/\n\s*[*-]\s+(.*)/g, '• $1')

					// bold/italics/both
					line = line.replace(/\*\*\*(\S.*?)\*\*\*/g, '$1')
					line = line.replace(/\*\*(\S.*?)\*\*/g, '$1')
					line = line.replace(/\*(\S.*?)\*/g, '$1')

					// replace newlines and tabs with spaces
					line = line.replace(/\s/g, ' ')
					line = line.replace(/\s+/g, ' ')

				// else for the item types listed above...
				} else {
					// convert lower-case roman numerals to lists -- from extensions of marked in utilities.js (requested 9/22/2023)
					line = line.replace(/\n([ivx]+)\.\s(.*)/g, ($0, $1, $2) => {
						let start = ['', 'i', 'ii', 'iii', 'iv', 'v', 'vi', 'vii', 'viii', 'ix', 'x', 'xi', 'xii', 'xiii', 'xiv', 'xv', 'xvi', 'xvii', 'xviii', 'xix', 'xx', 'xi', 'xii', 'xiii', 'xiv', 'xv'].indexOf($1)
						return `<ol type="i" start="${start}"><li>${$2}</li></ol>`
						// AP requested this to use <ul style="list-style-type: lower-roman;"> as the outer container, but that causes problems...
						// return `<ul style="list-style-type: lower-roman;" start="${start}"><li>${$2}</li></ul>`
					})

					// also convert bulleted lists to HTML
					while (line.search(/\n\s*[*-]\s+([\s\S]+?)((<ul>)|(\n\n)|(\n\s*[*-])|$)/) > -1) {
						// console.log(RegExp.$1, RegExp.$2)
						line = line.replace(/\n\s*[*-]\s+([\s\S]+?)((<ul>)|(\n\n)|(\n\s*[*-])|$)/, '<ul><li>$1</li></ul>$2')
					}
					// while (line.search(/\n\s*\*\s+([\s\S]+?)((\n\n)|$)/) > -1) {
					// 	console.log(RegExp.$1, RegExp.$2)
					// 	line = line.replace(/\n\s*\*\s+([\s\S]+?)((\n\n)|$)/, '<ul><li>$1</li></ul>\n\n')
					// }
					// line = line.replace(/\n\s*\*\s+([\s\S]+?)((\n\n)|$)/g, '<ul><li>$1</li></ul>\n\n')

					// now, after doing the above transform, change double-line-breaks to <br>'s, and line breaks to spaces
					line = line.replace(/\n\n/g, '<br>')
					line = line.replace(/\n/g, ' ')

					// 1.1.A.3: Distance and speed are examples of scalar quantities, while position, displacement, velocity, and acceleration are examples of vector quantities. i. Vectors are notated with an arrow above the symbol for that quantity. Relevant equation: \(\vec{v}={{\vec{v}}_{0}}+\vec{a}t\) ii. Vector notation is not required for vector components along an axis. In one dimension, the sign of the component completely describes the direction of that component. Derived equation: \({{v}_{x}}={{v}_{x0}}+{{a}_{x}}t\)	

					// Convert back ticks to LaTeX -- \texttt -- or (hopefully this works too) <code>tags</code>
					line = line.replace(/`(\S.*?)`/g, ($0, code) => {
						// AP wants latex within backticks blocks to be *outside* the code blocks...
						// we're going to hope/assume that we don't have "raw" $'s inside code blocks (that is, we assume that every $ is a latex marker)
						let s = ''
						let chunks = code.split('$')
						// `a $\leftarrow$ 1` => ['a ', '$\leftarrow$', ' 1']
						for (let i = 0; i < chunks.length; ++i) {
							// skip empties; that could happen if there's a latex at the start or end of the code block
							if (empty(chunks[i])) continue

							// even chunks are text that we need in code blocks; odd chunks are latex
							if (i % 2 == 0) s += `<code>${chunks[i]}</code>`
							else s += `$${chunks[i]}$`
						}
						return s
					})

					// 8/18/2023: convert LaTeX delimiters from $xxx$ to \(xxx\) -- see U.render_latex for explanation of regex
					line = `[${line}]`
					line = line.replace(/([\s({\[>])\$(\S([^$]*?\S)?)\$([\s)}\].,;:%!?<])/g, ($0, $1, $2, $3, $4) => {
						let s = `\\(${$2}\\)`

						// also insert clearspeak span as alt text
						s = U.clearspeak_span($2, s)

						return `${$1}${s}${$4}`
					})
					line = line.replace(/^\[([\s\S]*)\]$/, '$1')

					// convert italics and bolds from markdown to LaTeX
					// TODO: we could have a version that doesn't do this conversion...
					// latex: $\textbf{bold}$ and $\textit{italics}$ and $\textbf{\textit{both}}$ here
					line = line.replace(/\*\*\*(\S.*?)\*\*\*/g, '\\(\\textbf{\\textit{$1}}\\)')
					line = line.replace(/\*\*(\S.*?)\*\*/g, '\\(\\textbf{$1}\\)')
					line = line.replace(/\*(\S.*?)\*/g, '\\(\\textit{$1}\\)')
				}

				return line
			}

			let last_line_for_level = []	// see below

			let process_children = (node, level, flag) => {
				let cfitem = node.cfitem
				let item_type = U.item_type_string(cfitem)

				// if flag isn't 'processing_nested_children' and the item type is one of the renested_item_types, skip it
				if (flag != 'processing_nested_children' && !empty(renested_item_types[item_type])) {
					console.log('skipping item type ' + item_type)
					return
				}

				// start the line with the appropriate number of tabs
				let line = ''
				for (let i = 0; i < level; ++i) line += '\t'

				line += item_line(cfitem)

				// push to flat_lines, removing one leading tab
				flat_lines.push(line.replace(/^\t/, ''))

				// save list of content_item_types, in the order we encounter them in the tree
				if (item_type && !content_item_types.includes(item_type)) content_item_types.push(item_type)

				// if we've reached a new level...
				if (level > max_level) {
					// increase max_level and add this item's type to the types line for this category
					// BUT NOT if the item's type is one of the types we've identified as not being included in the "subject" part of the spreadsheet
					if (!excluded_subject_item_types.includes(item_type)) {
						max_level = level
						lines[0] += '\t' + U.item_type_string(cfitem)
						if (max_level > max_max_level) {
							all_lines[0] += '\t' + 'Level ' + max_level
							max_max_level = max_level
						}
					}
				}

				// push to lines
				// BUT NOT if the item's type is one of the types we've identified as not being included in the "subject" part of the spreadsheet
				// 		-- we will add these as flat fields only below
				if (!excluded_subject_item_types.includes(item_type)) {
					// also don't push lines with empty item_type values, except for level 0 (e.g. "Skill Category")
					if (item_type || level == 0) {
						// and don't push the same line for the same level twice in a row -- see CoGoPo PAU-1.D
						if (line == last_line_for_level[level]) {
							console.log(`skipping duplicate line for level ${level}: ${line}`)
						} else {
							lines.push(line)
							last_line_for_level[level] = line
						}
					}
				}

				// process children
				for (let child_node of node.children) {
					process_children(child_node, level+1, flag)
				}

				// if this item's type is one of the targets of a renested_item_types type, 
				if (flag != 'processing_nested_children' && node.cfitem.humanCodingScheme) for (let child_type in renested_item_types) {
					if (renested_item_types[child_type] = item_type) {
						// then go through all items and find items of child_type whose hcs match this item's code; add those items, and their children, to the spreadsheet here
						let re = new RegExp(`^${node.cfitem.humanCodingScheme}\\.[^\.]+$`)
						let arr = []
						for (let identifier in this.framework_record.cfo.cfitems) {
							let nitem = this.framework_record.cfo.cfitems[identifier]
							if (U.item_type_string(nitem) == child_type) {
								if (nitem.humanCodingScheme && nitem.humanCodingScheme.search(re) == 0) {
									// console.log(`found child of ${item_type} ${node.cfitem.humanCodingScheme}: ${nitem.humanCodingScheme}`)
									arr.push(nitem.tree_nodes[0])
								}
							}
						}

						// sort the found nodes by the code
						arr.sort((a,b) => U.natural_sort(a.cfitem.humanCodingScheme, b.cfitem.humanCodingScheme))

						// then process each node (and its children)
						for (let node of arr) {
							process_children(node, level+1, 'processing_nested_children')
						}
					}
				}
			}

			let process_metadata_node = (node) => {
				let flat_column_index = flat_columns.length
				// start this flat_column with the item's fullStatement
				flat_columns[flat_column_index] = [node.cfitem.fullStatement]
				// now for each child...
				for (let child_node of node.children) {
					// add the child's fullStatement to this flat_column
					flat_columns[flat_column_index].push(child_node.cfitem.fullStatement)
					if (child_node.children.length > 0) {
						// if the child has children, we create another flat column for the child and its children
						process_metadata_node(child_node)
					} else if (U.item_type_string(child_node.cfitem) == 'Metadata Category') {
						// else if the child is itself a MetadataCategory item, and its fullStatement matches an item type, that means it represents an item type, and gets its own flat column.
						if (this.framework_record.cfo.item_types.includes(child_node.cfitem.fullStatement)) {
							flat_columns[flat_columns.length] = [child_node.cfitem.fullStatement]
						}
					}
				}
			}

			// get list of item types that we want to exclude from the "subject" (tree-formatted) part of the spreadsheet...
			let excluded_subject_item_types = []
			let additional_metadata_parent_node = this.framework_record.cfo.cftree.children.find(x=>x.cfitem.fullStatement == 'Additional Metadata')
			if (additional_metadata_parent_node) {
				for (let top_am_node of additional_metadata_parent_node.children) {
					// ... top-level "Metadata Category" items that *don't* have children
					let item_type = U.item_type_string(top_am_node.cfitem)
					if (item_type == 'Metadata Category') {
						if (top_am_node.children.length == 0) {
							excluded_subject_item_types.push(top_am_node.cfitem.fullStatement)
						}
					}
				}
			}
			console.log('excluded_subject_item_types:', excluded_subject_item_types)

			// for each top-level item...
			for (let top_node of this.framework_record.cfo.cftree.children) {
				if (top_node.cfitem.fullStatement != 'Additional Metadata') {
					max_level = 0
					lines = ['']	// placeholder for this category's types line

					process_children(top_node, 0, '')
					
					// only put the top-level item in the 'top' section of the spreadsheet if it has more than one level
					if (max_level > 1) {
						header_row_indexes.push(all_lines.length)
						all_lines = all_lines.concat(lines)
						all_lines.push('')	// blank line between categories
					}

				// when we get to the Additional Metadata item, process its children as flat lines only
				} else {
					for (let child_node of top_node.children) {
						process_metadata_node(child_node)
					}

					// then process the top-level children of the all-course metadata file
					// BUT NOT if the "Additional Metadata" node has the string "no_all_course_metadata" in the notes field
					if (empty(top_node.cfitem.notes) || !top_node.cfitem.notes.includes('no_all_course_metadata')) {
						for (let child_node of acm_framework_record.cfo.cftree.children) {
							process_metadata_node(child_node)
						}
					}
				}
			}

			let process_flat_item_type_column = (col, item_type, node) => {
				if (node.cfitem && U.item_type_string(node.cfitem) == item_type) col.push(item_line(node.cfitem))
				for (let child of node.children) process_flat_item_type_column(col, item_type, child)
			}

			// add a "flat" column for the values for each content_item_type that is specified in the metadata area
			let type_warnings = []
			for (let i = content_item_types.length-1; i >= 0; --i) {
				let item_type = content_item_types[i]
				let index = flat_columns.findIndex(x=>x[0] == item_type)
				if (index == -1) {
					type_warnings.push(`Not processing ${item_type} items because the type isn't specified  in the medatadata area`)
					continue
				}

				let arr = [item_type]
				process_flat_item_type_column(arr, item_type, this.framework_record.cfo.cftree)

				if (arr.length == 0) {
					type_warnings.push(`No items found for item type ${item_type}`)
				} else {
					flat_columns[index] = arr
				}
			}

			for (let fc of flat_columns) {
				if (fc.length == 1) type_warnings.push(`No values found for metadata category ${fc[0]}`)
			}

			// collapse flat_columns into rows and add to the bottom
			if (flat_columns.length > 0) {
				all_lines.push('')
				all_lines.push('FLAT METADATA')
				all_lines.push('')
				header_row_indexes.push(all_lines.length)
				for (let i = 0; i < 10000; ++i) {
					let line = ''
					let found_value = false
					for (let fc of flat_columns) {
						if (fc[i]) {
							line += fc[i]
							found_value = true
						}
						line += '\t'
					}
					if (!found_value) break
					all_lines.push(line)
				}
			}

			let t = ''
			if (type_warnings.length > 0) {
				t += `<div class="red--text text--darken-3 mb-3"><b>WARNINGS:</b><ul><li>${type_warnings.join('</li><li>')}</li></ul></div>`
			}

			t += '<div class="mb-2"><a href="#k_flat_metadata"><b>Jump to Flat Metadata</b></a></div>'

			t += '<table class="k-ap-spreadsheet-export">'
			for (let j = 0; j < all_lines.length; ++j) {
				let tag = header_row_indexes.includes(j) ? 'th' : 'td'
				let arr = all_lines[j].split(/\t/)
				if (arr[0] == 'FLAT METADATA') t += `<tr id="k_flat_metadata">`
				else t += `<tr>`
				for (let i = 0; i < flat_columns.length; ++i) {
					t += `<${tag}>${arr[i]??''}</${tag}>`
				}
				t += `</tr>`
			}
			t += '</table>'

			this.$confirm({
				title: 'AP-Formatted Spreadsheet Data',
				text: t,
				// text: '<pre style="font-size:12px; line-height:15px;">' + all_lines.join('\n') + '</pre>',
				acceptText: 'Done',
				acceptIcon: 'fas fa-check',
				cancelText: 'Copy to Clipboard',
				cancelIcon: 'fas fa-clipboard',
				cancelColor: 'green darken-4',
				// set rejectCloseOnClick to false so clicking the cancel ("Copy to Clipboard") button *doesn't* close the dialog
				rejectCloseOnClick: false,
				fullscreen: true,
				dialogMaxWidth: '95vw',
			}).then(y => {

			}).catch(n=>{
				U.copy_to_clipboard(all_lines.join('\n'))
				this.$inform('Spreadsheet data copied to clipboard')
			}).finally(f=>{})
		},

		show_framework_json() {
			if (vapp.signed_in_only('show the entire framework’s CASE JSON')) return

			let html = '<textarea style="height: calc(100vh - 220px); width: 1000px; font-size: 12px; line-height: 14px; font-family: monospace;">' + JSON.stringify(this.framework_record.json, null, 4)
			this.$alert({title: 'Framework JSON', text:html, dialogMaxWidth: 1050})
		},

		reduce_file_size() {
			this.show_reduce_file_size_dialog = true
		},

		clean_line_breaks() {
			this.$confirm({
			    title: 'Clean Line Breaks',
			    text: 'This process removes extraneous line breaks from item fullStatements and notes (while preserving markup formatting). Line breaks can be introduced when copying and pasting from PDFs to create items. Click “CHECK FOR LINE BREAKS” to run the process and see how many, if any, items would be affected by the process. You can then decide if you want to proceed',
			    acceptText: 'CHECK FOR LINE BREAKS',
			}).then(y => {
				let updated_items = []
				let updates = ''
				for (let item of this.framework_record.json.CFItems) {
					item = $.extend(true, {}, item)
					let original = JSON.stringify(item)
					item.fullStatement = U.remove_line_breaks_from_text_field(item.fullStatement)
					item.notes = U.remove_line_breaks_from_text_field(item.notes)
					if (JSON.stringify(item) != original) {
						// set lastChangeDateTime = '*NOW*' so that server will generate a new date
						item.lastChangeDateTime = '*NOW*'
						updated_items.push(item)
						updates += sr('<li>$1 ($2)</li>', U.generate_cfassociation_node_uri_title(item, true), item.identifier)
					}
				}
				updates = sr('<ul class="mt-2">$1</ul>', updates)

				if (updated_items.length == 0) {
					this.$alert({title: 'Clean Line Breaks', text:'No items need cleaning!'})
					return
				}

				this.$confirm({
				    title: 'Clean Line Breaks',
				    text: updated_items.length + ' item(s) have line breaks that can be removed from fullStatements and/or notes (listed below). Click “REMOVE LINE BREAKS” to remove them and save the framework.' + updates,
				    acceptText: 'REMOVE LINE BREAKS',
					dialogMaxWidth: 800,
				}).then(y => {
					let data = {
						lsdoc_identifier: this.lsdoc_identifier,
						CFItems: updated_items
					}

					// save the updated items
					U.loading_start()
					this.$store.dispatch('save_framework_data', data).then(()=>{
						// rather than messing around with trying to preserve things in the data structure, just reload
						this.$alert({title: 'Clean Line Breaks', text:'Done! Please reload the framework now.'}).then(y=>window.location.reload())

					}).catch(n=>{console.log(n)}).finally(f=>{U.loading_stop()})
				}).catch(n=>{console.log(n)}).finally(f=>{})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		process_duplicate_items() {
			if (!this.stats_mode) {
				this.$alert('You must run the “Calculate/show framework statistics” command before running this command.')
				return
			}

			this.$confirm({
			    title: 'Process Duplicates',
			    text: 'This experimental process does advanced JSON cleaning by removing duplicates and some other things. <b>Running this process will not (currently) update the framework’s JSON file on the server.</b> Rather, it will download a copy of the processed JSON to your local computer.',
			    acceptText: 'Do It!',
			}).then(y => {
				U.process_duplicates(this.$worker, this.framework_record.json, this.cfo.cfitems, U.case_current_time_string()).then((nf)=>{
					U.loading_stop()
					// Done!
					console.log('done!', nf)

					// TODO: save the file instead of exporting
					// export the file
					this.export_json(nf, 'CASE-DUPLICATES-REMOVED-' + nf.CFDocument.title)

					// set the json to the new version, re-build the cfo, and cancel stats_mode
					this.$store.commit('set', [this.framework_record, 'json', nf])
					this.build_cfo()
					this.stats_mode = false
				})
				.catch((e)=>{
					U.loading_stop()
					console.log(e)
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		re_import_framework() {
			this.$prompt({
				title: 'Re-Import Framework',
				text: 'Choose a CASE JSON file from your computer as the imported framework source.',
				promptType: 'file',		// default is 'text'
				acceptText: 'Import',
			}).then(file => {
				if (empty(file) || empty(file.name)) return
				// we receive a file from dialog-promise-pwet here; create a FileReader
				let reader = new FileReader()
				reader.onload = e => {
					// NOTE: keep this in synch with import_framework from FrameworkList.vue
					// currently we only support CASE JSON imports

					// parse json (it will be stringified by save_framework_data)
					let json
					try {
						json = JSON.parse(e.target.result)
					} catch(e) {
						console.log(e)
						this.$alert('The uploaded file was not valid JSON (an error occurred when attempting to parse the file).')
						return
					}

					// if no CFDocument, it's not valid json
					// TODO: do more validation...
					if (!json.CFDocument) {
						this.$alert('The uploaded JSON did not include a CFDocument property, so it is not a valid CASE framework file.')
						return
					}

					// if the uploaded document doesn't match this document's identifier, error
					if (json.CFDocument.identifier != this.CFDocument.identifier) {
						this.$alert('The uploaded JSON’s document identifier does not match this document’s identifier.')
						return
					}

					this.$confirm({
					    title: 'Overwrite Existing Framework',
					    text: sr('Are you sure you want to overwrite this framework’s CASE JSON with the newly-imported JSON?'),
					    acceptText: 'Re-Import CASE JSON',
						dialogMaxWidth: 600
					}).then(y => {
						// call complete_import_framework from FrameworkList
						vapp.framework_list_component.complete_import_framework(json)
					}).catch(n=>{console.log(n)}).finally(f=>{})
				}
				// trigger the FileReader to load the text of the file
				reader.readAsText(file)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		// A sandbox is a copy of framework with new lsdoc identifier and private adoption status
		create_sandbox() {
			let payload = {
				'user_id': this.user_info.user_id,
				'framework_identifier': this.cfdocument.identifier,
				'sandbox_identifier': U.new_uuid()
			}

			// returns result.sandbox_json
			// TODO we may only need CFDocument and sandbox meta data here, if so don't send whole frameowrk back to client here
			U.loading_start()
			U.ajax('save_sandbox', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log(sr('Error in save_sandbox ajax call: $1', result.status));
					return
				}
				console.log(sr('Sandbox $1 created for framework $2', result.sandbox_json.CFDocument.identifier, this.cfdocument.identifier));

				let cfd_json = new CFDocument(result.sandbox_json.CFDocument).to_json()

				// this is a new framework created by sandbox_create service
				// we do want to copy in the ss_framework_data from source framework and add sandboxOfIdentifier
				let sandbox_framework_record = U.create_framework_record(cfd_json.identifier, {CFDocument:cfd_json}, $.extend(true, {}, this.framework_record.ss_framework_data))
				sandbox_framework_record.ss_framework_data.sandboxOfIdentifier = this.cfdocument.identifier
				// add the sandboxSyncDateTime too, it's the lastChangeDateTime
				sandbox_framework_record.ss_framework_data.sandboxSyncDateTime = this.cfdocument.lastChangeDateTime

				// add sandbox to framework_records
				this.$store.commit('set', [this.framework_records, 'PUSH', sandbox_framework_record])

				// open viewer into sandbox if user chooses to do so
				this.$confirm({
					text: 'Sandbox created. Would you like to open the sandbox now?',
					acceptText: 'Open Sandbox',
					cancelText: 'Remain in Original',
					dialogMaxWidth: 520
				}).then(y => {
					setTimeout(()=>vapp.framework_list_component.view_framework(cfd_json.identifier), 100)
					this.hide_tree()

				}).catch(n=>{console.log(n)}).finally(f=>{})
			})
		},

		// apply sandbox changes to original framework and then delete the sandbox
		// see ApplySandbox.vue component
		apply_sandbox() {
			this.show_apply_sandbox = true
		},

		open_original_of_sandbox() {
			let source_lsdoc_identifier = this.framework_record.ss_framework_data.sandboxOfIdentifier
			setTimeout(()=>vapp.framework_list_component.view_framework(source_lsdoc_identifier), 100)
			this.hide_tree()
		},

		open_original_of_derivative() {
			let source_lsdoc_identifier = this.cfdocument.extensions.sourceFrameworkIdentifier
			setTimeout(()=>vapp.framework_list_component.view_framework(source_lsdoc_identifier), 100)
			this.hide_tree()
		},

		// A derivative is a copy of framework with a new document and associations, but aliases of all items from the source
		create_derivative() {
			let payload = {
				'user_id': this.user_info.user_id,
				'framework_identifier': this.cfdocument.identifier,
				'derivative_identifier': U.new_uuid()
			}

			// returns result.derivative_fr
			U.loading_start()
			U.ajax('save_derivative', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log(sr('Error in save_derivative ajax call: $1', result.status));
					return
				}
				console.log(sr('Derivative $1 created for framework $2', payload.derivative_identifier, this.cfdocument.identifier))
				console.log(result)

				// store returned framework record
				let json = JSON.parse(result.derivative_fr.json)
				let doc = new CFDocument(json.case_document_json)
				delete(json.case_document_json)	// once we delete this, we'll be left with the ss_framework_data
				let derivative_framework_record = U.create_framework_record(doc.identifier, {CFDocument: doc}, json, false)
				this.$store.commit('set', [this.$store.state.framework_records, 'PUSH', derivative_framework_record])

				// open viewer into derivative if user chooses to do so
				this.$confirm({
					text: 'Derivative created. Would you like to open the derivative now?',
					acceptText: 'Open Derivative',
					cancelText: 'Remain in Original',
					dialogMaxWidth: 520
				}).then(y => {
					setTimeout(()=>vapp.framework_list_component.view_framework(doc.identifier), 100)
					this.hide_tree()

				}).catch(n=>{console.log(n)}).finally(f=>{})
			})
		},

		copy_shortcut_url() {
			let url = window.location.origin + '/'	// e.g. `https://case.georgiastandards.org/`
			if (this.framework_record.ss_framework_data.shortcuts.length == 0) {
				url += this.lsdoc_identifier
			} else {
				url += this.framework_record.ss_framework_data.shortcuts[0]
			}

			U.copy_to_clipboard(url)
			this.$inform('Link copied to clipboard')
			this.kebab_menu_showing = false
		},

		copy_api_url() {
			if (vapp.signed_in_only('copy CASE API links')) return

			// always call sync_case_api before showing the copy API link page
			U.loading_start()
			U.ajax('sync_case_api', {framework_identifiers: [this.framework_record.lsdoc_identifier]}, result=>{
				U.loading_stop()
				this.$prompt({
					title: 'Copy CASE package API link',
					text: sr('Select a format for the API link:<ul class="mb-2"><li>The <b>CASE 1.0</b> API will include only fields that are part of the official CASE 1.0 specification.</li><li><b>CASE 1.1</b> includes additional fields that are part of the official CASE 1.1 specification.</li><li>The <b>Extended CASE</b> API will include additional fields that are part of the official CASE 1.1 specification, plus some fields that are editable/viewable in $1, but are not part of the official CASE spec.</li></ul>', this.$store.state.site_config.app_name),
					promptType: 'select',
					selectOptions: [{value:'v1p0', text: 'CASE 1.0'}, {value:'v1p1', text: 'CASE 1.1'}, {value:'vext', text: 'Extended CASE'}],
					initialValue: 'v1p0',
					acceptText: 'Select Format',
					dialogMaxWidth: 620,
				}).then(format => {
					let url = U.case_api_url(this.lsdoc_identifier, null, format)
					U.copy_to_clipboard(url)
					this.$inform('Link copied to clipboard')
				}).catch(n=>{console.log(n)}).finally(f=>{})
			})
			this.kebab_menu_showing = false
		},

		//////////////////////////////////////////////
		// Archives functionality
		set_track_changes_fields(key) {
			this.track_changes_fields[key] = !this.track_changes_fields[key]
			this.$store.commit('lst_set', ['track_changes_fields', this.track_changes_fields]) 
		},

		copy_archive_link() {
			let url = window.location.toString() + '?'
			if (this.tracking_changes) {
				url += 'track_changes=' + this.track_changes_fn
				url += `&track_changes_fields=${JSON.stringify(this.track_changes_fields)}`
				if (this.side_by_side_editor_head_identifier) {
					url += '&sbshi=' + this.side_by_side_editor_head_identifier
					this.$inform('Side-By-Side Comparison link copied to clipboard')
				} else {
					this.$inform('Track changes link copied to clipboard')
				}
			} else {
				// we currently don't show the link btn when you're just viewing an archive, but we could do so in the future
				url += 'view_archive=' + this.view_archive_fn
				this.$inform('Archive link copied to clipboard')
			}
			U.copy_to_clipboard(url)
		},

		view_archive(archive_filename, archive_date, archive_note, item_identifier) {
			// make sure we're not tracking changes when we're viewing an archive
			this.cancel_track_changes()

			this.archive_item_identifier_to_show = item_identifier ? item_identifier: ''
			this.view_archive_fn = archive_filename

			if (archive_filename == 'current') {
				// if archive_filename is 'current', we're viewing an item from the current framework, but in the context of the archive comparison dialog, so we want the bottom panel to show
				archive_filename = ''
				this.view_archive_ctl_text = 'Viewing item in current framework'
			} else {
				// set reactive values noting what we're showing
				this.view_archive_ctl_text = 'Viewing archive'
				if (archive_date && archive_note) this.view_archive_ctl_text += ` from <b>${archive_date}</b>: “${archive_note}”`

				// note if we need to re-enable editing when we return to the current framework, then turn editing_enabled off because we don't want you editing while you're viewing the archived framework
				this.editing_prior_to_viewing_archive = this.editing_enabled
				this.editing_enabled = false
			}

			// call refresh_lsdoc sending the archive_filename to load the archived json
			this.refresh_lsdoc(archive_filename, x=>{
				setTimeout(x=>this.show_item(item_identifier), 100)
			})
		},

		track_changes(archive_filename, archive_date, archive_note, item_identifier) {
			// set reactive values noting what we're showing; this also stores these values in lst
			this.archive_date = archive_date ?? ''
			this.archive_note = archive_note ?? ''
			this.track_changes_fn = archive_filename

			this.view_archive_ctl_text = archive_date ? sr('Tracking changes from <b>$1</b>: “$2”', archive_date, archive_note) : 'Tracking changes'

			// load the archive's file (if we haven't already), then continue
			if (this.$store.state.archive_json[this.track_changes_fn]) {
				this.track_changes_post_file_load(item_identifier)
			} else {
				let filepath = sr('framework_archives/$1', this.track_changes_fn)
				U.get_json_file(filepath, archive_json=>{
					if (typeof(json) == 'string') {
						console.log('Error in get_json_file', archive_json)
						return
					}

					if (archive_json.CFDocument.identifier !== this.lsdoc_identifier) {
						// discovered on 10-13-2023 that this can happen with a "Sandbox X changes applied to original" archive; I don't *think* it causes a problem with the track changes algorithm...
						console.log(' Mismatch CFDocument identifier archive id: [' + archive_json.CFDocument.identifier + '] and framework id: [' + this.lsdoc_identifier + ']')
						// return
					}

					// store archive json in store
					this.$store.commit('set', ['archive_json', this.track_changes_fn, archive_json])

					// if we didn't get an archive_date (probably because we entered track changes via a direct link), set archive_date here based on the archive_json
					if (!this.archive_date) {
						this.archive_date = archive_json.CFDocument.lastChangeDateTime.replace(/T.*/, '')
						this.view_archive_ctl_text = sr('Tracking changes from $1', this.archive_date)
					}

					this.track_changes_post_file_load(item_identifier)
				})
			}
		},

		track_changes_post_file_load(item_identifier) {
			let case_json = this.$store.state.archive_json[this.track_changes_fn]
			this.track_changes_framework_record = U.create_framework_record(case_json.CFDocument.identifier, case_json, this.framework_record.ss_framework_data, true)
			// note that this uses the U.build_cfo fn, not this.build_cfo
			U.build_cfo(this.$worker, this.track_changes_framework_record.json).then((cfo)=>{
				this.track_changes_framework_record.cfo = cfo
				if (item_identifier) this.show_item(item_identifier)
				U.loading_stop()
			})
			.catch((e)=>{
				U.loading_stop()
				console.log(e)
			})
		},

		show_archive_table_view() {
			vapp.show_framework_update_report('framework_archive_compare')
		},

		cancel_track_changes() {
			this.track_changes_fn = ''
			this.track_changes_framework_record = null
			this.view_archive_ctl_text = ''
		},

		toggle_track_changes_legend() {
			vapp.framework_list_component.$refs.track_changes_legend.toggle_legend()
		},

		return_to_current_version(callback_fn) {
			if (this.view_archive_fn != '' && this.view_archive_fn != 'current') {
				// to return to the current version, we call refresh_lsdoc with no arguments
				this.refresh_lsdoc(null, callback_fn)
			} else {
				if (typeof(callback_fn) == 'function') {
					this.$nextTick(x=>callback_fn())
				}
			}
			// (we may have been viewing the current version with the "return to archive" buttons at the bottom)

			// reset the view_archive values
			this.view_archive_fn = ''
			this.view_archive_ctl_text = ''

			// restore value of editing_enabled, if set
			if (this.editing_prior_to_viewing_archive !== null) {
				this.editing_enabled = this.editing_prior_to_viewing_archive
				this.editing_prior_to_viewing_archive = null
			}
		},

		restore_archive(archive_filename) {
			let payload = {
				'archive_filename': archive_filename,
				'action': 'restore',
				'cf_item_count': this.framework_record.json.CFItems.length,
				'cf_association_count': this.framework_record.json.CFAssociations.length
			}

			U.loading_start()
			U.ajax('manage_archives', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in manage_archives ajax call');
					if (result.status == 'lock_conflict' || result.status == 'session_conflict') {
						// user has lost the edit lock due to inactivity and competing checkout
						this.$alert(result.message)

						this.view_archive_fn = 'lost_lock'	// set view_archive_fn to something to force reload of current version
						this.return_to_current_version()
						this.editing_enabled = false
					} else {
						this.$alert('There was a problem with restoring the archive to the current framework.');
					}
					return
				}
				if (result.files_identical == 'yes') {
					this.$alert('This archive is identical to the current version, so the restore was canceled.')
					return
				}

				// reset entity_archives so they'll be reloaded when we re-open change history for entities
				this.$store.commit('set', ['entity_archives', {}])

				// call return_to_current_version; the restored archive is now the current version!
				this.view_archive_fn = 'post_restore'	// set view_archive_fn to something to force reload of current version
				this.return_to_current_version()
				this.$alert('The framework has been restored to the archive.')
			})
		},

		show_update_report(flag) {
			// when the button is clicked from the bottom banner to show the framework_update_report, go back to the last-shown report
			this.return_to_current_version()
			setTimeout(()=>vapp.show_framework_update_report(flag), 0)
		},

		show_progression_table(framework_record, node) {
			this.progression_table_record = framework_record
			this.progression_table_data = node
		},

		cancel_tile_mode(evt) {
			if (!empty(evt?.target)) $(evt.target).closest('button').blur()
			this.$store.commit('set', ['start_string', ''])
			this.$store.commit('set', ['start_identifier', ''])
			this.$store.commit('set', ['start_string_identifiers', ''])
			this.viewer_mode = 'tree'
		},

		enter_tile_mode(evt) {
			if (!empty(evt?.target)) $(evt.target).closest('button').blur()
			this.$store.commit('set', ['start_string', ''])
			this.$store.commit('set', ['start_identifier', ''])
			this.$store.commit('set', ['start_string_identifiers', ''])
			this.viewer_mode='tiles'
		},

		run_custom_script() {
			this.$prompt({
				title: 'Run custom script',
				text: 'Enter the script name:',
				initialValue: this.$store.state.lst.last_custom_script_run,
				disableForEmptyValue: true,
				acceptText: 'Run…',
			}).then(custom_script_name => {
				this.$store.commit('lst_set', ['last_custom_script_run', custom_script_name])
				this.custom_script_name = custom_script_name
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},
		
	}
}
</script>

<style lang="scss">
// adjust these to fit app; also see initialize_tree above
$k-case-tree-outer-wrapper-top: 60px;	// height of permanent banner at the top of the screen (with the product title and product menu)
$k-case-tree-outer-wrapper-height: calc(100vh - 60px);
$k-case-tree-inner-wrapper-height: calc(100vh - 107px);	// 100vh - height of permanent banner - height of framework banner (with the search bar and framework menu)
// see also $k-case-tree-height in CASETree

.k-case-tree-outer-wrapper {
	position:fixed;
	top:$k-case-tree-outer-wrapper-top;
	left:0px;
	height:$k-case-tree-outer-wrapper-height;
	width:100%;
	z-index:200;
	background-color:#eee;
	display:flex;
	flex-direction:column;
	text-align:left;
	resize:both;

	.k-case-tree-top {
		background-color:#999;
		// display:flex;
		padding:4px 4px 0 4px;
	}

	.k-case-tree-top-inner {
		// flex:1 1 auto;
		color:#fff;
		padding-bottom:5px;
		display:flex;

		.k-case-tree-title {
			font-size:20px;
			line-height:24px;
			font-weight:900;
			flex: 0 1 auto;
			display:flex;
			align-items:center;
			overflow:hidden;
		}

		.k-case-tree-title-inner {
			flex:0 1 auto;
			white-space:nowrap;
			overflow:hidden;
			text-overflow: ellipsis;
		}

		.k-case-tree-title-inner-small {
			font-size:16px;
			line-height:19px;
			white-space:normal;
		}

		.k-case-tree-title-inner-xsmall {
			font-size:14px;
			line-height:16px;
			white-space:normal;
		}

		.k-case-tree-search-outer {
			flex:0 0 auto;
			display:flex;
			text-align:center;
			width:372px;
			font-weight:normal;
			position:relative;
			pointer-events: auto;

			input {
				font-size:14px;
			}

			.k-case-tree-search-results {
				position:absolute;
				z-index:102;
				right:12px;
				top:38px;
				background-color:#000;
				opacity:0.7;
				border-radius:4px;
				font-size:14px;
				padding:0 6px;
			}
		}
	}

	.k-case-tree-main {
		flex:1 1 auto;
		height:100%;
		pointer-events: none;
		// z-index:2;
		// padding:5px 0;

		.k-case-tree-inner-wrapper {
			// height:calc(100% - 10px);
			// height:100%;
			height: $k-case-tree-inner-wrapper-height;
			// overflow:auto;
			overflow:hidden;
			// padding-top:8px;
			position:relative;	// this is needed so that the item tile shows up in the right place on small screens
		}
	}

	.k-resizable-handle-br, .k-resizable-handle-bl, .k-resizable-handle-tr, .k-resizable-handle-tl, .k-resizable-handle-bm, .k-resizable-handle-tm, .k-resizable-handle-ml, .k-resizable-handle-mr {
		display:none!important;
	}
}

.k-case-tree-search-panel {
	position:absolute;
	right:4px;
	top:40px;
	border-radius:6px;
	background-color:#eee;
	color:#000;
	font-size:14px;
	z-index:10;
	text-align:left;
	// min-width:calc(100% - 8px);
	max-width:calc(50vw - 50px)!important;
	max-height:calc(100vh - 120px);
	overflow:auto;
}

.k-case-tree-search-panel-screen {
	position:absolute;
	z-index:1;
	width:100%;
	height:100%;
	background-color:transparent;
	display:none;
}

.k-case-tree-search-panel-behind {
	opacity:0.5;
	z-index:0;
	.k-case-tree-search-panel-screen { display:block; }
}

.k-case-tree-search-options {
	width:calc(50vw - 50px);
	max-width:800px;
	padding:0 8px 1px 8px;

	.k-case-tree-search-option-selectors {
		display:flex;
		flex-wrap: nowrap;
	}

	.v-select__selections {
		font-size:13px;
		line-height:13px;
		input[type=text] { display:none!important; }
	}
}

.k-case-tree-search-results-panel {
	// position:absolute;
	// left:auto!important;
	// right:4px!important;
	// top:40px!important;
	// border-radius:6px;
	max-width:800px;
	border-top: 1px solid #666;
	margin-top:4px;
	background-color:#ddd;

	.v-list {
		background-color:#ddd;
		margin:0 8px;
		overflow:hidden;
	}

	.v-list-item {
		font-size:12px;
		min-height:26px!important;
		text-align:left;
		white-space:nowrap;
		padding:0 4px;
	}

	.k-advanced-search-sim-score {
		flex: 0 0 40px;
		// width:40px;
		text-align:center;
		// font-size:13px;
		font-weight:bold;
		background-color:#444;
		color:#fff;
		border-radius:3px;
		text-decoration: none!important;
		margin-right:8px;

		.v-icon {
			font-size:11px!important;
			margin-right:2px;
			// margin-left:2px;
			margin-top:-3px;
		}
	}

	.k-advanced-search-multiple-items {
		flex: 0 0 auto;
		text-align:center;
		font-weight:bold;
		color:$v-green-darken-2;
		margin-right:8px;
	}

	.k-advanced-search-full-statement {
		overflow:hidden;
	}
}

.k-search-full-statement-tooltip {
	max-width:360px;
	font-size:12px;
	line-height:15px;
}

.k-case-tree-advanced-search-last-clicked-result {
	background-color:$v-yellow-accent-4!important;
}

.k-case-tree-search-item-tooltip {
	background-color:#eee!important;
	color:#000!important;
	opacity:1.0!important;
	transition: none!important;
	border:1px solid #666;
}

.k-case-tree-framework-image-wrapper {
	position:fixed;
	// z-index: -1;
	right:20px;
	bottom:20px;
	width: 248px;
	height: 248px;
	padding: 12px;
	border-radius: 8px;
	background-color: #fff;
	// background-color: rgba(255, 255, 255, 0.5);
	// margin: 4px 8px 0 8px;
	opacity:0.3;
	z-index:-1;
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		max-width: 224px;
		max-height:224px;
		border-radius:6px;
		// opacity:0.2;
	}
}

.k-case-tree-top-control {
	display:flex;
	align-items:center;
	font-size:12px;
	font-weight:bold;
	color:#666;
	text-transform: uppercase;
	letter-spacing: 0.5px;
}

.k-case-tree-outer-wrapper:hover {
	.k-resizable-handle-br, .k-resizable-handle-bl, .k-resizable-handle-tr, .k-resizable-handle-tl, .k-resizable-handle-bm, .k-resizable-handle-tm, .k-resizable-handle-ml, .k-resizable-handle-mr {
		display:block!important;
	}
}

// allow for scrolling in table view
.k-case-tree-outer-wrapper--table-mode {
	.k-case-tree-main {
		.k-case-tree-inner-wrapper {
			overflow:auto;
			pointer-events:auto;
		}
	}
}

// adjustments for minimized view
.k-case-tree-outer-wrapper--minimized {
	left:10px;
	top:100px;
	border:5px solid #999;
	border-radius:10px;

	.k-case-tree-main {
		.k-case-tree-inner-wrapper {
			height:calc(100% - 100px);
		}
	}

	.k-case-tree-top {
		padding:0;
	}

	.k-case-tree-framework-image-wrapper {
		display:none;
	}

	.k-resizable-handle-br, .k-resizable-handle-bl, .k-resizable-handle-tr, .k-resizable-handle-tl, .k-resizable-handle-bm, .k-resizable-handle-tm, .k-resizable-handle-ml, .k-resizable-handle-mr {
		background-color:#fff;
		border:1px solid #000;
		position:absolute;
		width:16px;
		height:16px;
		// currently not showing handles, even in minimized mode
		display:none!important;
	}

	.k-resizable-handle-br {
		right:-10px;
		bottom:-10px;
		cursor: se-resize;
	}

	.k-resizable-handle-bl {
		left:-10px;
		bottom:-10px;
		cursor: sw-resize;
	}

	.k-resizable-handle-mr {
		right:-10px;
		bottom:calc(50% - 8px);
		cursor: e-resize;
	}

	.k-resizable-handle-ml {
		left:-10px;
		bottom:calc(50% - 8px);
		cursor: w-resize;
	}

	.k-resizable-handle-bm {
		right:calc(50% - 8px);
		bottom:-10px;
		cursor: s-resize;
	}
}

.k-case-tree-main {
	.k-ls-doc-preview {
		font-size:14px;
		margin:4px 8px 12px 8px;
		border-radius:6px;
		color:#222;
		background-color:#fff;
		cursor:default;
		max-width:750px;
		padding:10px 12px;
		font-size:15px;
		li {
			margin-top:2px;
			margin-bottom:2px;
		}
		a {
			font-weight:normal!important;
			text-decoration:none;
		}
	}
}

.k-ls-item-preview {
	// position:relative;
	// display:inline-block;
	font-size:12px;
	font-weight:normal;
	margin-top:8px;
	margin-bottom:12px;
	border-radius:6px;
	background-color:#fff;
	padding:8px 8px 12px 8px;
	// min-width:600px;
	color:#000;
	cursor:default;
	li {
		margin-top:2px;
		margin-bottom:2px;
	}
}

///////////////////////////
.k-case-tree-outer-wrapper.k-framework-color-0, .k-editor-wrapper-outer .k-framework-color-0-editor {
	.primary { background-color: $k-dfc-darken-3 !important; border-color: $k-dfc-darken-3 !important; }
	.primary--text { color: $k-dfc-darken-3 !important; caret-color: $k-dfc-darken-3 !important;}
	border-color: $k-dfc-darken-3;
	// .k-case-tree-item-descendant-or-ancestor-of-last-clicked { color:$k-dfc-darken-3; }
	.k-case-tree-top, .k-case-item-editor-title { background-color:$k-dfc-darken-3; }
	.k-case-tree-item-being-edited {
		border-color:$k-dfc-darken-3!important;
		.k-case-tree-item-node-btn { color:$k-dfc-darken-3!important; }
	}
	.k-case-graphic-tree-node { background-color:$k-dfc-darken-3; }
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$k-dfc-darken-3; }
	.k-case-tree-tile-view .k-case-tree-item-last-clicked { background-color:$k-dfc-lighten-5; } // ; border-color:$k-dfc-darken-3; }
	.k-case-tree-item-tile-outer { border-color:$k-dfc-darken-3; }
}
.k-case-item-editor.k-framework-color-0 {background-color:$k-dfc-darken-3!important; }
.k-framework-color-0-editor {
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$k-dfc-darken-3; }
}
.k-framework-color-0-svg-line line { stroke:$k-dfc-darken-3!important; }

.k-case-tree-outer-wrapper.k-framework-color-1, .k-editor-wrapper-outer .k-framework-color-1-editor {
	.primary { background-color: $v-pink-darken-3 !important; border-color: $v-pink-darken-3 !important; }
	.primary--text { color: $v-pink-darken-3 !important; caret-color: $v-pink-darken-3 !important;}
	border-color: $v-pink-darken-3;
	// .k-case-tree-item-descendant-or-ancestor-of-last-clicked { color:$v-pink-darken-3; }
	.k-case-tree-top, .k-case-item-editor-title { background-color:$v-pink-darken-3; }
	.k-case-tree-item-being-edited {
		border-color:$v-pink-darken-3!important;
		.k-case-tree-item-node-btn { color:$v-pink-darken-3!important; }
	}
	.k-case-graphic-tree-node { background-color:$v-pink-darken-3; }
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-pink-darken-3; }
	.k-case-tree-tile-view .k-case-tree-item-last-clicked { background-color:$v-pink-lighten-5; } // ; border-color:$v-pink-darken-3; }
	.k-case-tree-item-tile-outer { border-color:$v-pink-darken-3; }
}
.k-case-item-editor.k-framework-color-1 {background-color:$v-pink-darken-3!important; }
.k-framework-color-1-editor  {
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-pink-darken-3!important; }
}
.k-framework-color-1-svg-line line { stroke:$v-pink-darken-3!important; }

.k-case-tree-outer-wrapper.k-framework-color-2, .k-editor-wrapper-outer .k-framework-color-2-editor {
	.primary { background-color: $v-deep-purple-darken-3 !important; border-color: $v-deep-purple-darken-3 !important; }
	.primary--text { color: $v-deep-purple-darken-3 !important; caret-color: $v-deep-purple-darken-3 !important;}
	border-color: $v-deep-purple-darken-3;
	// .k-case-tree-item-descendant-or-ancestor-of-last-clicked { color:$v-deep-purple-darken-3; }
	.k-case-tree-top, .k-case-item-editor-title { background-color:$v-deep-purple-darken-3; }
	.k-case-tree-item-being-edited {
		border-color:$v-deep-purple-darken-3!important;
		.k-case-tree-item-node-btn { color:$v-deep-purple-darken-3!important; }
	}
	.k-case-graphic-tree-node { background-color:$v-deep-purple-darken-3; }
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-deep-purple-darken-3; }
	.k-case-tree-tile-view .k-case-tree-item-last-clicked { background-color:$v-deep-purple-lighten-5; } // ; border-color:$v-deep-purple-darken-3; }
	.k-case-tree-item-tile-outer { border-color:$v-deep-purple-darken-3; }
}
.k-case-item-editor.k-framework-color-2 {background-color:$v-deep-purple-darken-3!important; }
.k-framework-color-2-editor  {
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-deep-purple-darken-3!important; }
}
.k-framework-color-2-svg-line line { stroke:$v-deep-purple-darken-3!important; }

.k-case-tree-outer-wrapper.k-framework-color-3, .k-editor-wrapper-outer .k-framework-color-3-editor {
	.primary { background-color: $v-indigo-darken-3 !important; border-color: $v-indigo-darken-3 !important; }
	.primary--text { color: $v-indigo-darken-3 !important; caret-color: $v-indigo-darken-3 !important;}
	border-color: $v-indigo-darken-3;
	// .k-case-tree-item-descendant-or-ancestor-of-last-clicked { color:$v-indigo-darken-3; }
	.k-case-tree-top, .k-case-item-editor-title { background-color:$v-indigo-darken-3; }
	.k-case-tree-item-being-edited {
		border-color:$v-indigo-darken-3!important;
		.k-case-tree-item-node-btn { color:$v-indigo-darken-3!important; }
	}
	.k-case-graphic-tree-node { background-color:$v-indigo-darken-3; }
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-indigo-darken-3; }
	.k-case-tree-tile-view .k-case-tree-item-last-clicked { background-color:$v-indigo-lighten-5; } // ; border-color:$v-indigo-darken-3; }
	.k-case-tree-item-tile-outer { border-color:$v-indigo-darken-3; }
}
.k-case-item-editor.k-framework-color-3 {background-color:$v-indigo-darken-3!important; }
.k-framework-color-3-editor  {
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-indigo-darken-3!important; }
}
.k-framework-color-3-svg-line line { stroke:$v-indigo-darken-3!important; }

.k-case-tree-outer-wrapper.k-framework-color-4, .k-editor-wrapper-outer .k-framework-color-4-editor {
	.primary { background-color: $v-blue-darken-3 !important; border-color: $v-blue-darken-3 !important; }
	.primary--text { color: $v-blue-darken-3 !important; caret-color: $v-blue-darken-3 !important;}
	border-color: $v-blue-darken-3;
	// .k-case-tree-item-descendant-or-ancestor-of-last-clicked { color:$v-blue-darken-3; }
	.k-case-tree-top, .k-case-item-editor-title { background-color:$v-blue-darken-3; }
	.k-case-tree-item-being-edited {
		border-color:$v-blue-darken-3!important;
		.k-case-tree-item-node-btn { color:$v-blue-darken-3!important; }
	}
	.k-case-graphic-tree-node { background-color:$v-blue-darken-3; }
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-blue-darken-3; }
	.k-case-tree-tile-view .k-case-tree-item-last-clicked { background-color:$v-blue-lighten-5; } // ; border-color:$v-blue-darken-3; }
	.k-case-tree-item-tile-outer { border-color:$v-blue-darken-3; }
}
.k-case-item-editor.k-framework-color-4 {background-color:$v-blue-darken-3!important; }
.k-framework-color-4-editor  {
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-blue-darken-3!important; }
}
.k-framework-color-4-svg-line line { stroke:$v-blue-darken-3!important; }

.k-case-tree-outer-wrapper.k-framework-color-5, .k-editor-wrapper-outer .k-framework-color-5-editor {
	.primary { background-color: $v-cyan-darken-3 !important; border-color: $v-cyan-darken-3 !important; }
	.primary--text { color: $v-cyan-darken-3 !important; caret-color: $v-cyan-darken-3 !important;}
	border-color: $v-cyan-darken-3;
	// .k-case-tree-item-descendant-or-ancestor-of-last-clicked { color:$v-cyan-darken-3; }
	.k-case-tree-top, .k-case-item-editor-title { background-color:$v-cyan-darken-3; }
	// .k-cfv-toolbar { background-color:$v-cyan-lighten-5; }
	.k-case-tree-item-being-edited {
		border-color:$v-cyan-darken-3!important;
		.k-case-tree-item-node-btn { color:$v-cyan-darken-3!important; }
	}
	.k-case-graphic-tree-node { background-color:$v-cyan-darken-3; }
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-cyan-darken-3; }
	.k-case-tree-tile-view .k-case-tree-item-last-clicked { background-color:$v-cyan-lighten-5; } // ; border-color:$v-cyan-darken-3; }
	.k-case-tree-item-tile-outer { border-color:$v-cyan-darken-3; }
}
.k-case-item-editor.k-framework-color-5 {background-color:$v-cyan-darken-3!important; }
.k-framework-color-5-editor  {
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-cyan-darken-3!important; }
}
.k-framework-color-5-svg-line line { stroke:$v-cyan-darken-3!important; }

.k-case-tree-outer-wrapper.k-framework-color-6, .k-editor-wrapper-outer .k-framework-color-6-editor {
	.primary { background-color: $v-lime-darken-4 !important; border-color: $v-lime-darken-4 !important; }
	.primary--text { color: $v-lime-darken-4 !important; caret-color: $v-lime-darken-4 !important;}
	border-color: $v-lime-darken-4;
	// .k-case-tree-item-descendant-or-ancestor-of-last-clicked { color:$v-lime-darken-4; }
	.k-case-tree-top, .k-case-item-editor-title { background-color:$v-lime-darken-4; }
	.k-case-tree-item-being-edited {
		border-color:$v-lime-darken-4!important;
		.k-case-tree-item-node-btn { color:$v-lime-darken-4!important; }
	}
	.k-case-graphic-tree-node { background-color:$v-lime-darken-4; }
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-lime-darken-4; }
	.k-case-tree-tile-view .k-case-tree-item-last-clicked { background-color:$v-lime-lighten-5; } // ; border-color:$v-lime-darken-4; }
	.k-case-tree-item-tile-outer { border-color:$v-lime-darken-4; }
}
.k-case-item-editor.k-framework-color-6 {background-color:$v-lime-darken-4!important; }
.k-framework-color-6-editor  {
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-lime-darken-4!important; }
}
.k-framework-color-6-svg-line line { stroke:$v-lime-darken-4!important; }

.k-case-tree-outer-wrapper.k-framework-color-7, .k-editor-wrapper-outer .k-framework-color-7-editor {
	.primary { background-color: $v-brown-darken-3 !important; border-color: $v-brown-darken-3 !important; }
	.primary--text { color: $v-brown-darken-3 !important; caret-color: $v-brown-darken-3 !important;}
	border-color: $v-brown-darken-3;
	// .k-case-tree-item-descendant-or-ancestor-of-last-clicked { color:$v-brown-darken-3; }
	.k-case-tree-top, .k-case-item-editor-title { background-color:$v-brown-darken-3; }
	.k-case-tree-item-being-edited {
		border-color:$v-brown-darken-3!important;
		.k-case-tree-item-node-btn { color:$v-brown-darken-3!important; }
	}
	.k-case-graphic-tree-node { background-color:$v-brown-darken-3; }
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-brown-darken-3; }
	.k-case-tree-tile-view .k-case-tree-item-last-clicked { background-color:$v-brown-lighten-5; } // ; border-color:$v-brown-darken-3; }
	.k-case-tree-item-tile-outer { border-color:$v-brown-darken-3; }
}
.k-case-item-editor.k-framework-color-7 {background-color:$v-brown-darken-3!important; }
.k-framework-color-7-editor  {
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-brown-darken-3!important; }
}
.k-framework-color-7-svg-line line { stroke:$v-brown-darken-3!important; }

.k-case-tree-outer-wrapper.k-framework-color-8, .k-editor-wrapper-outer .k-framework-color-8-editor {
	.primary { background-color: $v-purple-darken-3 !important; border-color: $v-purple-darken-3 !important; }
	.primary--text { color: $v-purple-darken-3 !important; caret-color: $v-purple-darken-3 !important;}
	border-color: $v-purple-darken-3;
	// .k-case-tree-item-descendant-or-ancestor-of-last-clicked { color:$v-purple-darken-3; }
	.k-case-tree-top, .k-case-item-editor-title { background-color:$v-purple-darken-3; }
	.k-case-tree-item-being-edited {
		border-color:$v-purple-darken-3!important;
		.k-case-tree-item-node-btn { color:$v-purple-darken-3!important; }
	}
	.k-case-graphic-tree-node { background-color:$v-purple-darken-3; }
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-purple-darken-3; }
	.k-case-tree-tile-view .k-case-tree-item-last-clicked { background-color:$v-purple-lighten-5; } // ; border-color:$v-purple-darken-3; }
	.k-case-tree-item-tile-outer { border-color:$v-purple-darken-3; }
}
.k-case-item-editor.k-framework-color-8 {background-color:$v-purple-darken-3!important; }
.k-framework-color-8-editor {
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-purple-darken-3!important; }
}
.k-framework-color-8-svg-line line { stroke:$v-purple-darken-3!important; }

.k-case-tree-outer-wrapper.k-framework-color-9, .k-editor-wrapper-outer .k-framework-color-9-editor {
	.primary { background-color: $v-light-blue-darken-3 !important; border-color: $v-light-blue-darken-3 !important; }
	.primary--text { color: $v-light-blue-darken-3 !important; caret-color: $v-light-blue-darken-3 !important;}
	border-color: $v-light-blue-darken-3;
	// .k-case-tree-item-descendant-or-ancestor-of-last-clicked { color:$v-light-blue-darken-3; }
	.k-case-tree-top, .k-case-item-editor-title { background-color:$v-light-blue-darken-3; }
	.k-case-tree-item-being-edited {
		border-color:$v-light-blue-darken-3!important;
		.k-case-tree-item-node-btn { color:$v-light-blue-darken-3!important; }
	}
	.k-case-graphic-tree-node { background-color:$v-light-blue-darken-3; }
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-light-blue-darken-3; }
	.k-case-tree-tile-view .k-case-tree-item-last-clicked { background-color:$v-light-blue-lighten-5; } // ; border-color:$v-light-blue-darken-3; }
	.k-case-tree-item-tile-outer { border-color:$v-light-blue-darken-3; }
}
.k-case-item-editor.k-framework-color-9 {background-color:$v-light-blue-darken-3!important; }
.k-framework-color-9-editor {
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-light-blue-darken-3!important; }
}
.k-framework-color-9-svg-line line { stroke:$v-light-blue-darken-3!important; }

.k-case-tree-outer-wrapper.k-framework-color-10, .k-editor-wrapper-outer .k-framework-color-10-editor {
	.primary { background-color: $v-teal-darken-3 !important; border-color: $v-teal-darken-3 !important; }
	.primary--text { color: $v-teal-darken-3 !important; caret-color: $v-teal-darken-3 !important;}
	border-color: $v-teal-darken-3;
	// .k-case-tree-item-descendant-or-ancestor-of-last-clicked { color:$v-teal-darken-3; }
	.k-case-tree-top, .k-case-item-editor-title { background-color:$v-teal-darken-3; }
	.k-case-tree-item-being-edited {
		border-color:$v-teal-darken-3!important;
		.k-case-tree-item-node-btn { color:$v-teal-darken-3!important; }
	}
	.k-case-graphic-tree-node { background-color:$v-teal-darken-3; }
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-teal-darken-3; }
	.k-case-tree-tile-view .k-case-tree-item-last-clicked { background-color:$v-teal-lighten-5; } // ; border-color:$v-teal-darken-3; }
	.k-case-tree-item-tile-outer { border-color:$v-teal-darken-3; }
}
.k-case-item-editor.k-framework-color-10 {background-color:$v-teal-darken-3!important; }
.k-framework-color-10-editor {
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-teal-darken-3!important; }
}
.k-framework-color-10-svg-line line { stroke:$v-teal-darken-3!important; }

.k-case-tree-outer-wrapper.k-framework-color-11, .k-editor-wrapper-outer .k-framework-color-11-editor {
	.primary { background-color: $v-green-darken-3 !important; border-color: $v-green-darken-3 !important; }
	.primary--text { color: $v-green-darken-3 !important; caret-color: $v-green-darken-3 !important;}
	border-color: $v-green-darken-3;
	// .k-case-tree-item-descendant-or-ancestor-of-last-clicked { color:$v-green-darken-3; }
	.k-case-tree-top, .k-case-item-editor-title { background-color:$v-green-darken-3; }
	.k-case-tree-item-being-edited {
		border-color:$v-green-darken-3!important;
		.k-case-tree-item-node-btn { color:$v-green-darken-3!important; }
	}
	.k-case-graphic-tree-node { background-color:$v-green-darken-3; }
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-green-darken-3; }
	.k-case-tree-tile-view .k-case-tree-item-last-clicked { background-color:$v-green-lighten-5; } // ; border-color:$v-green-darken-3; }
	.k-case-tree-item-tile-outer { border-color:$v-green-darken-3; }
}
.k-case-item-editor.k-framework-color-11 {background-color:$v-green-darken-3!important; }
.k-framework-color-11-editor {
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-green-darken-3!important; }
}
.k-framework-color-11-svg-line line { stroke:$v-green-darken-3!important; }

.k-case-tree-outer-wrapper.k-framework-color-12, .k-editor-wrapper-outer .k-framework-color-12-editor {
	.primary { background-color: $v-red-darken-3 !important; border-color: $v-red-darken-3 !important; }
	.primary--text { color: $v-red-darken-3 !important; caret-color: $v-red-darken-3 !important;}
	border-color: $v-red-darken-3;
	// .k-case-tree-item-descendant-or-ancestor-of-last-clicked { color:$v-red-darken-3; }
	.k-case-tree-top, .k-case-item-editor-title { background-color:$v-red-darken-3; }
	.k-case-tree-item-being-edited {
		border-color:$v-red-darken-3!important;
		.k-case-tree-item-node-btn { color:$v-red-darken-3!important; }
	}
	.k-case-graphic-tree-node { background-color:$v-red-darken-3; }
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-red-darken-3; }
	.k-case-tree-tile-view .k-case-tree-item-last-clicked { background-color:$v-red-lighten-5; } // ; border-color:$v-red-darken-3; }
	.k-case-tree-item-tile-outer { border-color:$v-red-darken-3; }
}
.k-case-item-editor.k-framework-color-12 {background-color:$v-red-darken-3!important; }
.k-framework-color-12-editor {
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-red-darken-3!important; }
}
.k-framework-color-12-svg-line line { stroke:$v-red-darken-3!important; }

.k-case-tree-outer-wrapper.k-framework-color-13, .k-editor-wrapper-outer .k-framework-color-13-editor {
	.primary { background-color: $v-orange-darken-4 !important; border-color: $v-orange-darken-4 !important; }
	.primary--text { color: $v-orange-darken-4 !important; caret-color: $v-orange-darken-4 !important;}
	border-color: $v-orange-darken-4;
	// .k-case-tree-item-descendant-or-ancestor-of-last-clicked { color:$v-orange-darken-4; }
	.k-case-tree-top, .k-case-item-editor-title { background-color:$v-orange-darken-4; }
	.k-case-tree-item-being-edited {
		border-color:$v-orange-darken-4!important;
		.k-case-tree-item-node-btn { color:$v-orange-darken-4!important; }
	}
	.k-case-graphic-tree-node { background-color:$v-orange-darken-4; }
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-orange-darken-4; }
	.k-case-tree-tile-view .k-case-tree-item-last-clicked { background-color:$v-orange-lighten-5; } // ; border-color:$v-orange-darken-4; }
	.k-case-tree-item-tile-outer { border-color:$v-orange-darken-4; }
}
.k-case-item-editor.k-framework-color-13 {background-color:$v-orange-darken-4!important; }
.k-framework-color-13-editor {
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-orange-darken-4!important; }
}
.k-framework-color-13-svg-line line { stroke:$v-orange-darken-4!important; }

.k-case-tree-outer-wrapper.k-framework-color-14, .k-editor-wrapper-outer .k-framework-color-14-editor {
	.primary { background-color: $v-grey-darken-4 !important; border-color: $v-grey-darken-4 !important; }
	.primary--text { color: $v-grey-darken-4 !important; caret-color: $v-grey-darken-4 !important;}
	border-color: $v-grey-darken-4;
	// .k-case-tree-item-descendant-or-ancestor-of-last-clicked { color:$v-grey-darken-4; }
	.k-case-tree-top, .k-case-item-editor-title { background-color:$v-grey-darken-3; }
	.k-case-tree-item-being-edited {
		border-color:$v-grey-darken-4!important;
		.k-case-tree-item-node-btn { color:$v-grey-darken-4!important; }
	}
	.k-case-graphic-tree-node { background-color:$v-grey-darken-4; }
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-grey-darken-4; }
	.k-case-tree-tile-view .k-case-tree-item-last-clicked { background-color:$v-grey-lighten-5; } // ; border-color:$v-grey-darken-4; }
	.k-case-tree-item-tile-outer { border-color:$v-grey-darken-4; }
}
.k-case-item-editor.k-framework-color-14 {background-color:$v-grey-darken-4!important; }
.k-framework-color-14-editor {
	.k-case-tree-item-last-clicked > .k-case-tree-item { border-color:$v-grey-darken-4!important; }
}
.k-framework-color-14-svg-line line { stroke:$v-grey-darken-4!important; }


.k-case-archive-view-ctl {
	background-color: $v-orange-darken-3;
	color: #fff;
	position:fixed!important;
	z-index: 3;	// needs to be above the k-case-tree-pinned-items-wrapper
	left:0;
	bottom:0;
	width:100%;
	font-size:14px;
	line-height:18px;
	padding:4px 8px;
	// font-weight:bold;
	display:flex;
	align-items: center;
	// height: 100px;
}

.k-case-archive-view-ctl-current {
	background-color:$v-cyan-darken-3;
}

// adjustments for embedded mode
.k-case-tree-outer-wrapper-embedded {

	.k-case-tree-top {
		padding-right:12px;
		padding-left:12px;
	}
}

.k-tile-mode-to-tree-mode-btn {
	position:fixed;
	right:8px;
	top:114px;
}

.k-ap-spreadsheet-export {
	border-collapse:collapse;
	padding-right:16px;
	td, th {
		font-size:12px;
		line-height:12px;
		padding:2px;
		border:1px solid #ccc;
		max-width: 120px;
		min-width: 120px;
		height:16px;
		white-space:nowrap;
		overflow:auto;
	}
	th {
		font-weight:bold;
		background-color:#333;
		color:#fff;
		text-align:left;
	}
}
</style>
