<template><div v-if="item_should_be_displayed" class="k-case-tree-item-outer" :class="item_outer_css()" :data-tree-key="item.tree_key" @click.stop="clear_last_clicked_node">

	<div v-if="show_tile">
		<CASEItemTile v-if="show_tile" :home_framework_record="home_framework_record" :item="item" :viewer="viewer"/>
		<div class="k-case-tree-item-tile-spacer"><CASEItemTile v-if="show_tile" :home_framework_record="home_framework_record" :item="item" :viewer="viewer"/></div>
	</div>

	<v-hover v-slot:default="{hover}"><div class="k-case-tree-item" :data-case-tree-item-tree-key="item.tree_key" v-show="!show_tile&&(maximized||!node_is_active||!in_viewer_context||batch_editing)" :class="item_css(hover)">
		<span class="k-case-tree-item-floating-bubbles">
			<div v-if="show_hide_all_btn_text"><v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" style="margin-top:-2px;background-color:#eee;border-radius:2px;padding-left:4px;padding-right:4px; margin-right:2px;" x-small class="k-tight-btn" text color="primary" @click="toggle_show_selected_items">{{show_hide_all_btn_text}}</v-btn></template><div class="text-center" v-html="show_hide_all_btn_tooltip"></div></v-tooltip></div>
			<span v-if="making_associations||aligning_resources" v-visible="hover" class="ml-1" style="line-height:20px; background-color:#fff; padding:0 3px 2px 3px; border-radius:4px;margin-right:1px;"><v-icon small color="#666" @click.stop="show_expanded_tile">fas fa-expand</v-icon></span>
			<span v-if="show_associations_count&&(true||hover||descendant_or_ancestor_of_last_clicked)" class="k-case-tree-item-associations-count" :class="show_associations_count_css(hover)"><v-icon x-small>fas fa-arrows-alt-h</v-icon><span v-if="self_association_count>0" style="margin-left:3px" v-html="self_association_count"></span></span>
			<span v-if="show_comments_count&&(hover||node_is_active||comment_count>0)" class="k-case-tree-item-comments-count" :class="show_comments_count_css(hover)"><v-icon x-small>{{hover||node_is_active?'fas':'far'}} fa-comment</v-icon><span class="k-case-tree-item-comments-number" v-if="self_comment_count>0" style="margin-left:3px">{{self_comment_count}}</span></span>
		</span>

		<!-- This is a place where using v-if and v-show in the right places/combo is crucial for things to work right -->
		<div v-if="(show_chooser_btn||show_checkbox_fn||show_move_fn||selected_items)" class="k-case-tree-item-btns">
			<v-checkbox v-if="show_checkbox_fn&&edited_node!=item" small class="mt-0 pt-0" label="" v-model="node_is_checked" @click.stop="checkbox_clicked" hide-details></v-checkbox>
			<v-menu :disabled="!crosswalk || crosswalk.framework_record_b.lsdoc_identifier != framework_record.lsdoc_identifier || !crosswalk.tree_left_chosen_node" left nudge-top="8" offset-x transition="slide-x-transition" open-on-hover content-class="elevation-0">
				<template v-slot:activator="{on, attrs}">
					<v-btn v-on="on" v-bind="attrs" v-show="show_chooser_btn" icon x-small :color="chooser_color(hover)" @click.stop="show_chooser_fn_clicked" style="margin-left:-1px"><v-icon :style="chooser_style(hover)">{{chooser_icon(hover)}}</v-icon></v-btn>
				</template>
				<div ref="tree_association_menu">
					<div class="d-flex py-0" style="background-color: white; border-radius: 4px; display: none; border: 2px #ddd solid;">
						<div style="min-height: 30px!important; border-radius: 2px;" :style="'background-color: ' + ((crosswalk_association_type == 'exact') ? 'orange' : '')" class="px-2 py-0 my-0 v-list-item v-list-item--link theme--light" @click="e => { if (crosswalk_association_type != 'exact') {crosswalk.add_associations('exact', {left_identifier: crosswalk.tree_left_chosen_node.cfitem.identifier, right_identifier: item.cfitem.identifier})} else {crosswalk.delete_associations({left_identifier: crosswalk_associated_item_identifier, right_identifier: item.cfitem.identifier})} }"><v-icon small :style="'color: ' + ((crosswalk_association_type == 'exact') ? 'white' : '')">fas fa-bullseye</v-icon></div>
						<div style="min-height: 30px!important; border-radius: 2px;" :style="'background-color: ' + ((crosswalk_association_type == 'nearexact') ? 'orange' : '')" class="px-2 py-0 my-0 v-list-item v-list-item--link theme--light" @click="e => { if (crosswalk_association_type != 'nearexact') {crosswalk.add_associations('nearexact', {left_identifier: crosswalk.tree_left_chosen_node.cfitem.identifier, right_identifier: item.cfitem.identifier})} else {crosswalk.delete_associations({left_identifier: crosswalk_associated_item_identifier, right_identifier: item.cfitem.identifier})} }"><v-icon small :style="'color: ' + ((crosswalk_association_type == 'nearexact') ? 'white' : '')">fas fa-circle-dot</v-icon></div>
						<div style="min-height: 30px!important; border-radius: 2px;" :style="'background-color: ' + ((crosswalk_association_type == 'close') ? 'orange' : '')" class="px-2 py-0 my-0 v-list-item v-list-item--link theme--light" @click="e => { if (crosswalk_association_type != 'close') {crosswalk.add_associations('close', {left_identifier: crosswalk.tree_left_chosen_node.cfitem.identifier, right_identifier: item.cfitem.identifier})} else {crosswalk.delete_associations({left_identifier: crosswalk_associated_item_identifier, right_identifier: item.cfitem.identifier})} }"><v-icon small :style="'color: ' + ((crosswalk_association_type == 'close') ? 'white' : '')">fas fa-circle</v-icon></div>
						<div style="min-height: 30px!important; border-radius: 2px;" :style="'background-color: ' + ((crosswalk_association_type == 'moderate') ? 'orange' : '')" class="px-2 py-0 my-0 v-list-item v-list-item--link theme--light" @click="e => { if (crosswalk_association_type != 'moderate') {crosswalk.add_associations('moderate', {left_identifier: crosswalk.tree_left_chosen_node.cfitem.identifier, right_identifier: item.cfitem.identifier})} else {crosswalk.delete_associations({left_identifier: crosswalk_associated_item_identifier, right_identifier: item.cfitem.identifier})} }"><v-icon small :style="'color: ' + ((crosswalk_association_type == 'moderate') ? 'white' : '')">fas fa-circle-half-stroke</v-icon></div>
					</div>
				</div>
			</v-menu>
			<v-icon v-if="selected_items&&!show_chooser_btn" :style="selected_item_icon_style" color="primary">{{item_is_selected?'fas fa-play':'far fa-circle'}}</v-icon>
			<v-icon v-if="show_move_fn&&!item_is_document" class="k-case-item-move-handle" :color="hover?'green':'#ccc'" small>fas fa-arrows-alt</v-icon>
		</div>

		<div v-if="!in_viewer_context" v-visible="hover||item_is_last_clicked" class="float-right" style="line-height:20px; background-color:#fff; padding:0 3px 2px 3px; border-radius:4px;"><v-icon small color="#666" style="background-color:#fff" @click="show_expanded_tile">fas fa-expand</v-icon></div>

		<div class="k-case-tree-item-inner" @click.stop="item_clicked">
			<div v-if="!in_viewer_context&&item.comp_score>-1" class="k-case-tree-item-sim-score">
				<v-tooltip bottom><template v-slot:activator="{on}"><div style="width:20px"><v-progress-linear v-on="on" :value="item.comp_score" :color="comp_score_color" height="8"></v-progress-linear></div></template><div v-html="item.comp_score_tooltip"></div></v-tooltip>
			</div>

			<v-btn v-if="item_is_document" class="k-case-tree-item-node-btn" x-small icon color="grey darken-2"><v-icon small>fas fa-map</v-icon></v-btn>
			<v-btn v-if="!item_is_document && item.children.length>0" class="k-case-tree-item-node-btn" :style="node_btn_style" x-small icon color="grey darken-2" @click.stop="node_btn_clicked"><v-icon small>fas fa-chevron-circle-right</v-icon></v-btn>
			<v-btn v-if="!item_is_document && item.children.length==0" class="k-case-tree-item-node-btn" x-small icon color="grey darken-2" @click="leaf_node_btn_clicked"><v-icon small>fas fa-dot-circle</v-icon></v-btn>

			<v-btn v-if="tracking_changes" class="k-case-tree-item-node-btn k-case-tree-item-track-changes-btn" :style="'transform:rotate('+track_change_icon_rotation+'deg)'" x-small icon @click.stop="tracked_change_clicked"><v-icon small>fas {{tracked_change_icon}}</v-icon></v-btn>

			<div class="k-case-tree-item-code-plus-statement">
				<div>
					<span v-if="stats_mode" @mouseover="stats_mouseover" @mouseout="stats_mouseout" @click="stats_clicked">
						<b class="green--text" v-if="item.cfitem.stats.descendent_count>1">[{{item.cfitem.stats.descendent_count}}]</b>
						<b class="red--text" style="font-size:10px" v-if="item.cfitem.stats.items_removed>0">–{{item.cfitem.stats.items_removed}}</b>
						<span v-if="item.cfitem.stats.is_duplicate_of"><v-icon x-small color="green">fas fa-circle</v-icon></span>
						<span v-if="item.cfitem.stats.possible_related_to&&!item.cfitem.stats.is_duplicate_of"><v-icon x-small color="red">far fa-circle</v-icon></span>
						<span v-if="item.cfitem.stats.add_is_child_of_to_duplicate"><v-icon x-small color="red">fas fa-dot-circle</v-icon></span>
						<span v-if="item.cfitem.stats.to_be_deleted"><v-icon x-small color="red">fas fa-circle</v-icon></span>
					</span><!--
					--><b v-if="item.cfitem.humanCodingScheme" class="k-case-tree-item-hcs mr-1 grey--text text--darken-2" v-html="human_coding_scheme" :class="hover?'k-case-tree-item-statement-hovered':''"></b><!--
					--><span class="k-case-tree-item-statement k-case-tree-markdown" v-html="tree_statement" :class="`${(hover&&!item.cfitem.humanCodingScheme)?'k-case-tree-item-statement-hovered':''} ${(crosswalk && !crosswalk.show_diff_coloring) ? 'k-crosswalk-hide-diff-coloring' : ''}`"></span><!--  k-case-tree-item-statement-abbreviated  v-if="in_viewer_context&&!batch_editing&&!moving"
					--><!--<span class="k-case-tree-item-statement-full k-case-tree-markdown" v-else :class="(hover&&!item.cfitem.humanCodingScheme)?'k-case-tree-item-statement-hovered':''" v-html="full_statement"></span>--><!--
					--><!--<span v-if="!in_viewer_context&&(hover||item_is_last_clicked)" class="ml-2" style="line-height:20px; position:absolute; right:0px;top:1px; background-color:#fff; padding:0 3px 2px 3px; border-radius:4px;"><v-icon small color="#666" @click="show_expanded_tile">fas fa-expand</v-icon></span>-->
					<span v-if="$vuetify.breakpoint.xs&&item_is_last_clicked" class="ml-1"><v-icon small color="#666" @click="show_expanded_tile">fas fa-expand</v-icon></span> <!-- show expand btn when we're in a phone-sized window -->
				</div>
			</div>
		</div>
		<div v-if="!item_is_document&&node_is_open&&progression_table_available" class="my-1 ml-7"><v-btn x-small outlined color="primary" @click.stop="show_progression_table"><v-icon small class="mx-2">fas fa-forward</v-icon> Show Progression</v-btn></div>
		<div v-if="$vuetify.breakpoint.xs&&show_expanded_full_statement"> <!-- show notes/supplementalNotes in xs-sized window when the full statement is expanded -->
			<div v-if="marked_notes">
				<div class="k-case-tree-item-details-notes-header">Notes</div>
				<div style="margin-left:6px; margin-right:6px" v-html="marked_notes"></div>
			</div>
			<div v-if="marked_exemplar">
				<div class="k-case-tree-item-details-notes-header">{{home_framework_record.ss_framework_data.exemplar_label?home_framework_record.ss_framework_data.exemplar_label:'Supplemental Information'}}</div>
				<div style="margin-left:6px; margin-right:6px" v-html="marked_exemplar"></div>
			</div>
		</div>
		<CASEItemAssociations v-if="show_associations" :item="item" :home_framework_record="home_framework_record" :framework_record="framework_record" :viewer="viewer" :crosswalk="crosswalk" />
	</div></v-hover>

	<div v-if="node_is_rendered && !item_is_document">
		<div v-show="node_is_open&&item.children.length>0" class="k-case-tree-item-children">

			<draggable v-bind="viewer.item_move_drag_options" v-model="draggable_children" @end="show_move_fn" :data-move-parent-key="item.tree_key">
				<CASEItem v-for="(child_item, index) in item.children" v-if="overflow_child_count_limit==-1||index<overflow_child_count_limit||overflow_children_showing" :key="child_item.tree_key" :home_framework_record="home_framework_record" :item="child_item" :viewer="viewer" :open_nodes_override="open_nodes_override" :featured_node_override="featured_node_override" :chosen_items="chosen_items" :show_checkbox_fn="show_checkbox_fn" :show_move_fn="show_move_fn" :show_chooser_fn="show_chooser_fn" :highlighted_identifier_override="highlighted_identifier_override" :crosswalk="crosswalk"/>
			</draggable>
			<div v-if="overflow_child_count_limit!=-1&&!overflow_children_showing" class="text-center mt-1 mb-2"><v-btn x-small color="primary" @click="overflow_children_showing=true">Show {{item.children.length-overflow_child_count_limit}} more items</v-btn></div>
		</div>

	</div>
	<div v-if="show_leaf_child_catcher&&item.children.length==0" class="k-case-tree-item-children">
		<draggable class="k-case-tree-leaf-drag-area" v-bind="viewer.item_move_drag_options" v-model="draggable_children" @end="show_move_fn" :data-move-parent-key="item.tree_key">
			<div>Drag here to add children to this item</div>
		</draggable>
	</div>

</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CopyBtn from '../utilities/CopyBtn'
import CASEItem from './CASEItem'
import CASEItemTile from './CASEItemTile'
import CASEItemAssociations from './CASEItemAssociations'
import draggable from 'vuedraggable'

export default {
	name: 'CASEItem',
	components: { CopyBtn, CASEItem, CASEItemTile, CASEItemAssociations, draggable },
	props: {
		item: { type: Object, required: true },
		home_framework_record: { type: Object, required: true },
		viewer: { required: false, default() { return {} }},
		show_checkbox_fn: { required: false, default() { return false }},
		show_move_fn: { required: false, default() { return false }},
		show_chooser_fn: { required: false, default() { return false }},
		// this can be used to "highlight" a certain item when not in the viewer context (i.e. when showing a tree on the right to highlight the item associated with a certain item on the left tree)
		highlighted_identifier_override: { type: String, required: false, default() { return '' }},
		// this can be used to control which nodes are open when not in the viewer context
		open_nodes_override: { required: false, default() { return null }},
		featured_node_override: { required: false, default() { return '' }},
		chosen_items: { required: false, default() { return [] }},
		crosswalk: { required: false, default() { return null }},
	},
	data() { return {
		node_is_rendered: false,
		node_is_open: false,
		node_is_active_local: false,
		node_is_chosen_local: false,
		checkbox_value_local: false,
		show_leaf_child_catcher: false,
		track_change_icon_rotation: 0,

		// if we have tons (e.g. > 300) of items in a folder, it can take a long time to open them all and (especially) collapse them all, so we start by showing 100 and then reveal more when the user clicks a button to do so
		overflow_children_showing: false,
	}},
	computed: {
		...mapState(['framework_records', 'comments', 'embedded_mode', 'embedded_mode_chooser']),
		...mapGetters(['comments_hash', 'signed_in', 'show_identifiers_in_tiles']),

		in_viewer_context() { return !empty(this.viewer.lsdoc_identifier) },

		framework_record() {
			if (!this.in_viewer_context) return this.home_framework_record

			// framework A may have associations with objects in framework B. if so, the user can choose to show framework B's tree if we're in the viewer context
			// so CASETree receives the home_framework_record (framework A), then determines which tree to show based on document_identifier_showing_in_tree
			return this.framework_records.find(x=>x.lsdoc_identifier == this.home_framework_record.document_identifier_showing_in_tree)
		},
		viewing_home_framework() { return this.framework_record == this.home_framework_record },

		basic_search_tree_key_hash() { return this.viewer.basic_search_tree_key_hash ? this.viewer.basic_search_tree_key_hash : {} },
		search_result_highlighted_tree_key() { return this.viewer.search_result_highlighted_tree_key ? this.viewer.search_result_highlighted_tree_key : '' },
		batch_edit_mode() { return this.viewer.batch_edit_mode },
		edited_node() { return this.viewer.edited_node },
		editing_document() { return this.viewer.editing_document },
		batch_editing() { return this.viewer.show_checkbox_fn != false },
		showing_associated_item_tree() { return this.viewer.association_framework_tree_identifier != null },
		moving() { return this.viewer.show_move_fn != false },
		stats_mode() { return this.viewer.stats_mode },
		maximized() { return this.viewer.maximized },
		show_move_node_conversion() { return this.viewer.show_move_node_conversion },
		wrap_item_text() { return this.viewer.wrap_item_text },

		making_associations() { return this.viewer.current_editor && this.viewer.current_editor.make_association },	// this is pretty hackish
		aligning_resources() { return this.viewer.current_editor && this.viewer.current_editor.make_alignment },	// this is pretty hackish

		item_should_be_displayed() {
			// the document should always be displayed
			if (this.item_is_document) return true

			// if this is a supplemental branch and we're not viewing vext case, hide the item
			if (this.item.cfitem.extensions.isSupplementalItem == true && this.$store.state.case_version_displayed != 'vext') return false

			// if the item has 'isPrivateItem'='true' and the user isn't a designated viewer of the framework, hide the item
			if (this.item.cfitem.extensions.isPrivateItem == 'true' && !vapp.is_granted('review_framework', this.framework_record.lsdoc_identifier)) return false

			if (this.selected_items && this.framework_record.limit_to_selected_items) {
				// if limit_to_selected_items is 'only' or 'children', trace up from each selected_node; 
				// if we find this.item, then the item we're currently showing is an ancestor of this selected node, so display it
				for (let node of this.viewer.selected_nodes) {
					while (node && node.tree_key > 1) {
						if (node == this.item) return true
						node = node.parent_node
					}
				}

				// if limit_to_selected_items isn't 'only' (that is, if it's 'children' or 'false'), trace up from this.item.parent (we would have found this.item above); 
				// if we hit a selected node, then this.item is a descendant of this selected node, so display it
				if (this.framework_record.limit_to_selected_items != 'only') {
					let node = this.item.parent_node
					while (node && node.tree_key > 1) {
						if (this.viewer.selected_nodes.includes(node)) return true
						node = node.parent_node
					}
				}

				// if we get to here return false -- don't display the item
				return false
			}

			return true
		},

		show_hide_all_btn_text() {
			if (!this.item_is_document) return false
			if (!this.selected_items) return false
			if (this.framework_record.limit_to_selected_items) return 'Show All Items'
			else return 'Show Selected Items Only'
		},
		show_hide_all_btn_tooltip() {
			if (this.show_hide_all_btn_text == 'Show All Items') return 'Currently showing selected items only.<br>Click to show all items'
			else return 'Currently showing all items.<br>Click to show selected items only'
		},

		active_node() { return this.framework_record.active_node },
		chosen_node() { return this.framework_record.chosen_node },
		open_nodes() {
			if (this.open_nodes_override) return this.open_nodes_override
			else return this.framework_record.open_nodes
		},
		featured_node() {
			if (this.featured_node_override) return this.featured_node_override
			else return this.framework_record.featured_node
		},
		last_clicked_node() { return this.framework_record.last_clicked_node },
		checked_items() { return this.framework_record.checked_items },

		node_btn_style() {
			if (this.node_is_open) return 'transform:rotate(90deg)'
		},
		item_is_document() { return empty(this.item.parent_node) },
		node_is_active() {
			// if we're embedded in the full viewer, look at active_node -- only one item is active at a time
			if (this.in_viewer_context) return (this.active_node == this.item.tree_key)

			// if not in the full viewer (e.g. in the associations viewer, particularly when viewing a progression), if the item is a leaf and the node is marked as open, always make the leaf active so we see the full text of the item
			if (this.item_is_leaf && this.node_is_open) return true
			
			// otherwise use node_is_active_local
			return this.node_is_active_local || this.available_in_crosswalk
		},
		// node_is_chosen() {
		// 	// if we're embedded in the full viewer and we're viewing the home framework, look at chosen_node; otherwise look at node_is_chosen_local
		// 	if (this.in_viewer_context) return (this.chosen_node == this.item.tree_key)
		// 	else return this.node_is_chosen_local
		// },
		node_is_checked: {
			get() {
				if (this.in_viewer_context) return (this.checked_items[this.item.tree_key+''] == true)
				else return this.checked_value_local
			},
			set(val) {
				if (this.in_viewer_context) this.$store.commit('set', [this.checked_items, this.item.tree_key+'', val])
				else this.checked_value_local = val
			},
		},
		descendant_or_ancestor_of_featured() {
			if (!this.featured_node) return false

			// if this is the featured node itself, true
			if (this.featured_node == this.item.tree_key) return true

			// if this is a descendant of the featured node, true
			let node = this.item.parent_node
			while (node) {
				if (this.featured_node == node.tree_key) return true
				node = node.parent_node
			}

			// if this is an ancestor of the featured node, true
			node = this.framework_record.cfo.tree_nodes_hash[this.featured_node]
			while (node) {
				if (this.item.tree_key == node.tree_key) return true
				node = node.parent_node
			}

			return false
		},
		descendant_or_ancestor_of_last_clicked() {
			if (!this.last_clicked_node) return false

			// if this is the last-clicked node itself, true
			if (this.last_clicked_node == this.item.tree_key) return true

			// if this is a descendant of the last-clicked node, true
			let node = this.item.parent_node
			while (node) {
				if (this.last_clicked_node == node.tree_key) return true
				node = node.parent_node
			}

			// if this is an ancestor of the last-clicked node, true
			node = this.framework_record.cfo.tree_nodes_hash[this.last_clicked_node]
			while (node) {
				if (this.item.tree_key == node.tree_key) return true
				node = node.parent_node
			}

			return false
		},
		item_is_leaf() {
			return this.item.children.length == 0
		},
		item_is_node() {
			return !this.item_is_leaf
		},
		item_is_last_clicked() {
			// when not in the viewer context
			if (!this.in_viewer_context) {
				// use the last_clicked style if the node's identifier matches highlighted_identifier_override
				if (this.item.cfitem.identifier == this.highlighted_identifier_override) return true
				return false
			}
			return this.last_clicked_node == this.item.tree_key
		},
		human_coding_scheme() {
			if (this.show_tracked_changes && this.track_changes_fields.humanCodingScheme && this.track_changes_fields.highlight_text_diffs) {
				let s = this.tracked_change_entities.current.humanCodingScheme ?? ''
				let archived = this.tracked_change_entities.archived.humanCodingScheme ?? ''
				let diff = U.diff_string(archived, s)
				return (this.in_viewer_context) ? diff.n : diff.o

			} else if (this.compare_to_item) {
				let s1 = this.item.cfitem.humanCodingScheme ?? ''
				let s2 = this.compare_to_item.humanCodingScheme ?? ''
				let diff = this.in_viewer_context ? U.diff_string(s1, s2, 'char_compare') : U.diff_string(s2, s1, 'char_compare')
				return (this.in_viewer_context) ? diff.o : diff.n

			} else {
				return this.highlight_search_terms(this.item.cfitem.humanCodingScheme)
			}
		},
		tree_statement() {
			// if we need mathjax for latex translation and it's not ready, return; the computed will re-run once mathjax is ready
			if (this.$store.state.mathjax_initialized == 'started') return ''

			// note that a similar fn is in CASEItemTile as well
			if (this.crosswalk && this.crosswalk.show_diff_coloring) {
				if (this.crosswalk_revealed_node) {
					if (this.crosswalk.crosswalk_lsdoc_identifiers[0] == this.home_framework_record.lsdoc_identifier && this.crosswalk.tree_right_revealed_node) {
						let diff = U.diff_string(this.item.cfitem.fullStatement, this.crosswalk.tree_right_revealed_node.cfitem.fullStatement)
						return diff.o
					}if (this.crosswalk.crosswalk_lsdoc_identifiers[1] == this.home_framework_record.lsdoc_identifier && this.crosswalk.tree_left_chosen_node) {
						let diff = U.diff_string(this.crosswalk.tree_left_chosen_node.cfitem.fullStatement, this.item.cfitem.fullStatement)
						return diff.n
					}
				}
			}

			// show abbreviatedStatement if we're in the viewer context and we're not showing the expanded full statement
			// let show_abbrev = this.in_viewer_context && !empty(this.item.cfitem.abbreviatedStatement) && !this.show_expanded_full_statement
			// NO: showing the abbreviated statement in some situations but not others seems confusing; cancel this
			let show_abbrev = false
			if (this.show_tracked_changes && this.track_changes_fields.fullStatement && this.track_changes_fields.highlight_text_diffs) {
				let s, archived

				if (this.item_is_document) {
					s = this.tracked_change_entities.current.title
					archived = this.tracked_change_entities.archived.title
				} else {
					s = ((show_abbrev) ? this.tracked_change_entities.current.abbreviatedStatement : this.tracked_change_entities.current.fullStatement) ?? ''
					archived = ((show_abbrev) ? this.tracked_change_entities.archived.abbreviatedStatement : this.tracked_change_entities.archived.fullStatement) ?? ''

					if (this.show_expanded_full_statement || this.wrap_item_text) {
						// preserve latex here; then do diff_string, then render latex below
						s = marked(U.preserve_latex(s))
						archived = marked(U.preserve_latex(archived))
					} else {
						s = markedInline(s)
						archived = markedInline(archived)
					}
				}

				let flags = 'preserve_tags preserve_latex'
				if (this.track_changes_fields.loose_comparisons) flags += ' alphanum_only'
				let diff = U.diff_string(archived, s, flags)
				// show the new value if we're on the left side, or the old value on the right
				diff = (this.in_viewer_context) ? diff.n : diff.o

				if (this.show_expanded_full_statement || this.wrap_item_text) diff = U.render_latex(U.preserve_latex_reverse(diff))

				return diff

			} else if (this.compare_to_item && !this.item_is_document) {
				// preserve latex here; then do diff_string, then render latex below
				let s1 = marked(U.preserve_latex(this.item.cfitem.fullStatement))
				let s2 = marked(U.preserve_latex(this.compare_to_item.fullStatement))
				let diff = this.in_viewer_context ? U.diff_string(s1, s2, 'preserve_tags') : U.diff_string(s2, s1, 'preserve_tags')
				diff = (this.in_viewer_context) ? diff.o : diff.n
				return U.render_latex(U.preserve_latex_reverse(diff))

			} else {
				// for the document, return the doc title
				if (this.item_is_document) return this.item.cfitem.title

				let s = ((show_abbrev) ? this.item.cfitem.abbreviatedStatement : this.item.cfitem.fullStatement) ?? ''

				// // kill uls and lis (though there really shouldn't be any uls or lis...)
				// s = s.replace(/<\/*(ul|ol|li).*?>/g, ' ')

				if (this.show_expanded_full_statement || this.wrap_item_text) {
					s = U.marked_latex(s)
				} else {
					s = markedInline(s)
				}

				return this.highlight_search_terms(s)
			}
		},
		marked_notes() {
			// if we need mathjax for latex translation and it's not ready, return; the computed will re-run once mathjax is ready
			if (this.$store.state.mathjax_initialized == 'started') return ''

			if (this.item_is_document || !this.item.cfitem.notes) return ''
			let s = U.marked_latex(this.item.cfitem.notes)
			s = this.highlight_search_terms(s, 'notes')

			// make sure all links open in a new window (though notes really shouldn't have links; note that for exemplars froala should ensure _blank is included)
			s = s.replace(/<a /g, '<a target="_blank" ')

			return s	
		},
		marked_exemplar() {
			// if we're not showing vext CASE, don't show exemplars
			if (this.$store.state.case_version_displayed != 'vext') return ''
			if (!this.item.cfitem.extensions.supplementalNotes) return ''

			// if we need mathjax for latex translation and it's not ready, return; the computed will re-run once mathjax is ready
			if (this.$store.state.mathjax_initialized == 'started') return ''

			// note that examplars can either use html or markdown; use marked if we don't have any html tags
			// also note that if the author uses latex, they shouldn't also try to use markdown, because the latex renderer will render the latex as html, so marked() won't be run here
			let exs = this.item.cfitem.extensions.supplementalNotes
			if (exs.search(/<\w+[^<]*>/) == -1) exs = U.marked_latex(exs)
			else exs = U.render_latex(exs)
			exs = this.highlight_search_terms(exs, 'exemplar')
			return exs

			// // if exemplar includes <hr>s, convert the line after each <hr> to an expandable div
			// let arr = exs.split('<hr>')
			// if (arr.length == 1) {
			// 	// no blocks; just return exs
			// 	return exs

			// } else {
			// 	// start with whatever was in arr[0] (which might be an empty string)
			// 	let html = arr[0].replace(/(<br>)+$/, '')

			// 	// for each additional block
			// 	for (let i = 1; i < arr.length; ++i) {
			// 		// try to extract the first line, which is a little tricky because it might be a paragraph, or it might be a blank line separated by a <br> or a <ol> or <ul>
			// 		let header, footer
			// 		if (arr[i].search(/^\s*<p>(.*?)<\/p>([\s\S]*)/) > -1) {
			// 			header = RegExp.$1
			// 			footer = RegExp.$2
			// 		} else if (arr[i].search(/^(.*?)((<br|<ol|<ul)[\s\S]*)/) > -1) {
			// 			header = RegExp.$1
			// 			footer = RegExp.$2
			// 		}

			// 		if (header && footer) {
			// 			// remove leading <br> from footer if there (that br would be the end of the header line)
			// 			footer = footer.replace(/^<br>/, '')

			// 			let block_id = U.new_uuid()
			// 			html += sr('<div class="k-exemplar-block-header" data-header-id="$1" data-block-showing="yes"><i class="fas fa-caret-right"></i> $2</div><div class="k-exemplar-block-body" data-block-id="$1">$3</div>', block_id, header, footer)

			// 			this.$nextTick(x=>{
			// 				$('[data-header-id=' + block_id + ']').click(function() {
			// 					let jq = $(this)
			// 					let block_id = jq.attr('data-header-id')
			// 					let showing = jq.attr('data-block-showing')
			// 					if (showing == 'no') {
			// 						$('[data-block-id=' + block_id + ']').slideDown()
			// 						jq.find('.fas').removeClass('fa-caret-right').addClass('fa-caret-down')
			// 						jq.attr('data-block-showing', 'yes')
			// 					} else {
			// 						$('[data-block-id=' + block_id + ']').slideUp()
			// 						jq.find('.fas').removeClass('fa-caret-down').addClass('fa-caret-right')
			// 						jq.attr('data-block-showing', 'no')
			// 					}
			// 				})
			// 			})

			// 		} else {
			// 			html += sr('<div class="k-exemplar-block-body-no-header">$1</div>', arr[i])
			// 		}
			// 	}

			// 	return html
			// }
		},
		draggable_children: {
			get() { return this.item.children },
			set(new_children_array) {
				// console.log('setting draggable_children', new_children_array)
				// // this.$store.commit('item_moved', [this.cftree, new_children_array])
				// this.$store.commit('set', [this.cftree, 'children', new_children_array])
			}
		},
		show_chooser_btn() {
			if (this.embedded_mode && this.item_is_document) return false
			return this.show_chooser_fn && this.edited_node != this.item
		},
		clear_checkboxes_trigger() { return this.framework_record.clear_checkboxes_trigger },
		show_associations_count() {
			// only show association counts when we're in the viewer context
			if (!this.in_viewer_context) return false
			// if we're in the viewer context and the viewer's show_associations flag is off, return false
			if (this.in_viewer_context && !this.viewer.show_associations) return false
			// no need to show this for the document
			if (this.item_is_document) return false
			return this.$store.state.lst.show_associations && this.association_count > 0
		},
		show_comments_count() {
			// if you're not signed in and public_review isn't on, there can't be any comments to show
			if (!this.signed_in && !this.viewer.public_review_on) return false
			if (!this.in_viewer_context) return false
			if (this.embedded_mode) return false
			return this.$store.state.lst.show_comments && this.comment_count > 0
		},
		show_associations() {
			// show if this item is in a left crosswalk tree
			if (this.crosswalk && this.crosswalk.crosswalk_lsdoc_identifiers[0] == this.home_framework_record.lsdoc_identifier) return true

			// if show_associations is off globally or in the viewer, don't show associations!
			if (!this.$store.state.lst.show_associations) return false
			if (this.in_viewer_context && !this.viewer.show_associations) return false

			// don't show associations when we're *not* in the viewer context (?)
			if (!this.in_viewer_context) return false

			// only show associations in the CASEitem component (i.e. in the tree) when the node is active
			if (!this.node_is_active) return false

			// if we're making new associations or aligning resources, show existing associations here
			if (this.making_associations) return true
			if (this.aligning_resources) return true
			// and if we're not in the viewer context, show existing associations here
			if (!this.in_viewer_context) return true

			// if we're in the viewer context (which we are if we get to here) and we're showing_associated_item_tree, show associations here because the tile isn't visible
			if (this.showing_associated_item_tree) return true

			// if we get to here, don't show associations
			return false

			// node_is_active&&(!in_viewer_context||making_associations)
		},
		show_tile() {
			// only show the tile in the item div (i.e. in the tree) when the node is active
			if (!this.node_is_active) return false

			// if active, always show the tile when we're on a phone-sized screen??
			// NOTE: this has been commented and uncommented... 
			// if we *don't* show the tile for the active item on a phone-sized screen, it's hard to see the item if it's indented a few levels; and also the associations won't appear
			// on the other hand, if we do see the tile for the active item on a phone-sized screen, the clicked standard may take over too much of the screen; and you can always click the expand button to see the whole thing, including assocs
			// if (this.$vuetify.breakpoint.xs) return true

			// don't show when not in the viewer context, or when batch editing
			if (!this.in_viewer_context) return false
			if (this.batch_editing) return false

			// show if main viewer isn't maximized
			if (!this.maximized) return true

			// show if making associations (?)
			// if (this.making_associations) return true

			// show if aligning resources (?)
			// if (this.aligning_resources) return true

			return false

			// if we get to here, show only if the main viewer isn't maximized
			return !this.maximized
		},
		show_expanded_full_statement() {
			// always show the full statement (not the abbreviated statement), and don't wrap...
			// if (we're not in the context of the full viewer, or we're batch editing, or we're showing_associated_item_tree or making associations or aligning resources) 
			if (!this.in_viewer_context || this.batch_editing || this.showing_associated_item_tree || this.making_associations || this.aligning_resources || this.$vuetify.breakpoint.xs) {
				// and if the node is active (or marked as last-clicked?)
				// if (this.node_is_active || this.item_is_last_clicked) return true
				if (this.node_is_active) return true

				// or if this is the highlighted_identifier_override item (used when making associations or alignments)
				if (this.item.cfitem.identifier == this.highlighted_identifier_override) return true
			}
			return false
		},
		
		// associations for this item itself + children
		association_count() {
			return this.home_framework_record.cfo.displayed_association_counts_hash[this.item.cfitem.identifier]
		},
		self_association_count() {
			if (empty(this.home_framework_record.cfo.displayed_associations_hash[this.item.cfitem.identifier])) return 0
			// don't "double count" assocs to/from the same items (e.g. a relatedTo and an copiedFromSource or aliasOf
			// if (this.item.cfitem.identifier == 'a42711b9-2895-4faf-bb35-55e8dafb9eaa') debugger
			let count = 0
			let other_identifiers = ''
			for (let assoc of this.home_framework_record.cfo.displayed_associations_hash[this.item.cfitem.identifier]) {
				let other_identifier = assoc.destinationNodeURI.identifier
				if (other_identifier == this.item.cfitem.identifier) other_identifier = assoc.originNodeURI.identifier
				if (other_identifiers.indexOf(other_identifier) == -1) {
					++count
					other_identifiers += '+' + other_identifier
				}
			}
			return count
		},

		self_comment_count() {
			if (this.comments_hash[this.framework_record.lsdoc_identifier] && this.comments_hash[this.framework_record.lsdoc_identifier][this.item.cfitem.identifier]) {
				return this.comments_hash[this.framework_record.lsdoc_identifier][this.item.cfitem.identifier].length
			}
			return 0
		},
		comment_count() {
			let get_comment_count = (node) => {
				let count = 0
				if (this.comments_hash[this.framework_record.lsdoc_identifier] && this.comments_hash[this.framework_record.lsdoc_identifier][node.cfitem.identifier]) {
					count += this.comments_hash[this.framework_record.lsdoc_identifier][node.cfitem.identifier].length
				}
				for (let i = 0; i < node.children.length; ++i) {
					count += get_comment_count(node.children[i])
				}
				return count
			}

			return get_comment_count(this.item)
		},

		comp_score_color() {
			if (this.item.comp_score_highlighted) return 'green'
			else return 'grey'
		},

		tracking_changes() {
			return vapp.case_tree_component && vapp.case_tree_component.tracking_changes
		},
		tracked_change_entities() {
			if (!this.tracking_changes) return {}
			return U.get_archive_comparison_entities(this.item.cfitem.identifier, vapp.case_tree_component.framework_record, vapp.case_tree_component.track_changes_framework_record)
		},
		show_tracked_changes() {
			// if we're tracking changes and there's an item on both sides, highlight the difference between current and archive; otherwise we just show what's in this.item
			return this.tracking_changes && this.tracked_change_entities.current.identifier && this.tracked_change_entities.archived.identifier
		},
		tracked_change_icon() {
			if (!this.tracking_changes) return ''

			let other_framework_record
			if (this.in_viewer_context) {
				other_framework_record = vapp.case_tree_component.track_changes_framework_record
			} else {
				other_framework_record = vapp.case_tree_component.framework_record
			}

			return U.tracked_change_icon(this.tracked_change_entities, this.item, other_framework_record)
		},
		track_changes_fields() { return this.$store.state.lst.track_changes_fields },

		compare_to_item() {
			// if we're on the left side,...
			if (this.in_viewer_context) {
				// if this node is active, we're in the associations editor, and a suggested item has been highlighted on the right, compare to the suggested item
				if (this.node_is_active) {
					let identifier = this.viewer.$refs.make_associations_editor?.highlighted_identifier_override
					if (identifier) {
						let rfr = this.viewer.$refs.make_associations_editor?.right_framework_record
						if (rfr) return rfr?.cfo.cfitems[identifier]
					}
				}
			
			// else we're on the right side...
			} else {
				// if we're making associations...
				if (vapp.case_tree_component?.$refs.make_associations_editor) {
					// if this is a suggested item that has been highlighted, compare to the item on the left
					let identifier = vapp.case_tree_component?.$refs.make_associations_editor?.highlighted_identifier_override
					if (identifier == this.item.cfitem.identifier) {
						return vapp.case_tree_component.$refs.make_associations_editor?.left_node?.cfitem
					}

				// else if we're showing a progression...
				} else if (vapp.case_tree_component?.association_framework_tree_associationType == 'precedes') {
					// and highlight_progression_changes is true
					if (this.$store.state.lst.highlight_progression_changes) {
						// if this is a top-level item and it isn't the first item in the progression, compare to the previous item in the progression
						let i = this.framework_record.cfo.cftree.children.findIndex(x=>x==this.item)
						if (i > 0) return this.framework_record.cfo.cftree.children[i-1].cfitem
					}
				}
			}
			return null
		},

		// for embedded mode
		selected_items() { return this.viewer.selected_items },
		item_is_selected() { 
			if (!this.selected_items) return false
			// select on the basis of selected_nodes, which is calculated in this.viewer
			return this.viewer.selected_nodes.includes(this.item)
		},
		selected_item_icon_style() {
			let s = 'font-size:9px!important; margin-left:8px; margin-top:-1px;'
			if (this.item_is_document) return s + 'visibility:hidden;'
			// if (!this.item_is_selected) return s + 'opacity:0.2;'
			if (!this.item_is_selected) return s + 'visibility:hidden;'
			return s + 'opacity:0.7;'
		},

		progression_table_available() { return U.progression_table_available(this.framework_record, this.item) },

		available_in_crosswalk() {
			if (this.crosswalk == null) return true
			if (this.home_framework_record.lsdoc_identifier == this.crosswalk.crosswalk_lsdoc_identifiers[0]) {
				return this.$store.state.crosswalk_available_left_items[this.crosswalk.lst_key]?.includes(this.item.cfitem.identifier)
			}
			if (this.home_framework_record.lsdoc_identifier == this.crosswalk.crosswalk_lsdoc_identifiers[1]) {
				// console.log("COMPUTING" + Date.now())
				return this.crosswalk//_tree_items.right_hash[this.item.cfitem.identifier] != undefined
			}
		},
		crosswalk_revealed_node() {
			if (!this.crosswalk) return false

			let revealed
			if (this.home_framework_record.lsdoc_identifier == this.crosswalk.crosswalk_lsdoc_identifiers[1]) {
				revealed = this.item.tree_key == this.$store.state.crosswalk_revealed_right_item[this.crosswalk.lst_key]
			}
			else {
				revealed = this.item.tree_key == this.$store.state.crosswalk_revealed_left_item[this.crosswalk.lst_key]
			}
			return revealed
		},
		crosswalk_chosen_node() {
			if (!this.crosswalk) return false

			if (this.home_framework_record.lsdoc_identifier == this.crosswalk.crosswalk_lsdoc_identifiers[1]) {
				return this.item.tree_key == this.$store.state.crosswalk_chosen_right_item[this.crosswalk.lst_key]
			}
			else {
				return this.item.tree_key == this.$store.state.crosswalk_chosen_left_item[this.crosswalk.lst_key]
			}
		},
		crosswalk_association_type() {
			if (!this.crosswalk || !this.crosswalk.tree_left_chosen_node) return undefined
			let associations = this.crosswalk.crosswalk_framework_record.cfo.associations_hash[this.crosswalk.tree_left_chosen_node.cfitem.identifier]
			if (!associations) return undefined
			let matching_association
			for (let association of associations) {
				if (association.destinationNodeURI.identifier == this.item.cfitem.identifier) {
					matching_association = association
					break
				}
				if (association.originNodeURI.identifier == this.item.cfitem.identifier) {
					matching_association = association
					break
				}
			}
			if (!matching_association) return undefined
			if (matching_association.associationType == "exactMatchOf") return "exact"
			if (matching_association.associationType == "ext:isNearExactMatch") return "nearexact"
			if (matching_association.associationType == "ext:isCloselyRelatedTo") return "close"
			if (matching_association.associationType == "ext:isModeratelyRelatedTo") return "moderate"
			if (matching_association.associationType == "isRelatedTo") return "moderate"
			return undefined

		},
		crosswalk_associated_item_identifier() {
			if (!this.crosswalk || !this.crosswalk.tree_left_chosen_node) return undefined
			let associations = this.crosswalk.crosswalk_framework_record.cfo.associations_hash[this.crosswalk.tree_left_chosen_node.cfitem.identifier]
			if (!associations) return undefined
			for (let association of associations) {
				if (association.destinationNodeURI.identifier == this.item.cfitem.identifier) {
					return association.originNodeURI.identifier
				}
				if (association.originNodeURI.identifier == this.item.cfitem.identifier) {
					return association.destinationNodeURI.identifier
				}
			}
			return undefined
		},
		overflow_child_count_limit() {
			// if we have < X items, just show them all
			if (this.item.children.length < 100) return -1
			// else limit to only the first Y items
			else return 50
		},
	},
	watch: {
		open_nodes: {immediate: true, handler(val) {
			// if we're not in the full viewer, we'll calculate node_is_open below
			if (!this.in_viewer_context) return

			// else use this watcher, along with node_is_rendered and node_is_open, to only show children when we need to (this greatly improves performance)
			// note that if the item is the ancestor
			if (this.open_nodes[this.item.tree_key]) {
				if (!this.node_is_rendered) {
					this.node_is_rendered = true
				}
				this.node_is_open = true
			} else {
				this.node_is_open = false
				// NOTE: prior to 6/25/2024, we were setting node_is_rendered to false whenever we set node_is_open to false. 
				// This makes collapsing extremely slow, because it triggers un-opened items' CASEItem components to be destroyed, which seems to take a long time
				// but it seems like I must have been setting it to false for some reason...
				// this.node_is_rendered = false
			}
		}},
		open_nodes_override: {immediate: true, handler(val) {
			if (!this.open_nodes_override) return

			// if this is set, use it to calculate node_is_open when the component is first rendered, and/or when open_nodes_override changes
			if (this.open_nodes_override[this.item.tree_key]) {
				if (!this.node_is_rendered) {
					this.node_is_rendered = true
				}
				this.node_is_open = true
			} else {
				this.node_is_open = false
				// this.node_is_rendered = false
			}
		}},
		item_is_last_clicked: {immediate: true, handler(val) {
			if (val) {
				// store the component for the item that has had its last_clicked_item_component value most recently changed
				// this is used in CASEFrameworkViewer -> switch_associated_item()
				vapp.last_clicked_item_component = this
			}
		}},
		clear_checkboxes_trigger() {
			// this is a hackish way to clear checkboxes when we're not in viewer mode
			if (this.clear_checkboxes_trigger == true) this.checked_value_local = false
		},
		highlighted_identifier_override: {immediate: true, handler(val) {
			// if this is a parent of the highlighted item, we want to start with this node open
			if (!this.highlighted_identifier_override) return

			if (!this.framework_record.cfo.cfitems[this.highlighted_identifier_override]) return

			// but if open_nodes are explicitly specified, don't do this, because we may not want to open every node where the highlighted item appears in the tree
			if (!this.in_viewer_context) {
				if (this.open_nodes_override && Object.keys(this.open_nodes_override).length > 0) {
					return
				}
			} else {
				if (this.open_nodes && Object.keys(this.open_nodes).length > 0) {
					return
				}
			}

			let is_parent = false
			// look through all nodes for the highlighted_item
			let highlighted_item = this.framework_record.cfo.cfitems[this.highlighted_identifier_override]
			for (let highlighted_tree_node of highlighted_item.tree_nodes) {
				// use a while loop to look for this item in this node's parents
				let tree_node = highlighted_tree_node.parent_node
				while (tree_node) {
					if (tree_node.cfitem.identifier == this.item.cfitem.identifier) {
						is_parent = true
						break
					}
					tree_node = tree_node.parent_node
				}
				if (is_parent) break
			}

			if (is_parent) {
				this.node_is_rendered = true
				this.node_is_open = true
			} else {
				// this.node_is_rendered = false
				this.node_is_open = false
			}
		}},
		show_move_fn() {
			// when the move_fn is reset, cancel show_leaf_child_catcher
			this.show_leaf_child_catcher = false
		},
	},
	created() {
		// console.warn('item created')
		// if highlighted_identifier_override is set
		if (this.highlighted_identifier_override) {

		// else if we're not in the viewer context and open_nodes_override isn't set, start with anything open here that's open in the viewer itself
		} else if (!this.in_viewer_context && !this.open_nodes_override) {
			if (this.open_nodes[this.item.tree_key]) {
				this.node_is_rendered = true
				this.node_is_open = true
			}
		}
	},
	mounted() {
	},
	beforeDestroy() {
		// console.warn('item beforeDestroy')
	},
	destroyed() {
		// console.warn('item destroyed')
	},
	methods: {
		item_outer_css() {
			let s = ''

			// if we're *not* showing the tile inline, then add the last-clicked style if appropriate
			if (!this.show_tile) {
				if (this.item_is_last_clicked || (this.in_viewer_context && this.node_is_active) || this.crosswalk_revealed_node) {
					s += ' k-case-tree-item-last-clicked'
				}
			}

			// apply k-case-tree-item-descendant-or-ancestor-of-featured if appropriate
			if (this.descendant_or_ancestor_of_featured) s += ' k-case-tree-item-descendant-or-ancestor-of-featured'

			if (this.in_viewer_context && this.viewer.new_imported_item_identifiers.find(x=>x==this.item.cfitem.identifier)) {
				s += ' k-case-tree-new-imported-item'
			}

			if (this.item.tree_key == this.viewer.move_node_shift_right) s += ' k-case-tree-item-shifted-right-for-move'

			return s
		},

		item_css(hover) {
			let s = ''

			if (this.item_is_document) {
				s += ' k-case-tree-item-document'
			}

			if (this.selected_items) {
				s += ' k-case-tree-item-embedded-mode-selected-items'
			}

			if (this.wrap_item_text) {
				s += ' k-case-tree-item-wrapped'
			}

			// if (!empty(this.edited_node)) {
			// 	if (this.edited_node.tree_key == this.item.tree_key) {
			// 		s += ' k-case-tree-item-being-edited'
			// 	} else {
			// 		s += ' k-case-tree-item-shifted'
			// 	}
			// }
			
			// don't do this anymore, because it screws with the item type coding
			// if (this.basic_search_tree_key_hash[this.item.tree_key]) {
			// 	s += ' k-case-tree-item-search-match'
			// }

			if (this.search_result_highlighted_tree_key == this.item.tree_key && this.node_is_active && this.item_is_last_clicked) {
				s += ' k-case-tree-searched-jumped'
			}

			if (this.item.cfitem.stats.duplicate_hovered) {
				s += ' k-case-tree-item-duplicate-hovered'
			}

			if (this.show_expanded_full_statement) {
				s += ' k-case-tree-node-show_expanded_full_statement'
			}

			if (this.item_is_leaf) {
				s += ' k-case-tree-item-leaf'
			} else {
				s += ' k-case-tree-item-node'
			}

			// show hovered class if hover is on, or if we're in the viewer context and we're hovering over a duplicate item or over the item's tile
			if (hover || (this.in_viewer_context && (this.item.cfitem.stats.duplicate_hovered || this.framework_record.hovered_tile == this.item.tree_key))) {
				if (!this.item.flashing) s += ' k-case-tree-item-hovered'
			}

			if (this.item.flashing) s += ' k-case-tree-item-flashing'

			// apply k-case-tree-item-descendant-or-ancestor-of-last-clicked if appropriate
			if (this.in_viewer_context && !this.show_move_fn) {
				if (this.descendant_or_ancestor_of_last_clicked) s += ' k-case-tree-item-descendant-or-ancestor-of-last-clicked'
				else if (this.last_clicked_node) s += ' k-case-tree-item-NOT-descendant-or-ancestor-of-last-clicked'
			}

			// apply color coding by item type, if user hasn't turned it off
			if (this.$store.state.lst.show_color_coded_item_types) {
				s += ' k-case-tree-item-type-color'
				let item_type_level = this.framework_record.cfo.item_type_levels.find(x=>x.item_type == U.item_type_string(this.item.cfitem))
				if (item_type_level) {
					s += (' k-case-tree-item-type-color-' + item_type_level.level_index)
				}
			}

			// unless ss_framework_data.ignore_status_dates is true...
			if (!this.home_framework_record.ss_framework_data.ignore_status_dates) {
				// add style for items that haven't yet reached statusStartDate, and/or have passed statusEndDate (both shouldn't be true, but that technically could happen)
				// but DON'T add the style for CFItems if the CFDocument is also in the same state -- that just makes it look confusing (we'll still show the notification on the item itself)
				// also, ignore dates < 2010; such dates almost certainly reflect incorrect usage of the fields
				if (!empty(this.item.cfitem.statusStartDate) && this.$store.state.today < this.item.cfitem.statusStartDate && this.item.cfitem.statusStartDate > '2010') {
					if (this.item_is_document || empty(this.framework_record.json.CFDocument.statusStartDate) || this.$store.state.today >= this.item.cfitem.statusStartDate) {
						s += ' k-case-tree-item-not-yet-adopted'
					}
				}
				if (!empty(this.item.cfitem.statusEndDate) && this.$store.state.today >= this.item.cfitem.statusEndDate && this.item.cfitem.statusEndDate > '2010') {
					if (this.item_is_document || empty(this.framework_record.json.CFDocument.statusEndDate) || this.$store.state.today < this.item.cfitem.statusEndDate) {
						s += ' k-case-tree-item-no-longer-adopted'
					}
				}
			}

			return s
		},

		viewer_fn(fn_name, evt) {
			this.viewer[fn_name](evt)
		},

		show_associations_count_css(hover) {
			let s = ''
			if (this.association_count == 0) s += ' k-case-tree-item-associations-count-zero'
			if (hover || this.node_is_active) s += ' k-case-tree-item-associations-count-hovered'
			return s
		},

		show_comments_count_css(hover) {
			let s = ''
			if (this.comment_count == 0) s += ' k-case-tree-item-comments-count-zero'
			if (hover || this.node_is_active) s += ' k-case-tree-item-comments-count-hovered'
			return s
		},

		chooser_style(hover) {
			if (this.item.cat) return 'font-size:16px'	// current association type
			else return 'font-size:20px'
		},
		chooser_icon(hover) {
			// marker for already-aligned item in resource alignment mode
			if (this.item.cat) return 'fas fa-check'

			// return check-square if we're in the viewer context and this is the framework's chosen_node
			if (this.in_viewer_context && this.framework_record.chosen_node == this.item.tree_key) return 'fas fa-square-check'
			// or node_is_chosen_local is set to true
			if (this.node_is_chosen_local || this.crosswalk_chosen_node) return 'fas fa-square-check'

			// if we have chosen_items or selected_items and this item's identifier is among them...
			if (this.chosen_items?.find(x=>x==this.item.cfitem.identifier) || this.selected_items?.find(x=>x==this.item.cfitem.identifier)) {
				// if hovering, return an 'x', because clicking it will remove the association; else return check
				if (hover) return 'fas fa-square-xmark'
				if (this.crosswalk_association_type == 'exact') return 'fas fa-bullseye'
				if (this.crosswalk_association_type == 'nearexact') return 'fas fa-circle-dot'
				if (this.crosswalk_association_type == 'close') return 'fas fa-circle'
				if (this.crosswalk_association_type == 'moderate') return 'fas fa-circle-half-stroke'
				else return 'fas fa-square-check'
			}

			// if we get to here show blank square
			return 'far fa-square'
		},
		chooser_color(hover) {
			// green if we're in the viewer context and this is the framework's chosen_node
			if (this.in_viewer_context && this.framework_record.chosen_node == this.item.tree_key) return 'green'
			// green if node_is_chosen_local is set to true
			if (this.node_is_chosen_local || this.crosswalk_chosen_node) return 'green'
			// green if item.cat is true
			if (this.item.cat) return 'green'

			// if we have selected_items or chosen_items and this item's identifier is among them...
			if (this.chosen_items?.find(x=>x==this.item.cfitem.identifier) || this.selected_items?.find(x=>x==this.item.cfitem.identifier)) {
				// if hovering, return red, because clicking it will remove the association; otherwise return orange
				if (hover) return 'red'
				else return 'orange'
			}

			// if we get to here, green if hovering or gray otherwise
			if (hover) return 'green'
			else return '#ccc'
		},

		highlight_search_terms(s) { return this.viewer.highlight_search_terms ? this.viewer.highlight_search_terms(s, this.item.tree_key) : s },

		more_info() { this.viewer.more_info(this.item) },
		print_view() { this.viewer.print_view(this.item) },
		clear_last_clicked_node() { if (this.viewer && this.viewer.clear_last_clicked_node) this.viewer.clear_last_clicked_node() },

		tracked_change_clicked() {
			// rotate the icon
			this.track_change_icon_rotation += 360
			// if you clicked on the icon from the left tree, show the corresponding item in the right tree
			if (this.in_viewer_context) {
				setTimeout(x=>this.viewer.show_archived_item(this.item), 100)
			
			// else you clicked on the icon from the right tree, so show the corresponding item in the left tree
			} else {
				setTimeout(x=>vapp.case_tree_component.show_current_item_from_archive(this.item), 100)
				// set node_is_open to false, then call node_btn_clicked to open this node and reveal its children on the right
				this.node_is_open = false
				this.node_btn_clicked()
			}
		},

		item_clicked(evt) {
			// console.log(this.item.cfitem.tree_nodes[0])
			if (this.crosswalk && this.crosswalk.crosswalk_lsdoc_identifiers[1] == this.home_framework_record.lsdoc_identifier) {
				this.crosswalk.show_right_item_in_tree(this.item.cfitem.identifier)
				return
			}

			// if we're not in the context of the full tree...
			if (!this.in_viewer_context) {
				// if we're viewing a sequence and this item is showing on the left, open the item on the left
				if (vapp.case_tree_component?.association_framework_tree_associationType == 'precedes') {
					vapp.case_tree_component.show_item(this.item.cfitem.identifier)
					vapp.case_tree_component.show_associated_item('precedes', this.item, this.framework_record.lsdoc_identifier, this.item.cfitem.identifier, this.item.tree_key)
					return
				}
				this.node_is_active_local = !this.node_is_active_local
				if (this.item_is_node) this.node_btn_clicked(evt)
				return
			}

			// make sure the search panel is in the back
			this.viewer.search_panel_to_back()

			// if we're in "show_move_node_conversion" mode...
			if (this.show_move_node_conversion) {
				this.viewer.finish_move_node_conversion(this.item.tree_key)
				return
			} else if (this.show_move_fn) {
				// else if we're in move mode, don't respond to item clicks (other than via clicking the node btn; see leaf_node_btn_clicked
				return
			}

			let opened, activated

			// if an editor is currently open,
			if (this.edited_node || this.editing_document) {
				// if this is the item/document being edited, just return
				if (this.edited_node == this.item || (this.editing_document && this.item_is_document)) {
					return
				}

				// else the user is currently editing a different item/document, and has clicked to another one.
				// if there aren't any changes pending in the editor, switch to editing the newly-clicked item/document
				if (this.viewer.current_editor && !this.viewer.current_editor.changes_pending) {
					if (this.item_is_document) this.viewer.edit_document()
					else this.viewer.edit_item(this.item)
				} else {
					this.$inform('Changes are pending in the item you’re currently editing. Cancel or save changes to edit another item.')
					return
				}
			}

			// if the item isn't active
			if (!this.node_is_active) {
				// we will activate it
				activated = true

				// if the item is a node and isn't already open, open it too
				if (this.item_is_node && !this.node_is_open) opened = true
			} else {
				// else the item is already active, so deactivate it
				activated = false

				// and if the item is a node, close it too
				if (this.item_is_node) opened = false
			}

			if (activated === true) {
				this.$store.commit('set', [this.framework_record, 'active_node', this.item.tree_key])
				// when we activate an item, set the starting_lsitem_identifier to the item
				this.viewer.set_starting_lsitem_identifier(this.item.cfitem.identifier, this.item.tree_key)

			} else if (activated === false) {
				// clear the active node and starting_lsitem_identifier
				this.viewer.make_node_active('', true)
			}

			if (opened === true) this.open_node(evt)	// this.$store.commit('set', [this.open_nodes, this.item.tree_key+'', true])
			else if (opened === false) this.close_node(evt)	// this.$store.commit('set', [this.open_nodes, this.item.tree_key+'', '*DELETE_FROM_STORE*'])

			// no matter what we did, set last_clicked_node, to help the user remember what they last clicked
			this.$store.commit('set', [this.framework_record, 'last_clicked_node', this.item.tree_key])
		},

		node_btn_clicked(evt) {
			// if we're not embedded in the viewer, we just look at local values
			if (!this.in_viewer_context) {
				this.node_is_open = !this.node_is_open
				if (this.node_is_open && !this.node_is_rendered) this.node_is_rendered = true

				// if open_nodes_override was provided, update it too
				if (this.open_nodes_override) {
					// note that open_nodes_override might not actually be in the store, but commit will work anyway
					if (this.node_is_open) this.$store.commit('set', [this.open_nodes, this.item.tree_key+'', true])
					else this.$store.commit('set', [this.open_nodes, this.item.tree_key+'', '*DELETE_FROM_STORE*'])
				}
				return
			}

			// if the item isn't currently open
			if (!this.node_is_open) {
				// open the node
				this.open_node(evt)
				// this.$store.commit('set', [this.open_nodes, this.item.tree_key+'', true])
			} else {
				// else close the node, and if it was active, deactivate it
				this.close_node(evt)
				// this.$store.commit('set', [this.open_nodes, this.item.tree_key+'', '*DELETE_FROM_STORE*'])

				if (this.node_is_active) {
					// clear the active node and starting_lsitem_identifier
					this.viewer.make_node_active('', true)
				}
			}
		},

		leaf_node_btn_clicked() {
			// if the node btn for a leaf node is clicked and we're in move mode, open the leaf for "catching" children
			if (this.viewer.editing_enabled && this.viewer.show_move_fn) {
				this.show_leaf_child_catcher = true
				this.$store.commit('set', [this.open_nodes, this.item.tree_key+'', true])
			}
		},

		open_node(evt) {
			if (!U.meta_or_alt_key(evt)) {
				this.$store.commit('set', [this.open_nodes, this.item.tree_key+'', true])
				this.$emit('child_opened')
			} else {
				// if meta/alt key is held down, open all siblings; note that in blob view there is no blob for the doc, so everything has a parent
				for (let node of this.item.parent_node.children) {
					this.$store.commit('set', [this.open_nodes, node.tree_key+'', true])
				}
			}
		},

		close_node(evt) {
			if (!U.meta_or_alt_key(evt)) {
				this.$store.commit('set', [this.open_nodes, this.item.tree_key+'', '*DELETE_FROM_STORE*'])
				this.$emit('child_closed')
			} else {
				for (let node of this.item.parent_node.children) {
					this.$store.commit('set', [this.open_nodes, node.tree_key+'', '*DELETE_FROM_STORE*'])
				}
			}
		},

		checkbox_clicked(evt) {
			console.log("clicked")
			// when the checkbox is clicked/changed, call the passed-in show_checkbox_fn, sending the item identifier and the new checkbox value
			// it seems like we should be able to use node_is_checked as the return value here, but that doesn't seem to work for some reason
			let val
			if (this.in_viewer_context) val = (this.checked_items[this.item.tree_key+''] == true)
			else val = this.checked_value_local

			this.show_checkbox_fn(this.item.cfitem.identifier, this.item.tree_key, val, evt)
		},

		show_chooser_fn_clicked(evt) {
			if (this.in_viewer_context) this.viewer.clear_last_clicked_node()
			this.show_chooser_fn(this, this.item, evt)
		},

		show_expanded_tile() {
			// show the expanded tile using the case_tree_component that's currently showing
			vapp.case_tree_component.show_expanded_item(this.item, this.framework_record)
		},

		toggle_show_selected_items() { this.viewer.toggle_show_selected_items() },

		show_progression_table() { vapp.case_tree_component.show_progression_table(this.framework_record, this.item) },

		//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		// "Statistics" mode

		stats_mouseover() {
			let i = this.item.cfitem

			let dup
			if (i.stats.is_duplicate_of) dup = i.stats.is_duplicate_of
			else if (i.stats.add_is_child_of_to_duplicate) dup = i.stats.add_is_child_of_to_duplicate
			else if (i.stats.possible_related_to) dup = i.stats.possible_related_to
			else if (i.stats.to_be_deleted) dup = i.stats.to_be_deleted

			if (dup) {
				this.framework_record.cfo.cfitems[dup].stats.duplicate_hovered = true
			}
		},

		stats_mouseout() {
			let i = this.item.cfitem

			let dup
			if (i.stats.is_duplicate_of) dup = i.stats.is_duplicate_of
			else if (i.stats.add_is_child_of_to_duplicate) dup = i.stats.add_is_child_of_to_duplicate
			else if (i.stats.possible_related_to) dup = i.stats.possible_related_to
			else if (i.stats.to_be_deleted) dup = i.stats.to_be_deleted

			if (dup) {
				this.framework_record.cfo.cfitems[dup].stats.duplicate_hovered = false
			}
		},

		stats_clicked() {
			let i = this.item.cfitem

			let dup
			if (i.stats.is_duplicate_of) dup = i.stats.is_duplicate_of
			else if (i.stats.add_is_child_of_to_duplicate) dup = i.stats.add_is_child_of_to_duplicate
			else if (i.stats.possible_related_to) dup = i.stats.possible_related_to
			else if (i.stats.to_be_deleted) dup = i.stats.to_be_deleted

			if (dup) {
				console.log('duplicate_of: ' + dup)
				let search_terms = dup
				if (dup != i.identifier) search_terms += ' OR ' + i.identifier
				this.$store.commit('set', [this.framework_record, 'search_terms', search_terms])
				this.viewer.execute_search_start(false)
			}
		},

	}
}
</script>

<style lang="scss">
.k-case-tree-item-outer {
	font-size:16px;
	line-height:22px;
	padding-left:4px;
	overflow:hidden;
	// the background-color and border-radius makes the "ghost" look nicer when you drag-and-drop
	background-color:#fff;
	border-radius:4px;
}

.k-case-tree-new-imported-item {
	color: $v-red-darken-4;
	.k-case-tree-item {
		background-color:$v-red-lighten-4!important
	}
}

.k-case-tree-item-node-btn {
	margin-right:4px;
	margin-top:-2px;
	width:24px!important;
	height:24px!important;
	.fa-chevron-circle-right { font-size:18px!important; }
	.fa-dot-circle { font-size:18px!important; }
	.fa-map { font-size:16px!important; }
}

.k-case-tree-font-size--2 .k-case-tree-item-outer {
	font-size:12px; line-height:16px;
	.k-case-tree-node-show_expanded_full_statement .k-case-tree-item-code-plus-statement { line-height:16px; }
	.k-case-tree-item-node-btn {
		margin-right:2px;
		margin-top:-2px;
		width:20px!important;
		height:20px!important;
		.fa-chevron-circle-right { font-size:15px!important; }
		.fa-dot-circle { font-size:15px!important; }
		.fa-map { font-size:14px!important; }
	}
}
.k-case-tree-font-size--1 .k-case-tree-item-outer {
	font-size:14px; line-height:19px;
	.k-case-tree-node-show_expanded_full_statement .k-case-tree-item-code-plus-statement { line-height:18px; }
	.k-case-tree-item-node-btn {
		margin-right:3px;
		margin-top:-2px;
		width:22px!important;
		height:22px!important;
		.fa-chevron-circle-right { font-size:16px!important; }
		.fa-dot-circle { font-size:16px!important; }
		.fa-map { font-size:15px!important; }
	}
}

.k-case-tree-font-size-1 .k-case-tree-item-outer {
	font-size:18px; line-height:24px;
	.k-case-tree-node-show_expanded_full_statement .k-case-tree-item-code-plus-statement { line-height:22px; }
	.k-case-tree-item-node-btn {
		margin-right:4px;
		margin-top:-2px;
		width:25px!important;
		height:25px!important;
		.fa-chevron-circle-right { font-size:19px!important; }
		.fa-dot-circle { font-size:19px!important; }
		.fa-map { font-size:17px!important; }
	}
}

.k-case-tree-font-size-2 .k-case-tree-item-outer {
	font-size:20px; line-height:26px;
	.k-case-tree-node-show_expanded_full_statement .k-case-tree-item-code-plus-statement { line-height:24px; }
	.k-case-tree-item-node-btn {
		margin-right:4px;
		margin-top:-2px;
		width:25px!important;
		height:25px!important;
		.fa-chevron-circle-right { font-size:19px!important; }
		.fa-dot-circle { font-size:19px!important; }
		.fa-map { font-size:17px!important; }
	}
}

.k-case-tree-item-track-changes-btn {
	margin-left:-4px;
	margin-right:2px;
	.fas { font-size:18px!important; }
	.fa-circle-play { color:$v-amber-darken-3!important; transform:rotate(90deg); }
	.fa-circle { color:$v-green!important; }
	.fa-circle-half-stroke { color:$v-blue!important; }
	.fa-circle-plus { color:$v-purple!important; }
	.fa-circle-xmark { color:$v-red!important; }
}

.k-case-tree-item {
	padding:1px 0;
	border-radius:6px;
	border:1px solid transparent;
	position:relative;	// so that the chooser btn will be positioned properly
	margin-left:20px;
	transition: background-color 0.1s;

	// this is only used when we're viewing on a phone-sized window
	.k-case-tree-item-details-notes-header {
		color:#666;
		font-weight:bold;
		font-size:0.85em;
		padding:0 0 4px 6px;
		margin-top:4px;
		border-top:1px solid #ddd;
	}
}

.k-case-tree-item-embedded-mode-selected-items {
	margin-left:20px!important;
}

.k-case-tree-item-floating-bubbles {
	position:absolute;
	right:0px;
	display:flex;
}

.k-case-tree-item-associations-count {
	margin:0 1px;
	padding:0px 5px;
	font-size:10px;
	// line-height:16px;
	border-radius:5px;
	font-weight:bold;
	// background-color:rgba(220, 220, 220, 0.9);
	background-color:#eee;
	color:#666;
	cursor:pointer;
	.fa-arrows-alt-h {
		margin-top:-1px;
		color:#666;
		font-size:9px!important;
	}
}

.k-case-tree-item-comments-count {
	margin:0 1px;
	padding:0px 5px;
	font-size:10px;
	border-radius:5px;
	font-weight:bold;
	background-color:$v-amber-lighten-5;
	color:#555;
	cursor:pointer;
	.fa-comment {
		margin-top:-1px;
		// margin-right:3px;
		color:#555;
		font-size:9px!important;
	}
	.k-case-tree-item-comments-number {
	}
}

.k-case-tree-item-associations-count-hovered {
	background-color:#999;
	color:#fff;
	.fa-arrows-alt-h {
		color:#fff;
	}
}

.k-case-tree-item-associations-count-zero {
	color:#bbb;
	.fa-arrows-alt-h {
		color:#bbb;
	}
}

.k-case-tree-item-comments-count-zero {
	color:$v-amber-lighten-3;
	.fa-comment {
		color:$v-amber-lighten-3;
	}
}

.k-case-tree-item-comments-count-hovered {
	background-color:#555;
	color:$v-amber-lighten-2;
	.fa-comment {
		color:$v-amber-lighten-2;
	}
}

.k-case-tree-item-document {
	margin-left:0!important;

	// .k-case-tree-item-statement-abbreviated, .k-case-tree-item-statement-full {
	.k-case-tree-item-statement {
		font-weight:bold!important;
	}
}

// .k-case-tree-item-statement-abbreviated, .k-case-tree-item-statement-full {
.k-case-tree-item-statement {
	// see also k-case-tree-item-association-title in CASEItemAssociations
	display:inline;
	p:first-of-type {
		display:inline;
	}

	ul, ol {
		margin-top:4px;
	}

	table {
		border-collapse:collapse;
		margin:4px 0;
	}
	th, td {
		border:1px solid #ddd;
		padding:4px;
	}
	th {
		background-color:#f8f8f8;
	}
	td {
		background-color:#fff;
	}
	code {
		// display:inline-block;
		background-color:transparent!important;	// vuetify wants to use a light gray background color
		padding:0 2px!important;
		font-size:0.92em;	// this makes it a little bigger than what it would be otherwise
	}
}

// in CASEItem (in the tree), we want the first paragraph to be display:inline (see above); but in the tile view we might not? (tried this, but then realized it screwed things up)
// .k-case-tree-item-tile-inner {
// 	.k-case-tree-item-statement {
// 		p:first-of-type {
// 			display:block;
// 		}
// 	}
// }

.k-case-tree-item-node {
	// max-width:600px;
}

.k-case-tree-item-leaf {
}

.k-case-tree-item-flashing {
	border-color:#000!important;
	background-color:#ddd!important;
}

.k-case-tree-item-shifted {
	// not currently used...
	margin-left:20px;
	// border:2px solid transparent;
}

.k-case-tree-item-hovered {
	background-color:rgba(0, 0, 0, 0.05);
}

.k-case-tree-item-search-match {
	// background-color:rgba(0, 0, 0, 0.1)!important;
}

.k-case-tree-item-outer {
	.k-case-tree-item-not-yet-adopted {
		.k-case-tree-item-statement {
			text-decoration: wavy #aaa underline;
		}
	}
	.k-case-tree-item-no-longer-adopted {
		.k-case-tree-item-statement {
			text-decoration: $v-red-darken-3 line-through;
		}
	}
}


.k-case-tree-item-btns {
	position:absolute;
	left:-21px;
	margin-top:-1px;
	.fa-edit { font-size:18px!important; margin-top:-2px; }
	.fa-square, .fa-square-check, .fa-check-square, .fa-square-xmark, .fa-bullseye, .fa-circle-dot, .fa-circle, .fa-circle-half-stroke { font-size:18px!important; margin-top:-2px; }
	.v-input { margin-left:-5px; }
	.k-case-item-move-handle { font-size:16px!important; margin-top:-2px; margin-left:3px; }
}

.k-case-tree-item-inner {
	display:flex;
	align-items:flex-start;
	justify-content: flex-start;
}

.k-case-tree-item-sim-score {
	margin:0 4px;
	.v-progress-linear {
		margin-top:6px;
	}
}

.k-case-tree-item-code-plus-statement {
	cursor:pointer;
	white-space:nowrap;
}

.k-case-tree-item-wrapped {
	.k-case-tree-item-code-plus-statement {
		white-space: normal;
	}
}

.k-case-tree-searched-term {
	font-weight:bold;
	text-decoration:underline;
	color:#000;
}

.k-case-tree-searched-jumped {  // .k-case-tree-item-search-match:first-child {
	background-color:$v-yellow-accent-4!important;
}

.k-case-tree-item-last-clicked > .k-case-tree-item {
	// NOTE: this is needed for the crosswalk tool
	border-color:$v-grey-darken-2;
}

.k-case-tree-item-statement-hovered { text-decoration:underline; }

.k-case-tree-item-children {
	margin-left:18px;
	margin-bottom:4px;
}

.k-case-tree-node-show_expanded_full_statement {
	.k-case-tree-item-code-plus-statement {
		white-space:normal;
		line-height:20px;
		margin-top:1px;
	}
}

.k-case-tree-item-descendant-or-ancestor-of-last-clicked {
	.k-case-tree-item-code-plus-statement {
		// font-weight:bold;
	}
}

.k-case-tree-item-NOT-descendant-or-ancestor-of-last-clicked {
	button.grey--text.text--darken-2 { color:rgba(0,0,0,0.15)!important; }
	.k-case-tree-item-code-plus-statement {
		color:rgba(0,0,0,0.6);
		b.grey--text { color:rgba(0,0,0,0.4)!important; }
	}
}

.k-case-tree-leaf-drag-area {
	border: 1px dotted #999;
	border-radius: 4px;
	padding: 0 4px;
	margin-left: 26px;
	color: #999;
	font-size: 12px;
}

.k-case-tree-item-shifted-right-for-move {
	position:fixed;
	right:12px;
	top:188px;
	width:calc(50vw - 24px);
	height:calc(100vh - 200px);
	padding:8px 8px 8px 0;
	overflow-y:auto;
}

.k-case-tree-item-type-color-neutral {background-color:$v-blue-grey-lighten-5;}
.k-case-tree-item-type-color { margin-top:1px; background-color:$v-blue-grey-lighten-5; }
// rainbow order
.k-case-tree-item-type-color-0 { background-color: $v-indigo-lighten-5; }
.k-case-tree-item-type-color-1 { background-color: $v-purple-lighten-5; }
.k-case-tree-item-type-color-2 { background-color: $v-blue-lighten-5; }
.k-case-tree-item-type-color-3 { background-color: $v-teal-lighten-5; }
.k-case-tree-item-type-color-4 { background-color: $v-lime-lighten-5; }
.k-case-tree-item-type-color-5 { background-color: $v-orange-lighten-5; }
.k-case-tree-item-type-color-6 { background-color: $v-brown-lighten-5; }
.k-case-tree-item-type-color-7 { background-color: $v-red-lighten-5; }
.k-case-tree-item-type-color-8 { background-color: $v-deep-purple-lighten-5; }
.k-case-tree-item-type-color-9 { background-color: $v-light-blue-lighten-5; }
.k-case-tree-item-type-color-10 { background-color: $v-green-lighten-5; }
.k-case-tree-item-type-color-11 { background-color: $v-yellow-lighten-5; }
.k-case-tree-item-type-color-12 { background-color: $v-deep-orange-lighten-5; }

// rainbow order, more subtle
.k-case-tree-item-type-color-light-0 { background-color: rgba(232,234,246,0.5); }	// $v-indigo-lighten-5
.k-case-tree-item-type-color-light-1 { background-color: rgba(243,229,245,0.5); }	// $v-purple-lighten-5
.k-case-tree-item-type-color-light-2 { background-color: rgba(227,242,253,0.5); }	// $v-blue-lighten-5
.k-case-tree-item-type-color-light-3 { background-color: rgba(224,242,241,0.5); }	// $v-teal-lighten-5
.k-case-tree-item-type-color-light-4 { background-color: rgba(249,251,231,0.5); }	// $v-lime-lighten-5
.k-case-tree-item-type-color-light-5 { background-color: rgba(255,243,224,0.5); }	// $v-orange-lighten-5
.k-case-tree-item-type-color-light-6 { background-color: rgba(239,235,233,0.5); }	// $v-brown-lighten-5
.k-case-tree-item-type-color-light-7 { background-color: rgba(255,235,238,0.5); }	// $v-red-lighten-5
.k-case-tree-item-type-color-light-8 { background-color: rgba(237,231,246,0.5); }	// $v-deep-purple-lighten-5
.k-case-tree-item-type-color-light-9 { background-color: rgba(225,245,254,0.5); }	// $v-light-blue-lighten-5
.k-case-tree-item-type-color-light-10 { background-color: rgba(232,245,233,0.5); }	// $v-green-lighten-5
.k-case-tree-item-type-color-light-11 { background-color: rgba(255,253,231,0.5); }	// $v-yellow-lighten-5
.k-case-tree-item-type-color-light-12 { background-color: rgba(251,233,231,0.5); }	// $v-deep-orange-lighten-5

// specified by color
.k-case-tree-item-type-color-indigo { background-color: $v-indigo-lighten-5; }
.k-case-tree-item-type-color-purple { background-color: $v-purple-lighten-5; }
.k-case-tree-item-type-color-blue { background-color: $v-blue-lighten-5; }
.k-case-tree-item-type-color-teal { background-color: $v-teal-lighten-5; }
.k-case-tree-item-type-color-lime { background-color: $v-lime-lighten-5; }
.k-case-tree-item-type-color-orange { background-color: $v-orange-lighten-5; }
.k-case-tree-item-type-color-brown { background-color: $v-brown-lighten-5; }
.k-case-tree-item-type-color-red { background-color: $v-red-lighten-5; }
.k-case-tree-item-type-color-deep-purple { background-color: $v-deep-purple-lighten-5; }
.k-case-tree-item-type-color-light-blue { background-color: $v-light-blue-lighten-5; }
.k-case-tree-item-type-color-green { background-color: $v-green-lighten-5; }
.k-case-tree-item-type-color-yellow { background-color: $v-yellow-lighten-5; }
.k-case-tree-item-type-color-deep-orange { background-color: $v-deep-orange-lighten-5; }

// contrast order
// .k-case-tree-item-type-color-0 { background-color: $v-purple-lighten-5; }	// 0
// .k-case-tree-item-type-color-1 { background-color: $v-lime-lighten-5; }	// 4
// .k-case-tree-item-type-color-2 { background-color: $v-brown-lighten-5; }	// 6
// .k-case-tree-item-type-color-3 { background-color: $v-blue-lighten-5; }	// 2
// .k-case-tree-item-type-color-4 { background-color: $v-red-lighten-5; }	// 7
// .k-case-tree-item-type-color-5 { background-color: $v-indigo-lighten-5; }	// 1
// .k-case-tree-item-type-color-6 { background-color: $v-amber-lighten-5; }	// 5
// .k-case-tree-item-type-color-7 { background-color: $v-pink-lighten-5; }	// 8
// .k-case-tree-item-type-color-8 { background-color: $v-teal-lighten-5; }	// 3

// this would make the changed-hcs color different than the changed-fullStatement color when viewing a progression
// .k-case-tree-item-hcs {
// 	.k-diff-new {
// 		background-color:$v-amber-lighten-4;
// 	}
// }
</style>
