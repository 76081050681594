<template><div class="k-editor-wrapper-outer" style="z-index:101"><div class="k-editor-wrapper k-editor-wrapper-wide k-case-associated-item-tree" :class="top_css">
	<v-btn class="k-editor-close-btn" small icon color="grey darken-2" @click.stop="$emit('dialog_cancel')"><v-icon>fas fa-times-circle</v-icon></v-btn>
	<div class="k-editor-title d-flex">
		<div><v-icon small class="mr-2">fas fa-map</v-icon> <span v-html="framework_record.json?framework_record.json.CFDocument.title:'Loading Framework...'"></span></div>
	</div>
	<div class="k-case-item-editor-scrollable k-case-item-editor-scrollable-tallest pa-0">
		<div v-if="displayed_framework_record.cfo" class="k-case-associated-item-tree-case-tree-wrapper" :class="framework_css">
			<div v-if="associationType=='precedes'" class="d-flex pl-5 pt-1 pr-5 align-center" style="font-size:18px;">
				<b>Progression:</b>
				<v-spacer/>
				<v-checkbox class="mt-0 pt-0" v-model="highlight_progression_changes" hide-details><template v-slot:label><span style="font-size:14px">Highlight changes</span></template></v-checkbox>
			</div>
			<CASETree ref="associated_item_tree_tree" :home_framework_record="displayed_framework_record" :open_nodes_override="open_nodes_right" :highlighted_identifier_override="item_identifier" />
		</div>
	</div>
</div></div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CASETree from './CASETree'

export default {
	components: { CASETree },
	props: {
		framework_identifier: { type: String, required: true },
		archive_framework_record: { required: false, default() { return null } },
		left_node: { required: false, default() { return null } },
		associationType: { type: String, required: true },
		item_identifier: { type: String, required: false, default() { return '' } },
		item_tree_key: { type: Number, required: false, default() { return 0 } },
		viewer: { required: true },

		// these can be specified to draw a line from line_from_tree_key in the main tree to line_to_identifier or line_to_tree_key here
		line_to_identifier: { type: String, required: false, default() { return '' } },
		line_to_tree_key: { type: Number, required: false, default() { return 0 } },
		line_from_tree_key: { required: false, default() { return '' } },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		framework_record: {},
		displayed_framework_record: {},
		line_uuid: '',
		open_nodes_right: {},
	}},
	computed: {
		...mapState(['framework_records']),
		...mapGetters([]),
		top_css() {
			if (!this.framework_record.cfo) return ''
			return U.framework_color(this.framework_identifier) + '-editor'
		},
		framework_css() {
			if (!this.framework_record.cfo) return ''
			return U.framework_color(this.framework_record.cfo.cftree.cfitem.identifier)
		},
		highlight_progression_changes: {
			get() { return this.$store.state.lst.highlight_progression_changes },
			set(val) { this.$store.commit('lst_set', ['highlight_progression_changes', val]) }
		},
	},
	watch: {
		framework_identifier: {
			immediate: true, handler(val) {
				if (!this.archive_framework_record) {
					this.framework_identifier_changed()
				}
			}
		},
		archive_framework_record: {
			immediate: true, handler(val) {
				if (this.archive_framework_record) {
					this.initialize_tree(this.archive_framework_record)
				} else {
					this.framework_identifier_changed()
				}
			}
		},
		associationType() {
			// when associationType changes, re-initialize the association display, since we may have changed from a 'precedes' (progression) assocition to a "normal" one
			this.initialize_association_display()
		},
		line_from_tree_key() {
			this.$nextTick(x=>this.show_connecting_line())
		},
		line_to_identifier() {
			this.$nextTick(x=>this.show_connecting_line())
		},
		line_to_tree_key() {
			this.$nextTick(x=>this.show_connecting_line())
		},
	},
	created() {
		// stash a reference to the current_editor in viewer, so the viewer can determine whether or not to allow the user to switch to editing another item
		// this.viewer.current_editor = this
	},
	mounted() {
	},
	methods: {
		framework_identifier_changed() {
			let fr = this.framework_records.find(x=>x.lsdoc_identifier==this.framework_identifier)

			// if the framework hasn't yet been loaded, do so now
			if (!fr || !fr.framework_json_loaded) {
				this.load_framework()
			} else {
				// else set framework_record here
				this.initialize_tree(fr)
			}
		},

		load_framework() {
			// first load the framework from the server
			U.loading_start('Loading framework…')
			this.$store.dispatch('get_lsdoc', this.framework_identifier).then(()=>{
				U.loading_stop()

				// then build the cfo for the framework
				let fr = this.framework_records.find(x=>x.lsdoc_identifier==this.framework_identifier)
				U.build_cfo(this.$worker, fr.json).then((cfo)=>{
					this.$store.commit('set', [fr, 'cfo', cfo])

					// then set framework_record
					this.initialize_tree(fr)

					U.loading_stop()
				})
				.catch((e)=>{
					U.loading_stop()
					console.log(e)
				})

			}).catch((e)=>{
				console.log(e)
				U.loading_stop('refresh_lsdoc')
				this.$alert('An error occurred when loading the competency framework.').then(x=>this.hide_tree())
			})
		},

		initialize_tree(fr) {
			if (!fr.framework_json_loaded || fr.cfo == null) {
				this.load_framework()
				return
			}
			this.framework_record = fr
			this.initialize_association_display()
		},

		initialize_association_display() {
			// if associationType is anything other than 'precedes', we show the "normal" tree
			if (this.associationType != 'precedes') {
				this.displayed_framework_record = this.framework_record
				this.finish_initialize_association_display()
				return
			}
			// but for 'precedes', we show a modified version of the tree that only includes the items from this "progression"
			// NOTE: this currently only works if all the items come from this same framework

			// start with a "skeleton" framework record with a bunch of stuff copied from the actual framework_record
			let fr = U.create_framework_record(this.framework_record.lsdoc_identifier, {CFDocument: this.framework_record.json.CFDocument}, this.framework_record.ss_framework_data, true)
			U.build_cfo(this.$worker, fr.json, 'no_loading').then((cfo)=>{
				cfo.cfitems = this.framework_record.cfo.cfitems
				cfo.item_types = this.framework_record.cfo.item_types
				cfo.tree_nodes_hash = this.framework_record.cfo.tree_nodes_hash
				cfo.associated_documents = this.framework_record.cfo.associated_documents
				cfo.associations_hash = this.framework_record.cfo.associations_hash
				cfo.item_type_levels = this.framework_record.cfo.item_type_levels

				// now add this progression to cfo.cftree.children
				// start with the the item_tree_key node
				let node = this.left_node
				cfo.cftree.children.push(node)

				// trace back to the item that precedes this one, then the one that precedes that one, and so forth
				do {
					let assoc = cfo.associations_hash[node.cfitem.identifier].find(x=>x.associationType == 'precedes' && x.destinationNodeURI.identifier == node.cfitem.identifier)
					if (!assoc) node = null
					else {
						node = cfo.cfitems[assoc.originNodeURI.identifier].tree_nodes[0]
						if (node) cfo.cftree.children.unshift(node)
					}
				} while (node)

				// then trace forward to the item that this one precedes, then the next one, and so forth
				node = this.left_node
				do {
					let assoc = cfo.associations_hash[node.cfitem.identifier].find(x=>x.associationType == 'precedes' && x.originNodeURI.identifier == node.cfitem.identifier)
					if (!assoc) node = null
					else {
						node = cfo.cfitems[assoc.destinationNodeURI.identifier].tree_nodes[0]
						if (node) cfo.cftree.children.push(node)
					}
				} while (node)

				// stash our new cfo in fr.cfo, set displayed_framework_record to our new fr, and show the item
				fr.cfo = cfo
				this.displayed_framework_record = fr
				this.finish_initialize_association_display()
			})
		},

		finish_initialize_association_display() {
			this.open_item(this.item_identifier, this.item_tree_key)
			this.$nextTick(x=>this.show_connecting_line())
		},

		open_item(item_identifier, item_tree_key) {
			// get the node that goes with this item_identifier or item_tree_key
			let node
			if (item_tree_key > 0) {
				node = this.framework_record.cfo.tree_nodes_hash[item_tree_key]
			} else {
				let cfitem = this.framework_record.cfo.cfitems[item_identifier]
				// if item doesn't exist, just return (this could happen, e.g., for an item that didn't actually exist in the archive)
				if (!cfitem) return
				node = cfitem.tree_nodes[0]
			}

			// if node isn't found, something is wrong...
			if (!node) {
				console.log('CASEAssociatedItemTree open_item: bad identifier/key match', item_identifier, item_tree_key)
				return
			}

			this.open_nodes_right = {}
			// for a precedes assoc, where we show the progression on the right, we "open" all the nodes (which are all actually leafs); then the code in CASEItemTile uses that flag to make the full text of all items in the progression visible
			if (this.associationType == 'precedes') {
				for (let node of this.displayed_framework_record.cfo.cftree.children) {
					this.$set(this.open_nodes_right, node.tree_key+'', true)
				}

			// else open this node and its parents
			} else {
				let parent_node = node
				while (parent_node) {
					this.$set(this.open_nodes_right, parent_node.tree_key+'', true)
					parent_node = parent_node.parent_node
				}
			}
		},

		remove_connecting_line() {
			vapp.remove_line(this.line_uuid)
		},

		show_connecting_line() {
			if (this.line_to_identifier == '' && this.line_to_tree_key == 0) return
			if (!this.line_from_tree_key) return
			if (this.item_identifier != this.line_to_identifier) return
			if (empty(this.framework_record, 'cfo', 'cfitems')) return

			// for now, at least, only show one line at a time
			if (this.line_uuid) {
				vapp.remove_line(this.line_uuid)
				this.line_uuid = ''
			}

			// To remove: redundant with checks above
			// if (this.line_to_identifier && this.item_identifier == this.line_to_identifier && this.line_from_tree_key) {

			let line_start
			// for an archive connection, or for a precedes connection where we're lining from the item to itself, we draw the line from the CASEItem itself
			if (this.associationType == 'archive' || (this.associationType == 'precedes' && this.line_from_tree_key == this.line_to_tree_key)) {
				line_start = $(this.viewer.$refs.main_viewer_tree.$el).find('[data-case-tree-item-tree-key=' + this.line_from_tree_key + ']')

			} else {
				// for others, we draw the line from the association marker in the CASEItem
				let dam = this.line_from_tree_key + '-' + this.associationType.replace(/:/, '--') + '-' + this.line_to_identifier
				if (this.associationType == 'aliasOf') dam += '-' + this.line_to_tree_key
				line_start = $(this.viewer.$refs.main_viewer_tree.$el).find('[data-association-marker=' + dam + ']')
			}

			let node2
			if (this.line_to_tree_key > 0) {
				node2 = this.framework_record.cfo.tree_nodes_hash[this.line_to_tree_key]
			} else {
				let cfitem = this.framework_record.cfo.cfitems[this.line_to_identifier]
				// if item doesn't exist, just return (this could happen, e.g., for an item that didn't actually exist in the archive)
				if (!cfitem) return
				node2 = cfitem.tree_nodes[0]
			}

			let line_end = $(this.$el).find('[data-case-tree-item-tree-key=' + node2.tree_key + ']')
			// console.log(line_end.length, line_end)

			this.line_uuid = vapp.show_line({
				stroke_width: 2,
				// css_class: U.framework_color(this.viewer.framework_record.cfo.cftree.cfitem.identifier) + '-svg-line',
				css_class: 'k-association-svg-line',
				line_start: line_start,
				offset_start: 'rc',
				line_end: line_end,
				offset_end: 'lc',
				z_index: 198,	// was 10000; lowered to keep it below the expanded tile...
				// click_fn: 'bubble',
			})

			// scroll to the line end (the item in the associated item tree)
			setTimeout(x=>{
				let cjq = line_end.parents('.k-case-tree-scroll-wrapper').first()
				if (cjq.length > 0) {
					this.$vuetify.goTo(line_end[0], {container:cjq[0], offset:150})
				}
			}, 100)
		},
	}
}
</script>

<style lang="scss">
.k-case-associated-item-tree.k-editor-wrapper {
	// top:89px;
	.k-case-item-editor-scrollable {
		border-radius:0 0 8px 8px;
	}
}
.k-case-associated-item-tree-case-tree-wrapper {
	padding:0;
	border-radius:0;

	// we don't need to show the document node here, because the title is right at the top of the popover window
	.k-case-tree-document-node-item {
		display:none;
	}

	.k-case-tree-active-node-showing {
		border-radius:0;
		width:100%;
		padding:8px;

		.k-case-tree {
			box-shadow: none!important;
			padding-top:8px;
		}

		.k-case-tree-item {
			margin-left:4px;
		}
	}
}

.k-association-svg-line line {
	stroke: $v-amber-darken-4 !important;
	opacity:0.7;
}

</style>
