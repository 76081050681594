<template><div class="k-case-item-importer mt-2">
	<div class="text-center" style="color:black; font-size:18px;"><b>Batch Import Associations</b></div>

	<div class="k-case-ie-line mt-2">
		<div class="k-case-ie-line-label mr-2 text-right" style="width:84px"><nobr>Associate with<br>items from:</nobr></div>
		<v-autocomplete v-model="right_framework_identifier" :items="frameworks" label="" outlined background-color="#fff" dense hide-details>
			<template v-slot:item="data"><v-list-item-content><v-list-item-title><b v-if="data.item.this_framework">THIS FRAMEWORK:</b> <span v-html="data.item.text"></span></v-list-item-title></v-list-item-content></template>
		</v-autocomplete>
	</div>

	<div class="k-case-ie-line mt-2" v-if="is_mirror || right_framework_identifier!=left_framework_identifier"><v-spacer/>
		<v-checkbox class="mt-0 pt-0 d-inline-block" v-model="save_to_crosswalk_framework" hide-details><template v-slot:label><span style="font-size:14px">Save associations to dedicated crosswalk framework</span></template></v-checkbox>
	<v-spacer/></div>

	<div class="mt-2">
		Sample import lines:
<pre class="elevation-2 pa-1 mt-1 mb-2" style="border-radius:6px; border:1px solid #ccc;">
RL.K.9&nbsp;&nbsp;&nbsp;ELD-SI.K-3.Explain
W.5.7&nbsp;&nbsp;&nbsp;&nbsp;ELD-LA.4-5.Inform.Expressive&nbsp;&nbsp;ELD-SI.4-12.Inform
isPartOf: RL.5.1&nbsp;Unit_2
-isPartOf: RL.5.2&nbsp;Unit_3
</pre>
	</div>

	<div class="mt-1 pt-2 text-center" style="border-top:1px solid #999">Paste association text in below, then click “Process Associations” to continue.</div>
	<div class="d-flex align-center mt-2 mb-3">
		<v-btn small color="secondary" class="mr-2" @click="$emit('cancel_import')">Cancel Import</v-btn>
		<v-btn small color="primary" @click="import_associations">Process Associations…</v-btn>
		<v-spacer/>
		<v-btn small color="#333" dark fab class="ml-2" @click="ta_dialog_open=true"><v-icon>fas fa-expand</v-icon></v-btn>
	</div>
	<v-textarea background-color="#fff" class="k-case-item-importer-raw-text-area" outlined dense hide-details v-model="raw_text_for_import" placeholder="" rows="10"></v-textarea>

	<v-dialog v-model="ta_dialog_open" max-width="95vw" persistent scrollable>
		<v-card style="background-color:#eee">
			<v-card-text>
				<div class="mt-4">
<pre class="elevation-2 pa-1 mt-1 mb-2 white" style="border-radius:6px; border:1px solid #ccc;">
RL.K.9&nbsp;&nbsp;&nbsp;ELD-SI.K-3.Explain
W.5.7&nbsp;&nbsp;&nbsp;&nbsp;ELD-LA.4-5.Inform.Expressive&nbsp;&nbsp;ELD-SI.4-12.Inform
isPartOf: RL.5.1&nbsp;Unit_2
-isPartOf: RL.5.2&nbsp;Unit_3
</pre>
				</div>
				<div class="d-flex align-center mt-2 mb-3">
					<v-btn small color="secondary" class="mr-2" @click="ta_dialog_open=false;$emit('cancel_import')">Cancel Import</v-btn>
					<v-btn small color="primary" @click="import_associations">Process Associations…</v-btn>
					<v-spacer/>
					<v-btn small color="#333" dark fab class="ml-2" @click="ta_dialog_open=false"><v-icon>fas fa-compress</v-icon></v-btn>
				</div>
				<v-textarea background-color="#fff" class="k-case-item-importer-raw-text-area-max" autofocus outlined dense hide-details v-model="raw_text_for_import" placeholder="" rows="40"></v-textarea>
			</v-card-text>
		</v-card>
	</v-dialog>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import TemplateComponent from '@/components/TemplateComponent'

export default {
	// components: { TemplateComponent },
	props: {
		editor_component: { required: true },
	},
	data() { return {
		right_framework_record: null,	// this will be set below
		host_framework_record_status: 'not_initialized',	// see below
		raw_text_for_import: '',
		// raw_text_for_import: 'isRelatedTo: AJ.1 3.OA.A.1',
		// raw_text_for_import: 'isPartOf: SC15.4.12 4.ESS2.1',
		ta_dialog_open: false,
	}},
	computed: {
		...mapState(['user_info', 'framework_records']),
		...mapGetters([]),
		// the framework record we're editing
		left_framework_record() { return this.editor_component.framework_record },
		left_framework_identifier() { return this.left_framework_record.lsdoc_identifier },
		is_mirror() { return this.left_framework_record.ss_framework_data.is_mirror === 'yes' },
		frameworks() {
			if (empty(this.left_framework_record)) return []

			let arr = []
			for (let fr of this.$store.getters.filtered_framework_records) {
				let doc = fr.json.CFDocument
				// skip crosswalk frameworks
				if (doc.frameworkType == 'crosswalk') continue
				if (doc.identifier == this.left_framework_record.lsdoc_identifier) continue
				arr.push({value: doc.identifier, text: doc.title})
			}
			// sort by title
			arr.sort((a,b)=>U.natural_sort(a.text, b.text))
			// then add this framework at the start of the list -- UNLESS THIS IS A MIRROR
			if (!this.is_mirror) arr.unshift({value: this.left_framework_record.lsdoc_identifier, this_framework:true, text: this.left_framework_record.json.CFDocument.title})
			return arr
		},
		right_framework_identifier: {
			// store the right_framework_identifier for each framework in localstorage
			// default value is the left_framework_identifier
			get() {
				let s = this.$store.state.lst.make_association_right_framework_identifier
				if (s) {
					let o = JSON.parse(s)
					return (o[this.left_framework_identifier]) ? o[this.left_framework_identifier] : this.left_framework_identifier
				} else return this.left_framework_identifier
			},
			set(val) {
				let o = {}
				let s = this.$store.state.lst.make_association_right_framework_identifier
				if (s) o = JSON.parse(s)
				o[this.left_framework_identifier] = val
				this.$store.commit('lst_set', ['make_association_right_framework_identifier', JSON.stringify(o)])
			},
		},
		save_to_crosswalk_framework: {
			get() { 
				// for mirrored frameworks, we must save to a crosswalk framework
				if (this.is_mirror) return true

				// we have separate defaults for this for inter- and intra-framework associations
				if (this.right_framework_identifier == this.left_framework_identifier) {
					return this.$store.state.lst.make_association_save_to_crosswalk_framework_intra
				} else {
					return this.$store.state.lst.make_association_save_to_crosswalk_framework_inter
				}
			},
			set(val) { 
				if (this.is_mirror) return

				if (this.right_framework_identifier == this.left_framework_identifier) {
					this.$store.commit('lst_set', ['make_association_save_to_crosswalk_framework_intra', val]) 
				} else {
					this.$store.commit('lst_set', ['make_association_save_to_crosswalk_framework_inter', val]) 
				}
			}
		},
		host_framework_record() {
			console.warn('host_framework_record computed: host_framework_record_status==' + this.host_framework_record_status)

			// if save_to_crosswalk_framework is false, we're saving to the left_framework_record
			if (!this.save_to_crosswalk_framework) {
				this.host_framework_record_status = 'initialized'
				return this.left_framework_record
			} else {
				// else if we're saving to a dedicated crosswalk framework, first see if we already the crosswalk framework record already exists
				let cfr = U.get_crosswalk_framework_record(this.left_framework_identifier, this.right_framework_identifier)
				if (cfr) {
					this.host_framework_record_status = 'framework_loaded'
				} else {
					// if not, create it now; then computed will re-run
					this.host_framework_record_status = 'loading_framework'
					U.loading_start()
					U.create_crosswalk_framework(this.left_framework_record, this.right_framework_record).then(x=>{
						U.loading_stop()
						console.log('created crosswalk framework!')
						// call update_frameworks_with_associations so that the viewer knows to show assocs from the crosswalk framework
						vapp.case_tree_component.update_frameworks_with_associations()
						this.host_framework_record_status = 'framework_loaded'
					})
					return
				}

				// create cfo for crosswalk framework record if needed
				if (cfr.cfo) {
					this.host_framework_record_status = 'initialized'
					return cfr
				} else {
					this.host_framework_record_status = 'building_cfo'
					U.build_cfo(this.$worker, cfr.json).then((cfo)=>{
						this.$store.commit('set', [cfr, 'cfo', cfo])
						console.log('created crosswalk framework cfo!')
						this.host_framework_record_status = 'cfo_built'
					}).catch((e)=>{ console.warn('error computing cfo', e) })
				}
			}
		},
	},
	watch: {
		right_framework_identifier: {immediate: true, handler(val) {
			// whenever right framework changes, reset host_framework_record_status
			this.host_framework_record_status = 'not_initialized'

			let fr = this.framework_records.find(x=>x.lsdoc_identifier==this.right_framework_identifier)
			
			// if we don't find fr, maybe the last framework the person associated to has been deleted
			if (empty(fr)) return

			// if the framework hasn't yet been loaded, do so now
			if (!fr.framework_json_loaded) {
				this.load_framework()
			} else {
				// else set right_framework_record here
				this.right_framework_record = fr
			}
		}},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		load_framework() {
			// first load the framework from the server
			U.loading_start('Loading framework…')
			this.$store.dispatch('get_lsdoc', this.right_framework_identifier).then(()=>{
				U.loading_stop()

				// then build the cfo for the framework
				let fr = this.framework_records.find(x=>x.lsdoc_identifier==this.right_framework_identifier)
				U.build_cfo(this.$worker, fr.json).then((cfo)=>{
					this.$store.commit('set', [fr, 'cfo', cfo])

					// then set right_framework_record
					this.right_framework_record = fr

					U.loading_stop()
				})
				.catch((e)=>{
					U.loading_stop()
					console.log(e)
				})

			}).catch((e)=>{
				console.log(e)
				this.$alert('An error occurred when loading the competency framework.').then(x=>this.hide_tree())
			})
		},

		// TO DELETE??
		add_framework_association(framework_document, assocs_to_add) {
			// framework_document should be the CFDocument for a framework involved in an association

			// if the given framework_document.identifier isn't the "home" framework,
			if (framework_document.identifier != this.left_framework_record.lsdoc_identifier) {
				// see if we already have an association with the other framework in our framework_document
				if (!this.left_framework_record.cfo.associated_documents.find(x=>x.identifier == framework_document.identifier)) {
					// and also check to see if we've already added the document assoc to assocs_to_add
					if (!assocs_to_add.find(x=>x.destinationNodeURI.identifier == framework_document.identifier)) {
						// if we get to here, create the assoc -- origin is the "home" framework; destination is the other framework
						let doc_assoc = new CFAssociation({
							originNodeURI: {
								title: this.left_framework_record.json.CFDocument.title,
								identifier: this.left_framework_record.json.CFDocument.identifier,
								uri: this.left_framework_record.json.CFDocument.uri,
							},

							associationType: 'isRelatedTo',

							destinationNodeURI: {
								title: framework_document.title,
								identifier: framework_document.identifier,
								uri: framework_document.uri,
							},
						})
						doc_assoc.complete_data(this.left_framework_record.json.CFDocument)	// this will add '*NOW*' as the lastChangeDateTime
						assocs_to_add.push(doc_assoc.to_json())
						console.log('adding doc assoc', doc_assoc)
					}
				}
			}
		},

		import_associations() {
			// if host_framework_record isn't yet created, wait until it is
			if (!this.host_framework_record || this.host_framework_record_status != 'initialized') {
				console.log('waiting for host_framework_record initialization')
				setTimeout(x=>{this.import_associations()}, 100)
				return
			}

			// internal fn for processing errors
			let process_error = (line_number, error) => {
				log.push(sr('<div class="my-3">$1: <b class="red" style="padding:3px 3px; border-radius:3px; color:#fff;">Error:</b> $2</div>', line_number, error))
				++error_count
			}

			// internal fn for processing non-error info
			let process_info = (line_number, statement) => {
				log.push(sr('$1: $2', line_number, statement))
			}

			////////////////////////////////

			let log = []
			let error_count = 0

			let lines = $.trim(this.raw_text_for_import)
			if (empty(lines)) {
				this.$alert('You must enter text to import.')
				return
			}

			let assocs = []
			let assocs_to_delete = []

			let framework_from = this.left_framework_record
			let framework_to = this.right_framework_record

			lines = lines.split('\n')
			let line_number = 0
			///////////////////////////////////////
			// go through each line
			for (let line of lines) {
				++line_number

				// trim line
				line = $.trim(line)

				// skip blank lines
				if (empty(line)) continue

				// normal lines: optional relationship type, then 'from' identifier or hcs, then space(s), then one or more 'to' identifiers/HCSs
				if (line.search(/^\s*((-)?(\w+):\s+)?(\S+)\s+(.*)/) == 0) {
					let remove = RegExp.$2
					let type = RegExp.$3
					let from_id = RegExp.$4
					let to_ids = RegExp.$5

					// change _ to space in from_id
					from_id = from_id.replace(/_/g, ' ')

					if (!type) type = 'isRelatedTo'

					// TODO: deal with "reverse" assocs, where we want the origin to be the right framework and the destination to be the left -- use a '*' after the type for this

					// error if this isn't a valid type
					if (!this.$store.state.association_type_labels[type]) {
						process_error(line_number, `Bad association type <b>${type}</b>`)
						continue
					}

					to_ids = to_ids.split(/\s+/)

					// console.log(sr('$1: $2 -> $3', type, from_id, to_ids.join(', ')))

					let from_item

					// look for from_id in framework_from and to_id in framework_to
					if (U.is_uuid(from_id)) from_item = framework_from.json.CFItems.find(x=>x.identifier==from_id)
					else from_item = framework_from.json.CFItems.find(x=>x.humanCodingScheme==from_id)
					if (!from_item) {
						process_error(line_number, 'Couldn’t find item FROM <b>' + from_id + '</b>')
						continue
					}

					for (let to_id of to_ids) {
						// change _ to space in to_id
						to_id = to_id.replace(/_/g, ' ')

						let to_item

						if (U.is_uuid(to_id)) to_item = framework_to.json.CFItems.find(x=>x.identifier==to_id)
						else to_item = framework_to.json.CFItems.find(x=>x.humanCodingScheme==to_id)
						if (!to_item) {
							process_error(line_number, 'Couldn’t find item TO <b>' + to_id + '</b>')
							continue
						}

						// if the user is asking to *remove* the association...
						if (remove == '-') {
							// check if the association already exists, in either direction
							let atd
							let charr = this.host_framework_record.cfo.associations_hash[from_item.identifier]
							if (charr) atd = charr.find(x=>x.associationType == type && (x.originNodeURI.identifier == to_item.identifier || x.destinationNodeURI.identifier == to_item.identifier))
							if (atd) {
								// found it, so remove it
								assocs_to_delete.push(atd)
								process_info(line_number, sr('Deleting association [$1]: $2 -> $3', type, from_id, to_id))

							} else {
								// not found
								process_error(line_number, sr('Association to be deleted does not exist in framework [$1]: $2 -> $3', type, from_id, to_id))
							}

						} else {
							// if the association already exists, or has been processed previously, don't add
							let charr = this.host_framework_record.cfo.associations_hash[from_item.identifier]
							if (charr && charr.find(x=>x.originNodeURI.identifier == from_item.identifier && x.destinationNodeURI.identifier == to_item.identifier && x.associationType == type)) {
								process_error(line_number, sr('Association already exists in framework [$1]: $2 -> $3', type, from_id, to_id))
								continue
							}
							if (assocs.find(x=>x.originNodeURI.identifier == from_item.identifier && x.destinationNodeURI.identifier == to_item.identifier && x.associationType == type)) {
								process_error(line_number, sr('Association already added earlier in text input [$1]: $2 -> $3', type, from_id, to_id))
								continue
							}

							// got from_item and to_item; process assoc
							let assoc = new CFAssociation({
								originNodeURI: {
									title: U.generate_cfassociation_node_uri_title(from_item, true) + sr(' (:$1:)', framework_from.lsdoc_identifier),
									identifier: from_item.identifier,
									uri: from_item.uri,
								},

								associationType: type,

								destinationNodeURI: {
									title: U.generate_cfassociation_node_uri_title(to_item, true) + sr(' (:$1:)', framework_to.lsdoc_identifier),
									identifier: to_item.identifier,
									uri: to_item.uri,
								},
							})

							// complete association with the crosswalk framework's CFDocument record (which could, in theory, be different than either from or to)
							assoc.complete_data(this.host_framework_record.json.CFDocument)	// this will add '*NOW*' as the lastChangeDateTime

							assocs.push(assoc.to_json())

							process_info(line_number, sr('Found association [$1]: $2 -> $3', type, from_id, to_id))
						}
					}

				} else {
					process_info(line_number, sr('Skipped line: $1', line))
				}
			}
			// finished going through each line
			///////////////////////////////////////

			// show log and give user options for what to do
			let error_text = ''
			if (error_count > 0) error_text = sr('; <span class="red white--text" style="padding:3px 6px; border-radius:4px;">$1 possible errors</span>', error_count)
			let text = sr('<div style="margin-top:-10px; margin-bottom:8px;">Processed $1 lines; found $2 associations$3.</div>', line_number, assocs.length, error_text)
			let acceptText = ''

			if (assocs.length > 0) {
				text += '<div class="mb-1">Associations to ADD:</div>'
				text += '<div style="margin-left:16px; font-size:14px; line-height:18px;">'
				for (let assoc of assocs) {
					text += sr('<div style="margin-left:25px; text-indent:-35px; margin-top:4px;">• <b class="green--text text--darken-2">$1:</b> $2… <b class="green--text text--darken-2">-></b> $3…</div>', assoc.associationType, assoc.originNodeURI.title.substr(0,50), assoc.destinationNodeURI.title.substr(0,50))
				}
				text += '</div>'
				acceptText += `Add ${assocs.length} ${U.ps('Association', assocs.length)}`
			}

			if (assocs_to_delete.length > 0) {
				text += '<div class="mb-1">Associations to DELETE:</div>'
				text += '<div style="margin-left:16px; font-size:14px; line-height:18px;">'
				for (let assoc of assocs_to_delete) {
					text += sr('<div style="margin-left:25px; text-indent:-35px; margin-top:4px;">• <b class="red--text text--darken-2">$1:</b> $2… <b class="red--text text--darken-2">-></b> $3…</div>', assoc.associationType, assoc.originNodeURI.title.substr(0,50), assoc.destinationNodeURI.title.substr(0,50))
				}
				text += '</div>'
				if (acceptText) acceptText += ' / '
				acceptText += `Delete ${assocs_to_delete.length}  ${U.ps('Association', assocs_to_delete.length)} `
			}

			text += '<div class="mt-4 mb-2"><b>Processing log:</b></div>'
			text += '<div class="k-case-item-importer-report"><div class="k-case-item-importer-report-inner">'
			for (let log_line of log) text += sr('<div style="margin-left:50px; text-indent:-50px;">$1</div>', log_line)
			text += '</div></div>'

			this.$confirm({
			    title: 'Processing Results',
			    text: text,
			    acceptText: acceptText,
				hideAccept: empty(acceptText),
				dialogMaxWidth: 950,
			}).then(y => {
				this.save_imported_assocs(assocs, assocs_to_delete)
			}).catch(n=>{console.log(n)}).finally(f=>{})

			console.log(assocs)
		},

		save_imported_assocs(assocs_data, assocs_to_delete) {
			// TODO: add framework-framework assoc if we aren't using a dedicated crosswalk framework?
			// 	this.add_framework_association(framework_to.json.CFDocument, assocs)

			// if we don't have any assocs to add...
			if (assocs_data.length == 0) {
				// then if we have assocs_to_delete, do those
				if (assocs_to_delete.length > 0) {
					// note that delete_associations takes care of updating the cfo associations_hash and the tree display hash
					let data = {framework_record: this.host_framework_record, associations_to_delete: assocs_to_delete}
					if (this.save_to_crosswalk_framework) data.check_out_and_in = 'yes'
					this.$store.dispatch('delete_associations', data).then(()=>{
						this.$alert('Association processing complete.')
					})
				} else {
					this.$alert('Association processing complete.')
				}				

			} else {
				let data = {
					lsdoc_identifier: this.host_framework_record.lsdoc_identifier,
					CFAssociations: assocs_data,
				}

				// if we're saving to crosswalk framework, do this so that we can avoid issues with the service complaining about archives
				if (this.save_to_crosswalk_framework) data.check_out_and_in = 'yes'

				// if we're saving to a brand new host_framework, we have to include the document of the host_framework
				if (empty(this.host_framework_record.json.CFDocument.lastChangeDateTime)) {
					let d = new CFDocument(this.host_framework_record.json.CFDocument)
					d.complete_data()	// adds '*NOW*' to lastChangeDateTime
					data.CFDocument = d.to_json()
				}

				console.log(this.host_framework_record, data)
				// return

				// make sure max-editor is closed
				this.ta_dialog_open = false

				///////////////////////////////////
				// Note that this is very similar to the end of the process in ItemCopyInterface.vue
				this.$store.dispatch('save_framework_data', data).then(()=>{
					// add CFAssociations to json
					for (let cfa of data.CFAssociations) {
						// update lastChangeDateTime json before pushing CFAssociation
						if (cfa.lastChangeDateTime == '*NOW*') {
							cfa.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime
						}

						this.$store.commit('set', [this.host_framework_record.json.CFAssociations, 'PUSH', cfa])

						// also add to associations_hash for the cfo
						U.update_associations_hash(this.host_framework_record.cfo, cfa)
					}

					// if we also have deletions, do those here
					if (assocs_to_delete.length > 0) {
						// note that delete_associations takes care of updating the cfo associations_hash and the tree display hash
						let data = {framework_record: this.host_framework_record, associations_to_delete: assocs_to_delete}
						if (this.save_to_crosswalk_framework) data.check_out_and_in = 'yes'
						this.$store.dispatch('delete_associations', data).then(()=>{
							this.$alert('Association processing complete.')
						})
					} else {
						this.$alert('Association processing complete.')
					}

					// when we're done here, call update_association_display, sending in the associated-to framework identifier, to re-calculate all the associations we should be showing and make sure we're showing this framework's associations
					vapp.case_tree_component.update_association_display({framework_id: this.right_framework_record.lsdoc_identifier, assoc: data.CFAssociations[0], actions: ['add', 'display']})
					// vapp.case_tree_component.update_frameworks_with_associations()
					// isRelatedTo: 41.01400 41.01400

				}).catch((e)=>{
					console.log(e)
					// in case of failure...
					this.$alert('Error saving imported associations')
				})
			}
		}
	}
}
</script>

<style lang="scss">
</style>
