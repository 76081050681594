<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
	<v-card class="mx-2 mb-8">
		<v-card-title class="py-2 pr-2 pl-5" style="border-bottom:1px solid #ccc; flex-wrap:nowrap;">
			<b style="white-space:nowrap">{{table_header_title}}<v-btn class="mx-2 k-tight-btn xk-nocaps-btn" x-small text color="primary" @click="cancel_table_mode"><v-icon small class="mr-1">fas fa-tree</v-icon>Return to Tree View</v-btn></b>
			<v-spacer></v-spacer>
			<v-btn small color="primary" class="mr-4 k-tight-btn" @click="filters_showing=!filters_showing"><v-icon small class="mr-2">fas fa-filter</v-icon>{{filters_showing?'Hide':'Show'}} Filters</v-btn>
			<v-btn small color="primary" @click="export_data" class="mr-4 k-tight-btn"><v-icon small class="mr-2">fas fa-file-arrow-down</v-icon>Export</v-btn>
			<v-btn-toggle dense active-class="k-toggle-btn-active-class" class="k-toggle-btn" v-model="table_mode" mandatory>
				<v-btn small light value="items" @click.stop="">Items</v-btn>
				<v-btn small light value="associations" @click.stop="$emit('switch_table')">Associations</v-btn>
			</v-btn-toggle>
			<!-- <v-btn fab x-small color="primary" class="ml-3" @click="cancel_table_mode"><v-icon>fas fa-tree</v-icon></v-btn> -->
		</v-card-title>
		<v-card-text style="font-size:16px; color:#000;">
			<div v-if="filters_showing" class="pt-2 pb-4 d-flex">
					<div class="ml-6 mr-8">
						<div class="mb-1"><b>Show item types:</b></div>
						<v-checkbox @change="update_items_table_types_to_show" v-for="(option) in item_type_select_options" :key="option" class="mt-0 pt-0" :label="option" v-model="items_table_types_to_show[option]" hide-details></v-checkbox>
						<div class="mt-2"><v-btn x-small color="secondary" @click="select_all">{{select_all_value?'Select':'Deselect'}} all</v-btn></div>
					</div>
					<div>
						<div class="mb-1"><b>Show fields:</b></div>
						<div class="d-flex">
							<div style="flex:0 0 1">
								<v-checkbox @change="update_items_table_fields_to_show" class="mt-0 pt-0" label="Identifier" v-model="items_table_fields_to_show.identifier" hide-details></v-checkbox>
								<v-checkbox @change="update_items_table_fields_to_show" class="mt-0 pt-0" label="URI" v-model="items_table_fields_to_show.uri" hide-details></v-checkbox>
								<v-checkbox @change="update_items_table_fields_to_show" class="mt-0 pt-0" label="Item Type" v-model="items_table_fields_to_show.itemType" hide-details></v-checkbox>
								<v-checkbox @change="update_items_table_fields_to_show" class="mt-0 pt-0" label="Tree Sequence" v-model="items_table_fields_to_show.tree_sequence" hide-details></v-checkbox>
								<v-checkbox @change="update_items_table_fields_to_show" class="mt-0 pt-0" label="Human-Readable Code" v-model="items_table_fields_to_show.humanCodingScheme" hide-details></v-checkbox>
								<v-checkbox @change="update_items_table_fields_to_show" class="mt-0 pt-0" label="Full Statement" v-model="items_table_fields_to_show.fullStatement" hide-details></v-checkbox>
								<v-checkbox @change="update_items_table_fields_to_show" class="mt-0 pt-0" label="Abbreviated Statement" v-model="items_table_fields_to_show.abbreviatedStatement" hide-details></v-checkbox>
								<v-checkbox @change="update_items_table_fields_to_show" class="mt-0 pt-0" label="Notes" v-model="items_table_fields_to_show.notes" hide-details></v-checkbox>
								<v-checkbox @change="update_items_table_fields_to_show" class="mt-0 pt-0" label="Supplemental Information" v-model="items_table_fields_to_show.supplementalNotes" hide-details></v-checkbox>
							</div>
							<div style="flex:0 0 1; margin-left:8px;">
								<v-checkbox @change="update_items_table_fields_to_show" class="mt-0 pt-0" label="Education Level" v-model="items_table_fields_to_show.educationLevel" hide-details></v-checkbox>
								<v-checkbox @change="update_items_table_fields_to_show" class="mt-0 pt-0" label="Language" v-model="items_table_fields_to_show.language" hide-details></v-checkbox>
								<!-- <v-checkbox @change="update_items_table_fields_to_show" class="mt-0 pt-0" v-model="items_table_fields_to_show.isSupplementalItem" hide-details><template v-slot:label>
									<div>Supplemental Branch<v-tooltip bottom><template v-slot:activator="{on}"><v-icon v-on="on" small color="light-blue" style="margin-left:4px;margin-top:-3px">fas fa-info-circle</v-icon></template><div style="font-size:14px; line-height:18px; max-width:400px;">Satchel extension: if true, this item and its children will not be exported by the CASE 1.0 or 1.1 API.</div></v-tooltip></div>
								</template></v-checkbox> -->
								<v-checkbox @change="update_items_table_fields_to_show" class="mt-0 pt-0" v-model="items_table_fields_to_show.sourceItemIdentifier" hide-details><template v-slot:label>
									<div><v-tooltip bottom><template v-slot:activator="{on}"><v-icon v-on="on" small color="light-blue" style="margin-right:4px;margin-top:-3px">fas fa-info-circle</v-icon></template><div style="font-size:14px; line-height:18px; max-width:400px;">Satchel extension: if an item is copied from another item, this field can be used to record the identifier (GUID) of the source item.</div></v-tooltip>Source Item Identifier</div>
								</template></v-checkbox>
								<v-checkbox @change="update_items_table_fields_to_show" class="mt-0 pt-0" v-model="items_table_fields_to_show.num_parents" hide-details><template v-slot:label>
									<div><v-tooltip bottom><template v-slot:activator="{on}"><v-icon v-on="on" small color="light-blue" style="margin-right:4px;margin-top:-3px">fas fa-info-circle</v-icon></template><div style="font-size:14px; line-height:18px; max-width:400px;">The number of times the item appears in the framework tree structure (almost always 1; a value greater than 1 indicates that the item is “aliased” multiple places in the framework).</div></v-tooltip># Parents</div>
								</template></v-checkbox>
								<v-checkbox @change="update_items_table_fields_to_show" class="mt-0 pt-0" v-model="items_table_fields_to_show.num_children" hide-details><template v-slot:label>
									<div><v-tooltip bottom><template v-slot:activator="{on}"><v-icon v-on="on" small color="light-blue" style="margin-right:4px;margin-top:-3px">fas fa-info-circle</v-icon></template><div style="font-size:14px; line-height:18px; max-width:400px;">The number of direct descendents the item has in the tree structure.</div></v-tooltip># Children</div>
								</template></v-checkbox>
								<v-checkbox @change="update_items_table_fields_to_show" class="mt-0 pt-0" label="Implementation Start Date" v-model="items_table_fields_to_show.statusStartDate" hide-details></v-checkbox>
								<v-checkbox @change="update_items_table_fields_to_show" class="mt-0 pt-0" label="Retirement Date" v-model="items_table_fields_to_show.statusEndDate" hide-details></v-checkbox>
								<v-checkbox @change="update_items_table_fields_to_show" class="mt-0 pt-0" label="Last Modified" v-model="items_table_fields_to_show.lastChangeDateTime" hide-details></v-checkbox>
							</div>
							<div v-if="instance_has_extensions" style="flex:0 0 1; margin-left:8px">
								<div><b style="color:#666; font-size:14px;">Extensions (custom fields):</b></div>
								<div v-for="(def, field) in extension_fields" :key="field" class="d-flex align-center" style="margin-top:2px">
									<v-checkbox @change="update_items_table_fields_to_show" class="mt-0 pt-0" v-model="items_table_fields_to_show[field]" hide-details></v-checkbox>
									<v-tooltip bottom><template v-slot:activator="{on}"><v-icon v-on="on" small color="light-blue" style="margin:-3px 4px 0 -2px">fas fa-info-circle</v-icon></template><div style="font-size:14px; line-height:18px; max-width:400px;" v-html="def.description||field"></div></v-tooltip>
									<span :style="(items_table_fields_to_show.metadata_filters[field]&&items_table_fields_to_show.metadata_filters[field]['something_set'])?'font-weight:bold':''">{{def.display_key||field}}</span>
									<v-menu right><template v-slot:activator="{on}"><v-btn v-if="def.legal_vals.length" v-on="on" icon small style="margin:-4px 0 0 -2px" :color="(items_table_fields_to_show.metadata_filters[field]&&items_table_fields_to_show.metadata_filters[field]['something_set'])?'#000':'#999'"><v-icon small>fas fa-eye</v-icon></v-btn></template>
										<v-list dense>
											<v-list-item><v-list-item-title><v-icon style="margin-right:8px; font-size:20px; cursor:pointer;">fas fa-circle-xmark</v-icon>Filter by values for <b>{{def.display_key||field}}:</b></v-list-item-title></v-list-item>
											<v-list-item v-for="(val) in def.legal_vals" :key="val"><v-list-item-title><v-checkbox @change="update_items_table_fields_to_show" @click.stop="" small class="mt-0 pt-0" :label="val?val:'[empty]'" v-model="items_table_fields_to_show.metadata_filters[field][val]" hide-details></v-checkbox></v-list-item-title></v-list-item>
											<v-list-item style="border-top:1px solid #ccc; height:32px; min-height:32px;">
												<v-btn x-small color="primary" class="k-tight-btn k-nocaps-btn" @click.stop="select_all_md_vals(field,false)">Select None</v-btn>
												<v-btn x-small color="primary" class="ml-2 k-tight-btn k-nocaps-btn" @click.stop="select_all_md_vals(field,true)">Select All</v-btn>
											</v-list-item>
										</v-list>
									</v-menu>
								</div>
							</div>
						</div>
					</div>
					<v-spacer/>
			</div>

			<div v-if="filter_description" class="text-center my-2" v-html="filter_description"></div>

			<v-data-table light dense ref="data_table"
				:headers="headers"
				:items="table_items"
				:custom-filter="table_search_filter"
				:search="search"
				:footer-props="footer_options"
				:items-per-page="10"
				:options="table_options"
				@update:items-per-page="update_items_per_page"
				class="k-framework-items-table"
			>
				<template v-slot:item="{ item }"><tr :class="row_class(item)">
					<td v-if="items_table_fields_to_show.identifier" class="px-1" style="font-size:9px!important;cursor:copy;" @click="copy_identifier(item)"><p><nobr>{{item.identifier}}</nobr></p></td>
					<td v-if="items_table_fields_to_show.uri" style="font-size:9px!important;"><p>{{item.uri}}</p></td>
					<td v-if="items_table_fields_to_show.itemType" class="text-center px-1"><p><nobr>{{item.item_type}}</nobr></p></td>
					<td v-if="items_table_fields_to_show.tree_sequence" class="text-center px-1"><p><nobr>{{item.tree_sequence}}</nobr></p></td>
					<td v-if="items_table_fields_to_show.humanCodingScheme"><p><nobr><b v-html="item.humanCodingScheme"></b></nobr></p></td>
					<td v-if="items_table_fields_to_show.fullStatement" :style="(big_fields_showing<=1)?'min-width:50%':'width:50%'" style="padding:0; min-width:300px;"><div class="k-items-table-item-cell" @click="item_clicked($event,item)" :class="item.color_class" v-html="item.statement_html"></div></td>
					<td v-if="items_table_fields_to_show.abbreviatedStatement"><p>{{item.abbreviatedStatement}}</p></td>
					<td v-if="items_table_fields_to_show.notes" :style="(big_fields_showing<=1)?'min-width:50%':'width:50%'" style="padding:0"><div class="k-items-table-item-cell" v-html="item.notes_html"></div></td>
					<td v-if="items_table_fields_to_show.supplementalNotes" :style="(big_fields_showing<=1)?'min-width:50%':'width:50%'" style="padding:0"><div class="k-items-table-item-cell" v-html="item.exemplar_html"></div></td>
					<td v-if="items_table_fields_to_show.educationLevel" class="text-center"><p>{{item.educationLevel}}</p></td>
					<td v-if="items_table_fields_to_show.language" class="text-center"><p>{{item.language}}</p></td>
					<td v-if="items_table_fields_to_show.isSupplementalItem" class="text-center px-1"><div class="k-items-table-item-cell" v-html="item.isSupplementalItem"></div></td>
					<td v-if="items_table_fields_to_show.sourceItemIdentifier" class="px-1" style="font-size:9px!important;"><nobr>{{item.sourceItemIdentifier}}</nobr></td>
					<td v-if="items_table_fields_to_show.num_parents" class="text-center"><p>{{item.num_parents}}</p></td>
					<td v-if="items_table_fields_to_show.num_children" class="text-center"><p>{{item.num_children}}</p></td>
					<td v-if="items_table_fields_to_show.statusStartDate"><p><nobr>{{item.statusStartDate}}</nobr></p></td>
					<td v-if="items_table_fields_to_show.statusEndDate"><p><nobr>{{item.statusEndDate}}</nobr></p></td>
					<td v-if="items_table_fields_to_show.lastChangeDateTime"><p><nobr>{{item.date_string}}</nobr></p></td>
					<td v-for="(o, field) in extension_fields" v-if="items_table_fields_to_show[field]"><p v-html="item[`ext:${field}`]"></p></td>
				</tr></template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: {  },
	props: {
		framework_record: { type: Object, required: true },
		starting_comment_id: { type: Number, required: false, default() { return -1 } },
		viewer: { required: false },
	},
	data() { return {
		table_mode: 'items',
		footer_options: {
			itemsPerPageOptions: [20,50,100],
		},
		table_options: {
			itemsPerPage: 50,
			page: 1,
		},
		filters_showing: false,
		items_table_types_to_show: {},	// this will be different for each framework
		items_table_fields_to_show: {identifier:false, uri:false, itemType:true, tree_sequence:true, humanCodingScheme:true, fullStatement:true, abbreviatedStatement:false, notes:false, supplementalNotes:false, educationLevel:true, language:false, isSupplementalItem: false, sourceItemIdentifier: false, num_parents:false, num_children:false, statusStartDate:false, statusEndDate:false, lastChangeDateTime:true},
		select_all_value: false,
	}},
	computed: {
		...mapState(['user_info', 'framework_records']),
		...mapGetters([]),
		table_header_title() {
			if (this.viewer.table_mode_start_node) {
				return U.generate_cfassociation_node_uri_title(this.viewer.table_mode_start_node.cfitem, 40)
			}
			return 'Framework Items'
		},
		search() { return this.viewer.search_terms },
		framework_identifier() { return this.framework_record.lsdoc_identifier },
		// item types may be different for each framework, so we have to construct a different set for each one
		item_type_select_options() {
			let arr = []
			// updated on 7/16 so that we don't list 'no item type' if all items in the framework have types
			// for (let item_type of this.framework_record.cfo.item_types) {
			for (let item_type in this.items_table_types_to_show) {
				arr.push(item_type)
			}
			// arr.push('[no item type]')
			return arr
		},
		extension_fields() { return this.viewer.extension_fields },
		instance_has_extensions() {
			// if we have at least one field in window.CASE_Custom_Extension_Fields.CFItem, we have extensions
			for (let field in this.extension_fields) return true
			return false
		},
		headers() {
			let arr = []
			if (this.items_table_fields_to_show.identifier) arr.push({text: 'Identifier', align: 'left', sortable: true, value: 'identifier'})
			if (this.items_table_fields_to_show.uri) arr.push({text: 'URI', align: 'left', sortable: true, value: 'uri'})
			if (this.items_table_fields_to_show.itemType) arr.push({text: 'Item Type', align: 'center', sortable: true, value: 'item_type'})
			if (this.items_table_fields_to_show.tree_sequence) arr.push({text: 'Sequence', align: 'center', sortable: true, value: 'tree_sequence'})
			if (this.items_table_fields_to_show.humanCodingScheme) arr.push({text: 'Human Code', align: 'left', sortable: true, value: 'humanCodingScheme'})
			if (this.items_table_fields_to_show.fullStatement) arr.push({text: 'Full Statement', align: 'left', sortable: true, value: 'fullStatement'})
			if (this.items_table_fields_to_show.abbreviatedStatement) arr.push({text: 'Abbreviated Statement', align: 'left', sortable: true, value: 'abbreviatedStatement'})
			if (this.items_table_fields_to_show.notes) arr.push({text: 'Notes', align: 'left', sortable: true, value: 'notes'})
			if (this.items_table_fields_to_show.educationLevel) arr.push({text: 'Ed. Level', align: 'center', sortable: true, value: 'educationLevel'})
			if (this.items_table_fields_to_show.supplementalNotes) arr.push({text: 'Supp. Notes', align: 'center', sortable: true, value: 'supplementalNotes'})
			if (this.items_table_fields_to_show.language) arr.push({text: 'Language', align: 'left', sortable: true, value: 'language'})
			if (this.items_table_fields_to_show.isSupplementalItem) arr.push({text: 'Supp. Branch', align: 'center', sortable: true, value: 'isSupplementalItem'})
			if (this.items_table_fields_to_show.sourceItemIdentifier) arr.push({text: 'Src. Item ID', align: 'left', sortable: true, value: 'sourceItemIdentifier'})
			if (this.items_table_fields_to_show.num_parents) arr.push({text: '# Parents', align: 'center', sortable: true, value: 'num_parents'})
			if (this.items_table_fields_to_show.num_children) arr.push({text: '# Children', align: 'center', sortable: true, value: 'num_children'})
			if (this.items_table_fields_to_show.statusStartDate) arr.push({text: 'Imp. Start Date', align: 'left', sortable: true, value: 'statusStartDate'})
			if (this.items_table_fields_to_show.statusEndDate) arr.push({text: 'Retirement Date', align: 'left', sortable: true, value: 'statusEndDate'})
			if (this.items_table_fields_to_show.lastChangeDateTime) arr.push({text: 'Last Modified', align: 'left', sortable: true, value: 'lastChangeDateTime'})
			for (let field in this.extension_fields) {
				let def = this.extension_fields[field]
				if (this.items_table_fields_to_show[field]) arr.push({text: def.display_key||field, align: 'left', sortable: true, value: `ext:${field}`})
			}
			return arr
		},
		// get list of orphaned items...
		orphaned_items() {
			let arr = []
			for (let cfitem of this.framework_record.json.CFItems) {
				let cfo_item = this.framework_record.cfo.cfitems[cfitem.identifier]
				if (!cfo_item || cfo_item.tree_nodes.length == 0) arr.push(cfitem)
			}
			return arr
		},

		table_items() {
			// if we're not actually showing the table, don't take the time to compute the table_items
			if (this.$store.state.lst.viewer_mode != 'table' || this.$store.state.lst.viewer_table_mode != 'items') return

			let arr = []

			let tree_sequence = [0]
			let add_item = (o, level) => {
				// o could be a node or a CFItem (from the original json)...
				let node, cfitem, tree_sequence_value
				if (o.tree_key) {
					node = o
					cfitem = node.cfitem

					++tree_sequence[level]
					tree_sequence_value = ''
					for (let i = 0; i < 30; ++i) {
						if (i <= level) {
							if (tree_sequence_value) tree_sequence_value += '.'
							tree_sequence_value += tree_sequence[i]
						} else {
							tree_sequence[i] = 0
						}
					}

				} else {
					cfitem = o
					tree_sequence_value = ''
				}

				let item_type = U.item_type_string(cfitem)

				// skip item_types that aren't checked to be included
				let include = true
				if (empty(item_type) && !this.items_table_types_to_show['[no item type]']) include = false
				if (!empty(item_type) && !(this.items_table_types_to_show[item_type])) include = false

				let mf_include = true
				for (let field in this.items_table_fields_to_show.metadata_filters) {
					// console.warn('checking ' + field)
					let def = this.extension_fields[field]
					let mf = this.items_table_fields_to_show.metadata_filters[field]
					// if something is set in this field, we need to exclude unless the item matches a set value...
					if (mf.something_set) {
						mf_include = false
						for (let val in mf) if (val != 'something_set' && mf[val] == true) {
							// if the empty val is set, include if this item doesn't have the metadata field, or if it's set to empty
							if (val == '') {
								if (!cfitem.extensions || empty(cfitem.extensions[field])) { mf_include = true; break; }
							} else {
								// else include if the item has this metadata field set to val
								if (cfitem.extensions && cfitem.extensions[field]) {
									// for array, use includes
									if (def.type == 'array') {
										if (cfitem.extensions[field].includes(val)) { mf_include = true; break; }

									// else check value directly
									} else if (cfitem.extensions[field] == val) { mf_include = true; break; }
									// TODO: if we want to support object fields, have to decide what to do here
								}
							}
						}
						if (!mf_include) break
					}
				}
				if (!mf_include) include = false

				// include this item if it meets criteria above
				if (include) {
					// item type color
					let color_class
					let item_type_level = this.framework_record.cfo.item_type_levels.find(x=>x.item_type == item_type)
					if (item_type_level) {
						color_class = 'k-case-tree-item-type-color-' + item_type_level.level_index
					} else {
						color_class = 'k-case-tree-item-type-color-neutral'
					}

					let num_children = (node) ? node.children.length : 0
					let num_parents = (node) ? node.cfitem.tree_nodes.length : 0

					let statement_html = ''
					// start with an icon for the item
					if (num_children > 0) {
						statement_html = '<i class="fas fa-chevron-circle-right grey--text text--darken-0" style="margin-right:3px"></i>'
					} else {
						statement_html = '<i class="fas fa-dot-circle grey--text text--darken-0" style="margin-right:3px"></i>'
					}

					statement_html = statement_html + cfitem.fullStatement

					// add icon if this is an orphaned item
					if (num_parents == 0) {
						statement_html = '<i class="mr-1 red--text fas fa-triangle-exclamation"></i>' + statement_html
					}
					statement_html = U.marked_latex(statement_html)
					let statement_string = U.html_to_text(statement_html)

					let notes_html = U.marked_latex(cfitem.notes ? cfitem.notes : '')
					let notes_string = U.html_to_text(notes_html)

					let exemplar_html = U.render_latex(cfitem.extensions?.supplementalNotes ? cfitem.extensions.supplementalNotes : '')
					let exemplar_string = U.html_to_text(exemplar_html)

					let o = {
						cfitem: cfitem,
						node: node,
						tree_sequence: tree_sequence_value,
						identifier: cfitem.identifier,
						uri: cfitem.uri,
						item_type: item_type,
						color_class: color_class,
						humanCodingScheme: (cfitem.humanCodingScheme) ? cfitem.humanCodingScheme : '',
						fullStatement: cfitem.fullStatement,
						statement_html: statement_html,
						statement_string: statement_string,
						abbreviatedStatement: (cfitem.abbreviatedStatement) ? cfitem.abbreviatedStatement : '',
						notes: cfitem.notes,
						notes_html: notes_html,
						notes_string: notes_string,
						exemplar_html: exemplar_html,
						exemplar_string: exemplar_string,
						educationLevel: (cfitem.educationLevel) ? U.grade_level_display(cfitem.educationLevel) : '',
						language: (cfitem.language) ? cfitem.language : '',
						isSupplementalItem: cfitem.extensions?.isSupplementalItem,
						sourceItemIdentifier: cfitem.extensions?.sourceItemIdentifier,
						statusStartDate: cfitem.statusStartDate ?? '',
						statusEndDate: cfitem.statusEndDate ?? '',
						lastChangeDateTime: cfitem.lastChangeDateTime,
						date_string: U.local_last_change_date_time(cfitem.lastChangeDateTime),

						num_children: num_children,
						num_parents: num_parents,
					}

					// extension values that user has chosen to include: put these in fields `ext:xxx` that will be rendered as a td each
					for (let field in this.extension_fields) {
						let key = `ext:${field}`
						if (this.items_table_fields_to_show[field]) {
							let val = cfitem.extensions ? cfitem.extensions[field] : ''

							// if val is empty, push an empty string
							if (empty(val)) {
								o[key] = ''
								continue
							}

							// val is not empty; do some formatting on field values
							if (this.extension_fields[field].type == 'array' && $.isArray(val)) o[key] = val.join(', ')
							else if (this.extension_fields[field].type == 'object' && typeof(val) == 'object') {
								let s = ''
								for (let subfield in val) {
									if (!empty(s)) s += '; '
									s += `${subfield}: ${val[subfield]}`
								}
								o[key] = s
							// for booleans, strings, and numbers, just use a string representation of the number
							} else o[key] = val + ''
						}
					}
					arr.push(o)
				}

				// then process the item's children if we have a node
				if (node) {
					for (let child_node of node.children) {
						add_item(child_node, level + 1)
					}
				}
			}

			// if we're showing the whole tree...
			if (this.viewer.table_mode_start_node == null) {
				// start with orphaned items (if any), so they'll appear at the top
				for (let cfitem of this.orphaned_items) {
					add_item(cfitem, 0)
				}

				// then recursively process each child of the tree (not the document)
				for (let child_node of this.framework_record.cfo.cftree.children) {
					add_item(child_node, 0)
				}

			} else {
				// for showing a node, we start with the node itself
				add_item(this.viewer.table_mode_start_node)
			}

			return arr
		},
		big_fields_showing() {
			let n = 0
			if (this.items_table_fields_to_show.fullStatement) ++n
			if (this.items_table_fields_to_show.notes) ++n
			if (this.items_table_fields_to_show.supplementalNotes) ++n
			return n
		},
		filter_description() {
			let s = ''
			let all_item_types = true
			let its = ''
			for (let item_type in this.items_table_types_to_show) {
				if (this.items_table_types_to_show[item_type]) {
					if (its) its += ', '
					its += item_type
				} else all_item_types = false
			}
			if (!all_item_types) s = `<b>Item types:</b> ${its ? its : 'none'}`

			for (let field in this.items_table_fields_to_show.metadata_filters) {
				let ms = ''
				let def = this.extension_fields[field]
				if (!def) continue

				let mf = this.items_table_fields_to_show.metadata_filters[field]
				if (mf.something_set) {
					for (let val in mf) if (val != 'something_set' && mf[val] == true) {
						if (ms) ms += ', '
						ms += val
					}
				}
				if (ms) {
					if (s) s += '; '
					s += `<b>${def.display_key?def.display_key:field}:</b> ${ms}`
				}
			}

			if (!s) return ''

			return `<b class="pink--text text--darken-2 mr-2" style="font-size:14px">FILTERING BY</b>${s}`
		},
	},
	watch: {
	},
	created() {
		vapp.items_table_component = this
	},
	mounted() {
		// set itemsPerPage to value from store
		this.table_options.itemsPerPage = this.$store.state.lst.items_table_items_per_page
		this.set_items_table_types_to_show()

		// start with items_table_fields_to_show from data, which includes the fields that can be shown based on the current state of the functionality
		let itfts = object_copy(this.items_table_fields_to_show)

		// add values for extensions, initializing metadata_filter as we go
		itfts.metadata_filters = {}
		for (let field in this.extension_fields) {
			itfts[field] = false
			
			let def = this.extension_fields[field]
			// if this metadata field has legal vals, set up a metadata_filter for the field
			if (def.legal_vals.length > 0) {
				itfts.metadata_filters[field] = {something_set: false}
				for (let val of def.legal_vals) {
					itfts.metadata_filters[field][val] = false
				}
			}
		}

		// if we have previously-stored values, merge them with the structure we created above
		if (this.$store.state.lst.items_table_fields_to_show) {
			let prev_itfts = JSON.parse(this.$store.state.lst.items_table_fields_to_show)
			// for each field in the new itfts...
			for (let field in itfts) {
				// if this field wasn't in the previous itfts, continue
				if (prev_itfts[field] === undefined) continue

				// for actual fields, copy the value in from prev_itfts
				if (field != 'metadata_filters') {
					itfts[field] = prev_itfts[field]

				// if/when we get to metadata_filters, process it...
				} else {
					// go through each field in the previous filters
					for (let mfield in prev_itfts.metadata_filters) {
						// if we're no longer using this field (i.e. this extension field has been removed from the instance), skip it
						if (itfts.metadata_filters[mfield] === undefined) continue

						// else copy in the values from the previous filter, taking care because the possible values might have been updated
						let def = this.extension_fields[mfield]
						if (def.legal_vals.length > 0) {	// this should be true; if it wasn't we wouldn't have created the metadata_filter property above
							let something_set = false
							for (let val of def.legal_vals) {
								// itfts.metadata_filters[mfield][val] has already been created and set to false; if it was previously set to true, set here.
								if (prev_itfts.metadata_filters[mfield][val] == true) {
									itfts.metadata_filters[mfield][val] = true
									something_set = true
								}
							}
							itfts.metadata_filters[mfield]['something_set'] = something_set
						}
					}
				}
			}
		}

		// // add values for extensions, initializing metadata_filter as we go
		// if (!itfts.metadata_filters) itfts.metadata_filters = {}
		// let mfs = itfts.metadata_filters
		// for (let field in this.extension_fields) {
		// 	if (!(field in itfts)) {
		// 		itfts[field] = false
		// 	}
			
		// 	let def = this.extension_fields[field]
		// 	if (def.legal_vals.length > 0) {
		// 		if (empty(mfs[field])) mfs[field] = {}
		// 		let something_set = false
		// 		for (let val of def.legal_vals) {
		// 			mfs[field][val] = (mfs[field][val] == true)
		// 			if (mfs[field][val]) something_set = true
		// 		}
		// 		mfs[field]['something_set'] = something_set
		// 	}
		// }

		// we are no longer showing the isSupplementalItem option, but we haven't taken the code out yet, so set this to false
		itfts.isSupplementalItem = false

		console.log(object_copy(itfts))
		this.items_table_fields_to_show = itfts
	},
	methods: {
		set_items_table_types_to_show() {
			// items_table_types_to_show is different for each framework, since different frameworks have different sets of item types
			// try to get items_table_types_to_show from lst
			let lst_o = {}
			if (this.$store.state.lst.items_table_types_to_show) {
				lst_o = JSON.parse(this.$store.state.lst.items_table_types_to_show)
			}

			// supplement or create from the list of item_types we have for the framework
			let o = lst_o[this.framework_identifier] ? lst_o[this.framework_identifier] : {}
			for (let item_type of this.framework_record.cfo.item_types) {
				if (o[item_type] === undefined) o[item_type] = true
			}

			// see if we have any items with no item types
			let i = this.framework_record.json.CFItems.find(x=>empty(x.CFItemType) && (empty(x.CFItemTypeURI) || empty(x.CFItemTypeURI?.identifier)))
			if (i) {
				if (o['[no item type]'] === undefined) o['[no item type]'] = true
			} else {
				delete o['[no item type]']
			}

			// and set
			this.items_table_types_to_show = o
		},

		// select/deselect all association types
		select_all() {
			for (let key in this.items_table_types_to_show) {
				this.items_table_types_to_show[key] = this.select_all_value
			}
			this.update_items_table_types_to_show()
		},

		update_items_table_types_to_show() {
			let lst_o = {}
			if (this.$store.state.lst.items_table_types_to_show) {
				lst_o = JSON.parse(this.$store.state.lst.items_table_types_to_show)
			}
			// set the value in lst for this framework
			lst_o[this.framework_identifier] = this.items_table_types_to_show
			this.$store.commit('lst_set', ['items_table_types_to_show', JSON.stringify(lst_o)])

			console.log('update_items_table_types_to_show')
			// set select_all_value if necessary
			let all_selected = true
			let all_deselected = true
			for (let key in this.items_table_types_to_show) {
				if (this.items_table_types_to_show[key]) all_deselected = false
				else all_selected = false
			}
			if (all_selected) this.select_all_value = false
			if (all_deselected) this.select_all_value = true
		},

		update_items_table_fields_to_show() {
			// for each metadata_filter, determine if any values are set
			for (let field in this.items_table_fields_to_show.metadata_filters) {
				let mf = this.items_table_fields_to_show.metadata_filters[field]
				mf.something_set = false
				for (let val in mf) if (val != 'something_set' && mf[val]) {
					mf.something_set = true
					break
				}
			}
			this.$store.commit('lst_set', ['items_table_fields_to_show', JSON.stringify(this.items_table_fields_to_show)])
		},

		update_items_per_page(val) {
			// when the user chooses a different items-per-page value, save in store so we can restore that number when the table re-opens later
			this.$store.commit('lst_set', ['items_table_items_per_page', val])
		},

		row_class(item) {
			return ''
		},

		table_search_filter(value, search, item) {
			// value is the value of the column (we can ignore this); search is the search string (could be empty)
			// RETURN FALSE TO HIDE THE ITEM

			// if search is empty, always return true, so the row will SHOW
			if (empty(search)) return true

			search = search.toLowerCase()
			let re = new RegExp(search, 'i')

			if (this.items_table_fields_to_show.identifier && item.identifier.search(re) > -1) return true
			if (this.items_table_fields_to_show.uri && item.uri.search(re) > -1) return true
			if (this.items_table_fields_to_show.itemType && item.item_type.search(re) > -1) return true
			if (this.items_table_fields_to_show.humanCodingScheme && item.humanCodingScheme.search(re) > -1) return true
			if (this.items_table_fields_to_show.fullStatement && item.statement_string.search(re) > -1) return true
			if (this.items_table_fields_to_show.abbreviatedStatement && item.abbreviatedStatement.search(re) > -1) return true
			if (this.items_table_fields_to_show.notes && item.notes_string.search(re) > -1) return true
			if (this.items_table_fields_to_show.supplementalNotes && item.exemplar_string.search(re) > -1) return true
			if (this.items_table_fields_to_show.educationLevel && item.educationLevel.search(re) > -1) return true
			if (this.items_table_fields_to_show.language && item.language.search(re) > -1) return true
			if (this.items_table_fields_to_show.statusStartDate && item.statusStartDate.search(re) > -1) return true
			if (this.items_table_fields_to_show.statusEndDate && item.statusEndDate.search(re) > -1) return true
			if (this.items_table_fields_to_show.lastChangeDateTime && item.date_string.search(re) > -1) return true

			// search for extensions
			for (let field in this.extension_fields) {
				if (this.items_table_fields_to_show[field]) {
					let key = `ext:${field}`
					if (item[key].search(re) > -1) return true
				}
			}

			// if we get to here return false
			return false
		},

		copy_identifier(item) {
			U.copy_to_clipboard(item.identifier)
			this.$inform('Identifier copied to clipboard')
		},

		item_clicked(evt, o) {
			let switched_to_tree = false

			if (o.node) {
				// do the same thing we would do if we'd found the item in a search and clicked on it
				this.$emit('search_result_clicked', {tree_key:o.node.tree_key})

				// open in tree view if cmd key is held down
				if (U.meta_or_alt_key(evt)) {
					this.$emit('switch_to_tree')
					switched_to_tree = true
				}
			}

			if (U.meta_or_alt_key(evt)) {
				if (!switched_to_tree) {
					this.$inform('That item cannot be opened in this framework’s tree view.')
				}

			} else if (o.num_parents == 0) {
				// this is an orphaned item
				this.$alert('This item is “orphaned”: it does not have an isChildOf association tying it to a parent item in the framework’s tree structure. You can remove orphaned items by editing the framework, then choosing “Clean Framework JSON” from the EDIT FRAMEWORK menu, then choosing the ‘Remove “orphans”’ option.')
			} else {
				// show the item in the expanded view
				this.viewer.show_expanded_item(o.node, this.framework_record)
			}
		},

		select_all_md_vals(field, bool) {
			for (let val in this.items_table_fields_to_show.metadata_filters[field]) {
				this.items_table_fields_to_show.metadata_filters[field][val] = bool
			}
			this.update_items_table_fields_to_show()
		},

		export_data() {
			if (vapp.signed_in_only('export CASE data in CSV format')) return

			// filteredItems would in theory give us items that are also filtered through table_search_filter, but this would be hackish, and it's not clear at this time if that would be more useful than getting the "raw" items list
			// let items = this.$refs.data_table.$children[0].filteredItems
			let items = this.table_items
			let msg = sr('This will export a CSV spreadsheet with the currently-visible columns for the $1 $2 that match the current table filters. Items will be exported in “Tree Sequence” order.', items.length, U.ps('item', items.length))
			this.$confirm({
			    title: 'Export Item Data',
			    text: msg,
			    acceptText: 'Proceed',
				dialogMaxWidth: 600,
				focusBtn: true,		// focus on the accept btn when dialog is rendered
			}).then(y => {
				// construct array to export
				let arr = [[]]

				// start with headers row
				for (let header of this.headers) arr[0].push(header.text)

				// now a row for each item
				for (let row of items) {
					let rarr = []
					for (let header of this.headers) {
						rarr.push(row[header.value])
					}
					arr.push(rarr)
				}

				let filename = sr('$1-CASE-items.csv', this.framework_record.json.CFDocument.title)
				U.download_file(CSV.stringify(arr), filename)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		cancel_table_mode() { this.viewer.cancel_tile_mode() },
	}
}
</script>

<style lang="scss">
.k-framework-items-table {
	margin-top:8px;
	th {
		white-space:nowrap;
	}
	td {
		font-size:12px!important;
		line-height:16px;
		vertical-align:top;
		padding-top:2px!important;
		padding-bottom:2px!important;
		// height:20px!important;
		// border-color:transparent!important;
		p {
			margin:4px 0;
		}
	}

	.k-items-table-item-cell {
		padding:1px 6px;
		border-radius:8px;
	}

	.k-items-table-item-cell {
		padding:1px 6px;
		border-radius:8px;
		cursor:pointer;
	}

	.k-items-table-item-cell:hover {
		text-decoration:underline;
	}

}
</style>
