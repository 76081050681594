<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="k-case-tree-scroll-wrapper" :class="scroll_wrapper_and_font_size_class" v-scroll.self="tree_scrolled" :style="tree_scroll_wrapper_style">
	<div class="k-case-tree" :class="top_css_class + (crosswalk ? '' : '  elevation-1')" :style="crosswalk ? 'padding-top: 0;' : ''" @click="tree_clicked">

		<div v-if="show_doc_switcher" class="d-flex align-top mb-2">
			<div class="k-case-tree-multiple-docs-doc-showing">Showing document tree <b>{{doc_showing.title}}</b></div>
			<v-spacer />
			<v-menu :transition="false" bottom left><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="#999" dark class="ml-2"><v-icon x-small class="mr-1">fas fa-random</v-icon>Switch to…</v-btn></template>
				<v-list dense>
					<v-list-item @click="switch_to_document_tree(home_framework_record.json.CFDocument)"><v-list-item-title>{{home_framework_record.json.CFDocument.title}}</v-list-item-title></v-list-item>
					<v-list-item v-for="(doc) in home_framework_record.cfo.associated_documents" :key="doc.identifier" @click="switch_to_document_tree(doc)"><v-list-item-title>{{doc.title}}</v-list-item-title></v-list-item>
				</v-list>
			</v-menu>
		</div>

		<div v-if="batch_editing" class="mb-2" style="font-size:14px; font-weight:bold; color:#666;"><span>{{checked_items_count}} tree node(s) selected</span> <v-btn v-if="checked_items_count" class="ml-2" x-small text color="secondary" @click="uncheck_all_items">Uncheck all items</v-btn></div>

		<CASEItem v-if="!blob_mode" class="k-case-tree-document-node-item" :class="doc_item_class" :key="cftree.tree_key" :home_framework_record="home_framework_record" :item="cftree" :viewer="viewer" :open_nodes_override="open_nodes_override" :featured_node_override="featured_node_override" :chosen_items="chosen_items" :show_checkbox_fn="show_checkbox_fn" :show_chooser_fn="show_chooser_fn" />

		<div v-if="!blob_mode" :class="child_items_class">
			<draggable :disabled="!viewer.item_move_drag_options" v-bind="viewer.item_move_drag_options" v-model="draggable_children" @end="show_move_fn" :data-move-parent-key="cftree.tree_key">
				<CASEItem v-for="(item) in cftree.children" :key="item.tree_key" :home_framework_record="home_framework_record" :item="item" :viewer="viewer" :open_nodes_override="open_nodes_override" :featured_node_override="featured_node_override" :chosen_items="chosen_items" :show_checkbox_fn="show_checkbox_fn" :show_move_fn="show_move_fn" :show_chooser_fn="show_chooser_fn" :highlighted_identifier_override="highlighted_identifier_override" :crosswalk="crosswalk" />
			</draggable>
			<div v-if="cftree.children.length==0" class="pl-8 py-2" style="font-size:16px"><i>This framework does not include any items. <span v-if="show_doc_switcher">However, the framework includes one or more associations with other frameworks. Click “SWITCH TO…” above to view the items from an associated framework.</span></i></div>
		</div>

		<CASEBlobItem v-if="blob_mode" :framework_record="framework_record" :item="cftree" :viewer="viewer" :show_checkbox_fn="show_checkbox_fn" :show_chooser_fn="show_chooser_fn" />
	</div>

	<div class="k-case-tree-pinned-items-wrapper" v-if="!$vuetify.breakpoint.xs && maximized && pinned_item_nodes.length>0">
		<CASEItemTile v-for="(item) in pinned_item_nodes" :key="item.tree_key" :home_framework_record="home_framework_record" :item="item" :viewer="viewer" :pinned_tile="true" />
	</div>

	<div class="k-case-tree-active-item-wrapper" v-if="render_tile" v-show="show_tile"><div class="k-case-tree-active-item-tile-wrapper">
		<CASEItemTile ref="item_tile" :home_framework_record="home_framework_record" :item="active_item" :viewer="viewer" />
	</div></div>
</div></template>

<script>
import CASEItem from './CASEItem'
import CASEBlobItem from './CASEBlobItem'
import CASEItemTile from './CASEItemTile'
import draggable from 'vuedraggable'

import { mapState, mapGetters } from 'vuex'
export default {
	components: { CASEItem, CASEBlobItem, CASEItemTile, draggable },
	props: {
		home_framework_record: { type: Object, required: true },
		viewer: { required: false, default() { return {} }},
		show_checkbox_fn: { required: false, default() { return false }},
		show_move_fn: { required: false, default() { return false }},
		show_chooser_fn: { required: false, default() { return false }},
		// this can be used to "highlight" a certain item when not in the viewer context (i.e. when showing a tree on the right to highlight the item associated with a certain item on the left tree)
		highlighted_identifier_override: { type: String, required: false, default() { return '' }},
		open_nodes_override: { required: false, default() { return null }},
		featured_node_override: { required: false, default() { return '' }},
		chosen_items: { required: false, default() { return [] }},
		crosswalk: { required: false, default() { return null } },
		tree_scroll_wrapper_style: { required: false, default() { return '' } },
	},
	data() { return {
	}},
	computed: {
		...mapState(['framework_records', 'embedded_mode', 'embedded_mode_chooser']),
		...mapGetters([]),
		in_viewer_context() { return !empty(this.viewer.lsdoc_identifier) },

		framework_record() {
			if (!this.in_viewer_context || this.aligning_resources || this.making_associations) return this.home_framework_record

			// framework A may have associations with objects in framework B. if so, the user can choose to show framework B's tree if we're in the viewer context
			// so CASETree receives the home_framework_record (framework A), then determines which tree to show based on document_identifier_showing_in_tree
			return this.framework_records.find(x=>x.lsdoc_identifier == this.home_framework_record.document_identifier_showing_in_tree)
		},
		viewing_home_framework() { return this.framework_record == this.home_framework_record },

		blob_mode() { return this.viewer && this.viewer.viewer_mode == 'tiles' },
		maximized() { return this.viewer.maximized },
		edited_node() { return this.viewer.edited_node },
		editing_document() { return this.viewer.editing_document },
		batch_editing() { return this.viewer.show_checkbox_fn && this.viewer.show_checkbox_fn != false },
		moving() { return this.viewer.show_move_fn != false },
		making_associations() { return this.viewer.current_editor && this.viewer.current_editor.make_association },	// this is pretty hackish
		aligning_resources() { return this.viewer.current_editor && this.viewer.current_editor.make_alignment },	// this is pretty hackish
		showing_associated_item_tree() { return this.viewer.association_framework_tree_identifier != null },
		active_node() { return this.framework_record.active_node },
		active_item() { return this.framework_record.cfo.tree_nodes_hash[this.active_node] },
		featured_node() {
			if (this.featured_node_override) return this.featured_node_override
			else return this.framework_record.featured_node
		},
		pinned_items() { return this.framework_record.pinned_items },
		pinned_item_nodes() {
			let arr = []
			for (let tree_key of this.pinned_items) {
				let node = this.framework_record.cfo.tree_nodes_hash[tree_key]
				if (empty(node)) {
					// if this node no longer exists (maybe the pinned item was deleted), remove from pinned_items
					// (note that code in CASEItemTile also deals with this lst property)
					this.$store.commit('splice_from_array', [this.framework_record.pinned_items, tree_key])
					let s = this.$store.state.lst.pinned_items
					let o = (s) ? JSON.parse(s) : {}
					o[this.framework_record.lsdoc_identifier] = this.framework_record.pinned_items
					this.$store.commit('lst_set', ['pinned_items', JSON.stringify(o)])

				} else {
					arr.push(this.framework_record.cfo.tree_nodes_hash[tree_key])
				}
			}
			return arr
		},
		cftree() { return this.framework_record.cfo.cftree },
		font_size() {
			// for now, at least, always use the same font size in embedded mode
			if (this.embedded_mode) return -1

			if (this.$vuetify.breakpoint.xs) return this.$store.state.lst.font_size_xs
			else if (this.$vuetify.breakpoint.sm) return this.$store.state.lst.font_size_sm
			else return this.$store.state.lst.font_size
		},
		scroll_wrapper_and_font_size_class() {
			let s = ' k-case-tree-font-size-' + this.font_size	// see CASEItem
			if (this.blob_mode) {
				s += ' k-case-tree-blob'
				if (this.pinned_items.length > 0) {
					s += ' k-case-tree-blob-with-pinned-items'
				}

			} else {
				// if we're not on a small screen, determine whether or not to compress the tree, leaving room for other things
				if (!this.$vuetify.breakpoint.xs) {
					if (this.framework_record.active_node || this.edited_node || this.editing_document || this.batch_editing || this.moving || this.highlighted_identifier_override || this.showing_associated_item_tree) {
						s += ' k-case-tree-active-node-showing'
					}
				}
			}
			return s
		},
		top_css_class() {
			let s = ''

			if (this.batch_editing) {
				s += ' pl-2'	// leave extra padding on the left for checkboxes when in batch_editing mode; also note the extra pl-6 class added in the divs above
			}

			if (this.in_viewer_context && !this.viewing_home_framework) s += ' k-case-tree-bordered ' + U.framework_color(this.framework_record.json.CFDocument.identifier) + '-border'

			// if a node is featured, dim all nodes EXCEPT descendents and ancestors of the featured node
			if (this.featured_node) {
				s += ' k-case-tree-featured-selected'
			}

			return s
		},
		doc_item_class() {
			if (this.embedded_mode) return ''
			if (this.batch_editing || this.show_chooser_fn) return 'pl-6'
			return ''
		},
		child_items_class() {
			if (this.embedded_mode_chooser) return 'pl-2'
			if (this.batch_editing || this.show_chooser_fn) return 'pl-6'
			return ''
		},
		show_tile_with_tree() {
			// if (this.embedded_mode) {
			// 	if (this.$vuetify.breakpoint.sm) return false
			// } else {
				if (this.$vuetify.breakpoint.xs) return false
			// }
			return true
		},
		render_tile() {
			if (this.blob_mode) return false
			if (!this.show_tile_with_tree) return false
			if (!this.maximized) return false
			if (!this.active_node || !this.active_item) return false
			if (this.batch_editing) return false
			if (this.moving) return false
			if (this.making_associations) return false
			if (this.aligning_resources) return false
			if (this.showing_associated_item_tree) return false
			return true
		},
		show_tile() {
			if (this.active_item == this.edited_node) return false
			if (this.active_node == 1 && this.editing_document) return false
			return true
		},
		draggable_children: {
			get() { return this.cftree.children },
			set(new_children_array) {
				// console.log('setting draggable_children', new_children_array)
				// // this.$store.commit('item_moved', [this.cftree, new_children_array])
				// this.$store.commit('set', [this.cftree, 'children', new_children_array])
			}
		},
		checked_items_count() {
			return Object.keys(this.framework_record.checked_items).length
		},
		show_doc_switcher() {
			// For now let's not show this...
			return false

			if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) return false	// no switcher on phone
			if (this.making_associations || this.aligning_resources) return false
			return this.in_viewer_context && this.home_framework_record.cfo.associated_documents.length > 0
		},
		doc_showing() {
			if (this.home_framework_record.document_identifier_showing_in_tree == this.home_framework_record.json.CFDocument.identifier) {
				return this.home_framework_record.json.CFDocument
			} else {
				return this.home_framework_record.cfo.associated_documents.find(x=>x.identifier == this.home_framework_record.document_identifier_showing_in_tree)
			}
		},
	},
	created() {
	},
	mounted() {
		vapp.case_tree_tree = this
	},
	umounted() {
	},
	watch: {
		active_node: { immediate: true, handler(val) {
			// when the active node changes, scroll the tree and position the tile to try to make it even with the node in the tree
			// see companion function tree_scrolled in CASEFrameworkViewer

			// do this after a short delay so the tile is showing and we know its height and position
			setTimeout(x=>this.position_tile_and_scroll_list(3), 30)
		}}
	},
	methods: {
		tree_scrolled() {
			this.$emit('tree_scrolled')
		},

		tree_clicked() {
			if (this.viewer && this.viewer.search_panel_to_back) this.viewer.search_panel_to_back()
		},
		
		position_tile_and_scroll_list(try_agains) {
			if (typeof(try_agains) != 'number') try_agains = 0

			// console.log('position_tile_and_scroll_list', this.active_node)
			if (!this.viewer.active_item_position || !this.active_node || $(sr('[data-tree-key=$1]', this.active_node)).length == 0) {
				// console.log('position_tile_and_scroll_list: can’t do it')
				return
			}

			let [tile_jq, item_top, tile_top] = this.viewer.active_item_position()

			// console.log(sr('position_tile_and_scroll_list: $1 / $2', item_top, tile_top))

			// NOTE: if we muck with scroll overflows, this might need to be updated...
			let $iwjq = $(this.$el)

			// if the tile is showing inline, ...
			if (!this.maximized || this.$vuetify.breakpoint.xs) {
				// item_top will represent the place where the item appears in the tree (because the tile is inline)
				// so just make sure item_top is on the screen
				let scroll_top = $iwjq.scrollTop()
				let new_scroll_top

				// item_top represents here the distance of the item from the very top of the window
				if (item_top < 67) {
					// we want the value of item_top to be no lower than 67
					// so if it's 57, we have to scroll down 10 (so subtract 10 to scroll_top)
					// or if it's 47, we have scroll down 20, etc.
					new_scroll_top = scroll_top - (67 - item_top)

				} else {
					// we want the value of item_top to be no higher than the height of the window - 100
					// so if it's the height of the window, we have to scroll up 100
					let max_bottom = $(window).height()
					if (item_top > (max_bottom - 100)) {
						new_scroll_top = scroll_top + (item_top - max_bottom + 100)
					}
				}

				if (new_scroll_top) {
					new_scroll_top += 'px'
					$iwjq.animate({scrollTop: new_scroll_top}, 50)
				}

			// tile is showing separately (not inline)
			} else {
				// don't scroll so far that the item wouldn't be visible
				let scroll_to_tile_top = tile_top
				let max_top = this.$vuetify.breakpoint.sm ? 52 : 155
				if (scroll_to_tile_top < max_top) scroll_to_tile_top = max_top

				// if we had to position the tile somewhere other than where it was "supposed" to go to be next to the list item,
				// scroll the list so that the item appears next to the statement in the tile
				if (scroll_to_tile_top != item_top) {
					// but only if the list item is off the screen
					if (item_top < max_top || item_top > ($(window).height() - 100)) {
						let scroll_distance = scroll_to_tile_top - item_top
						// console.log('position_tile_and_scroll_list: scroll_distance: ' + scroll_distance + ' / ' + $iwjq.scrollTop())
						let new_scroll_top = ($iwjq.scrollTop() - scroll_distance) + 'px'
						$iwjq.animate({scrollTop: new_scroll_top}, 50)
					}
				}

				tile_jq.css('top', tile_top + 'px')
				// console.log('tile_top: ' + tile_top)

				// sometimes things have not stablized quickly enough when this runs the first time, so we may be asked to try again after a delay
				if (try_agains > 0) {
					setTimeout(x=>this.position_tile_and_scroll_list(try_agains - 1), 100)
				}
			}
		},

		uncheck_all_items() {
			this.$store.commit('set', [this.framework_record, 'checked_items', {}])
		},

		switch_to_document_tree(doc) {
			// first clear the active node and starting_lsitem_identifier
			this.viewer.make_node_active('', true)

			// if this doc's full json has already been loaded, just set document_identifier_showing_in_tree
			let fr = this.framework_records.find(x=>x.lsdoc_identifier==doc.identifier)
			if (fr && fr.framework_json_loaded) {
				this.$store.commit('set', [this.home_framework_record, 'document_identifier_showing_in_tree', doc.identifier])

			} else {
				// else first load the framework from the server
				U.loading_start('Loading framework…')
				this.$store.dispatch('get_lsdoc', doc.identifier).then(()=>{
					U.loading_stop()

					// then build the cfo for the framework
					U.build_cfo(this.$worker, fr.json).then((cfo)=>{
						this.$store.commit('set', [fr, 'cfo', cfo])

						// and set document_identifier_showing_in_tree when done
						this.$store.commit('set', [this.home_framework_record, 'document_identifier_showing_in_tree', doc.identifier])

						U.loading_stop()
					})
					.catch((e)=>{
						U.loading_stop()
						console.log(e)
					})

				}).catch((e)=>{
					console.log(e)
					U.loading_stop('refresh_lsdoc')
					this.$alert('An error occurred when loading the competency framework.').then(x=>this.hide_tree())
				})
			}
		},
	}
}
</script>

<style lang="scss">
// $k-case-tree-height: calc(100vh - 123px);	// $k-case-tree-inner-wrapper-height - 16px
$k-case-tree-height: calc(100vh - 107px);	// same as $k-case-tree-inner-wrapper-height
$k-case-tree-hight-with-archive-bar: calc(100vh - 144px);

.k-case-tree-scroll-wrapper {
	width:calc(100vw - 20px);
	max-width:946px;
	// background-color:#eee;
	// width:100px;
	overflow:auto;
	max-height:$k-case-tree-height;
	// max-height:$k-case-tree-inner-wrapper-height;
	padding:8px 0 8px 20px;
	// if we want to leave explicit room for a scroll bar, padding-right: 12px
	pointer-events: auto;
}

.k-case-tree-outer-wrapper-archive-showing {
	.k-case-tree-scroll-wrapper {
		max-height: $k-case-tree-hight-with-archive-bar;
	}
}

.k-case-tree {
	// width:calc(100vw - 40px);
	// max-width:932px;
	background-color:#fff;
	padding-top:32px;	// allow space for toolbar; this goes to 8px when in blob mode (below)
	padding-bottom:8px;
	padding-right:8px;
	border-radius:10px;
	transition: all 0.1s;
	// margin-left:20px;
	// margin-right:8px;
	// margin-bottom:100vh;
	// overflow:hidden;
}

.k-case-tree-blob {
	max-width: none;
	.k-case-tree { 
		padding-right:0; 
		padding-top:8px;
	}
	// if we want to leave explicit room for a scroll bar, padding-right: 12px
}

.k-case-tree-blob-with-pinned-items {
	max-width:calc(100vw - 348px);
}

// .k-case-tree-blob-with-pinned-items {
// 	max-width:calc(100vw - 368px);
// }

.k-case-tree-active-node-showing {
	// width:calc(50vw - 16px);
	width:calc(50vw - 4px);
	padding-left:12px;
}

// .k-case-tree-active-node-showing {
// 	width:calc(50vw - 16px);
// 	margin-left:12px;
// }

.k-case-tree-bordered {
	border:3px solid transparent;
	margin-top:4px;
}

.k-case-tree-outer-wrapper--minimized {
	.k-case-tree {
		width:auto;
		max-width:auto;
		margin-left:8px;
	}

	.k-case-tree-active-node-showing {
		width:auto;
		margin-left:20px;
	}
}

.k-case-tree-tile-view {
	display:flex;
	flex-wrap:wrap;
	justify-content: flex-start;
	align-items: flex-start;
}

.k-case-tree-active-item-wrapper {
	position:fixed;
	// left:530px;
	left: calc(50vw + 8px);
	// left: calc(50vw - 565px + 520px);
	// position below the bars at the top of the window, so it doesn't cover the search bar
	top:112px;
	height:calc(100vh - 112px);
	z-index: 3;	// so the tile appears above the pinned items
}

.k-case-tree-active-item-tile-wrapper {
	position:absolute;
	left:0;
}

.k-case-tree-multiple-docs-doc-showing {
	font-size:14px;
	line-height:17px;
	padding-left:8px;
	color:#555;
}

// NOTE: these calc'd left values were determined mostly by eyeballing it...
.k-case-tree-pinned-items-wrapper {
	position:fixed;
	z-index: 2;	// so pinned items appear above the tree toolbar
	right:8px;
	top:108px;
	overflow:auto;
	height:calc(100vh - 108px);
	padding:0 12px;
}

// for now, at least, don't change positions of things when pinned items are showing
// .k-case-tree-outer-wrapper--pinned-items-showing {
// 	// .k-case-tree {
// 	// 	max-width:calc(50vw - 120px);
// 	// 	max-width:700px
// 	// }
// 	// .k-case-tree-active-item-wrapper {
// 	// 	left: calc(50vw - 100px);
// 	// 	left:730px;
// 	// }
//
// 	.k-case-tree {
// 		width:584px;
// 	}
// 	.k-case-tree-active-item-wrapper {
// 		left:612px;
// 	}
// }
//
// @media (max-width: 1200px) {
// 	.k-case-tree-outer-wrapper--pinned-items-showing {
// 		.k-case-tree {
// 			width:calc(50vw - 16px);
// 		}
// 		.k-case-tree-active-item-wrapper {
// 			left: calc(50vw + 4px);
// 		}
// 	}
// }


.k-case-tree-featured-selected {
	// .k-case-tree-item {
	// 	opacity:0.4;
	// }
	//
	// .k-case-tree-item-descendant-or-ancestor-of-featured {
	// 	opacity:1.0;
	// }
	.k-case-tree-item-outer {
		display:none;
	}

	.k-case-tree-item-descendant-or-ancestor-of-featured {
		display:block;
	}
}
</style>
